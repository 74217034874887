import { createRouter, createWebHistory } from "vue-router";
import pageLanding from "@/pages/Landing.vue";
import pageAuthRegister from "@/pages/auth/Register.vue";
import pageAuthLogin from "@/pages/auth/Login.vue";
import pageAuthOtp from "@/pages/auth/Otp.vue";
import pageAuthActivatePassword from "@/pages/auth/activatePassword.vue";
import pageAuthForgotPassword from "@/pages/auth/forgotPassword.vue";
import pageAuthResetPassword from "@/pages/auth/ResetPassword.vue";
import componentAccountSidenav from "@/components/accountSidenav";
import pageManageMyAccount from "@/pages/manage/myaccount.vue";
import pageManageUpdateAccount from "@/pages/manage/updateaccount.vue";
import pageManageChangePassword from "@/pages/manage/changepassword.vue";
import pageSelfMyCart from "@/pages/self/mycart.vue";
import pageAllServices from "@/pages/self/allservices.vue";
import pageSelfMyOrders from "@/pages/self/myorders.vue";
import pageSelfMyPurchase from "@/pages/self/mypurchase.vue";
import pageSelfCheckout from "@/pages/self/checkout.vue";
import pageSelfCheckoutNew from "@/pages/self/checkoutNew.vue";
import pageSelfMyForms from "@/pages/self/forms.vue";
import pageReportsInvestigation from "@/pages/reports/investigation.vue";
import pageReportsPrescription from "@/pages/reports/prescription.vue";
import pageReportsOpdPrescription from "@/pages/reports/opdPrescription.vue";
import pageReportsVitals from "@/pages/reports/vitals.vue";
import pageReportsAdvices from "@/pages/reports/advices.vue";
import pagePaymentResponse from "@/pages/paymentresponse";
import pagePaymentResponseSuccess from "@/pages/paymentresponse-success";
import pageAuthActivateAccount from "@/pages/auth/activateAccount";
// abou us page contents
import contactUsPage from "@/pages/aboutus/contactUs"
import privacyPage from "@/pages/aboutus/privacy"
// import termsPage from "@/pages/aboutus/contactUs"
import authSelectAccount from "@/pages/manage/selectaccount";

const routes = [
  {
    path: "/",
    name: "page.landing",
    component: pageLanding,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/login",
    name: "page.auth.login",
    component: pageAuthLogin,
    meta: {
      requiresAuth: false,
      paid: true
    },
   
  },
  {
    path: "/register",
    name: "page.auth.register",
    component: pageAuthRegister,
  },
  {
    path: "/otp",
    name: "page.auth.otp",
    component: pageAuthOtp,
  },
  {
    path: "/activate-password",
    name: "page.auth.activatepassword",
    component: pageAuthActivatePassword,
  },
  {
    path: "/forgot-password",
    name: "page.auth.forgotpassword",
    component: pageAuthForgotPassword,
  },
  {
    path: "/reset-password",
    name: "page.auth.resetpassword",
    component: pageAuthResetPassword,
  },
  {
    path: "/activate-account",
    name: "page.auth.activateaccount",
    component: pageAuthActivateAccount,
  },
  {
    path: "/my-orders",
    name: "page.self.myorders",
    component: pageSelfMyOrders,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/my-purchase-history",
    name: "page.self.mypurchase",
    component: pageSelfMyPurchase,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/my-forms",
    name: "page.self.myforms",
    component: pageSelfMyForms,
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/all-services",
  //   name: "page.self.allServices",
  //   component: pageAllServices,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/my-profile",
    component: componentAccountSidenav,
    children: [
      {
        path: "/my-account",
        name: "page.manage.myaccount",
        component: pageManageMyAccount,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/update-account",
        name: "page.manage.updateaccount",
        component: pageManageUpdateAccount,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/change-password",
        name: "page.manage.changepassword",
        component: pageManageChangePassword,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/my-cart",
    name: "page.self.mycart",
    component: pageSelfMyCart,

    meta: {
      requiresAuth: false,
    },
  },

  // {
  //   path: "/checkout/:id",
  //   name: "page.self.checkout",
  //   component: pageSelfCheckout,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/checkout/:id",
    name: "page.self.checkout",
    component: pageSelfCheckoutNew,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/investigation-report",
    name: "page.reports.investigation",
    component: pageReportsInvestigation,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/prescription",
    name: "page.reports.prescription",
    component: pageReportsPrescription,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/opd-prescription",
    name: "page.reports.opdPrescription",
    component: pageReportsOpdPrescription,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/vitals",
    name: "page.reports.vitals",
    component: pageReportsVitals,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/advices",
    name: "page.reports.advices",
    component: pageReportsAdvices,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/paymentresponse-success",
    name: "page.paymentresponse-success",
    component: pagePaymentResponseSuccess,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/paymentresponse",
    name: "page.paymentresponse",
    component: pagePaymentResponse,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contact-us",
    name: "page.aboutus.contactus",
    component: contactUsPage,
    props:{tempCart:{}},
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/policy",
    name: "page.aboutus.privacy",
    component: privacyPage,
    props:{tempCart:{}},
    meta: {
      requiresAuth: false,
    },
  },
  // {
  //   path: "/terms",
  //   name: "page.aboutus.terms",
  //   component: termsPage,
  //   props:{tempCart:{}},
  //   meta: {
  //     requiresAuth: false,
  //   },
  // },
  {
    name: "page.manage.selectaccount",
    component: authSelectAccount,
    path: "/manage/select-account",
    meta: {
      requiresAuth: true,
      paid: true
    },
     
  },
  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
