import axios from "axios";
import { defineStore } from "pinia";
import { toast } from "vue3-toastify";
export const useSelfReports = defineStore('selfReports', {
    state: () => ({
        errors : [],
        investigationReports : [],
        patientVitals: [],
        reportIframe: "",
        patientLatestVitals: null,
        loaderStatus: false,
        patientAdvices:[],
        patinetAdviceDates:'',
        patientPrescriptions:[],
        medications:[],
        currentSelectedVisitid:0,
        currentSelectedVitalId:0,
        externalGraphData:[],
        pdfLoader:false,
        opdPrescriptionData:[],
    }),
    getters: {
        getErrors: (state) => {
            return state.errors
        },
        getInvestigationReports: (state) => {
            return state.investigationReports
        },
        getPatientVitals: (state) => {
            return state.patientVitals
        },
        getPatientLatestVitals: (state) => {
            return state.patientLatestVitals
        },
        getLoaderStatus: (state) => {
          return state.loaderStatus;
        },
        
      getReportIframe: (state) => {
        return state.reportIframe;
      },

      getPatientAdviceDates:state=>{return state.patinetAdviceDates},
      getPatientAdvice:state=>{return state.patientAdvices},
      getPatientPrescriptions:state=>{return state.patientPrescriptions},
      getPatientMedications:state=>{return state.medications},
      getCurrentSelectedVisitId:state=>{return state.currentSelectedVisitid},
      getCurrentSelectedVitalId:state=>{return state.currentSelectedVitalId},
      getExternalGraphdata:state=> {return state.externalGraphData},
      getPdfLoaderStatueInvestigationReport:state=> state.pdfLoader,
    getOpdPrescriptionData:state=>state.opdPrescriptionData
    },
    actions: {
        async getInvestigationReportsData() {
            try {
                this.loaderStatus = true;
                // reseting the graph data;
                this.externalGraphData=[]
                await axios
                    .get('/get/bill-list')
                    .then((response) => {
                        this.loaderStatus = false;
                        this.investigationReports = response.data;
                        this.investigationReports.map((report) => {
                            return {...report, is_active: false};
                        })
                        this.investigationReports.forEach((element, index) => {
                            element.is_active = false;
                            if (index == 0) {
                              element.is_active = true;
                              this.getInvestigationReportDetail(
                                element.branch_id,
                                element.bill_no, element.itemDetails[0]?.sampleCollectionId,
                               
                              );
                            }
                          });
                    })
                    .catch((error) => {
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                this.loaderStatus = false;
            }
        },
        async getOpdPrescriptionDataFetch(patid) {
            try {
                this.loaderStatus = true;
                // reseting the graph data;
                await axios
                    .post('/getAllVisits',{patient_id:patid??1})
                    .then((response) => {
                        this.opdPrescriptionData = response.data;
                        this.opdPrescriptionData.map((report) => {
                            return {...report, is_active: false};
                        })
                        this.opdPrescriptionData.forEach((element, index) => {
                            element.is_active = false;
                            if (index == 0) {
                              element.is_active = true;
                              this.getOpdPrescriptionReport(
                                element.patient_id,
                                element.visit_id,
                               
                              );
                            }
                          });
                    })
                    .catch((error) => {
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                this.loaderStatus = false;
            }
        },
        async getOpdPrescriptionReport(patId,visitId) {
            try {
            this.reportIframe=''
              this.pdfLoader = true;
              await axios
                .get("/pdf/prescription/" + patId + "/" + visitId,{
                  responseType: "blob",
                })
                .then((response) => {
                  const blob = new Blob([response.data], { type: "application/pdf" });
                  const objectUrl = URL.createObjectURL(blob);
                  this.reportIframe = objectUrl;
                  this.pdfLoader = false;
                })
                .catch((error) => {
                    this.reportIframe=''
                  this.pdfLoader = false;
                  toast.error(error.response?.data?.message??"Something went wrong")
                });
            } catch (error) {}
          },
        async getInvestigationReportDetail(branch, bill_no,sampleCollectionId) {
            try {
            this.reportIframe=''
              this.pdfLoader = true;
              await axios
                .get("/v2/pdf/labreport/" + branch + "/" + bill_no+"/"+sampleCollectionId,{
                  responseType: "blob",
                })
                .then((response) => {
                  const blob = new Blob([response.data], { type: "application/pdf" });
                  const objectUrl = URL.createObjectURL(blob);
                  this.reportIframe = objectUrl;
                  this.pdfLoader = false;
                })
                .catch((error) => {
                    this.reportIframe=''
                  this.pdfLoader = false;
                  toast.error(error.response?.data?.message??"Something went wrong")
                });
            } catch (error) {}
          },
        async getPatientVitalsData(id) {
            try {
                this.loaderStatus = true;
                await axios
                    .get(`/fetchVitals/${id}`)
                    .then((response) => {
                        if(response.status === 200){
                        this.loaderStatus = false;
                        this.patientVitals = response.data;}
                    })
                    .catch((error) => {
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                this.loaderStatus = false;
            }
        },
        async getPatientLatestVitalsData(id,opdid) {
            try {
                this.loaderStatus = true;
                await axios
                    .get(`/vitalHistory/${id}/${opdid}`)
                    .then((response) => {
                        if(response.status === 200){
                            this.loaderStatus = false;
                            // as it is not sorted in api 
                            this.patientVitals = response.data
                        this.patientLatestVitals = response.data[response.data?.length-1];
                        }
                    })
                    .catch((error) => {
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                this.loaderStatus = false;
            }
        },
        async getPatientLatestAdvicesData(visitId=0) {
            try {
                this.loaderStatus = true;
                await axios
                    .get(`/patient/advice/${visitId}`)
                    .then((response) => {
               
                        this.loaderStatus = false;
                          if(visitId){  
                        this.patientAdvices = response.data.data?.doctor_advice;
                        this.patinetAdviceDates.forEach((elemet,index)=>
                        {if(elemet.visit_id==visitId){
                            this.patinetAdviceDates[index].is_active=true
                        }
                    else{this.patinetAdviceDates[index].is_active=false}}
                        )
                          }
                          else{
                        this.patinetAdviceDates = response.data.data?.date_list;
                        this.patinetAdviceDates[0].is_active=true
                          }
                        
                        
                    })
                    .catch((error) => {
                        this.loaderStatus = false;
                        if(visitId){  
                            this.patientAdvices = '';
                              }
                              else{
                            this.patinetAdviceDates = '';
    
                              }
                    })
            }
            catch(error) {
                this.loaderStatus = false;
            }
        },
        async getPatientLatestPrescriptionsData(visit_id=0) {
            // note: /0 is being used first to fetch the dates
            try {
                this.loaderStatus = true;
                await axios
                    .get(`patient_prescription/${visit_id}`)
                    .then((response) => {
                        this.loaderStatus = false;
                        //  note: donot do this in fe before production 
                        //  critical: did as i was told
                        let presWithData=[]
                        response.data.data.date_list.forEach((element)=>{if(element.visit_date){presWithData.push(element) }})
                        
                            
                        this.patientPrescriptions = presWithData;
                        if(!this.currentSelectedVisitid && presWithData[0]){
                        this.currentSelectedVisitid=presWithData[0].visit_id
                        this.getPatientLatestPrescriptionsData(presWithData[0].visit_id)    
                        }
                        this.medications=response.data.data.medication
                        
                     
                        
                        
                    })
                    .catch((error) => {
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                this.loaderStatus = false;
            }
        },
        async setCurrentSelectedMedicationid(id){
            this.currentSelectedVisitid=id
        },
        async getExternalGraphDataApi(){
            await axios
            .get(`patient/externalTestInGraph`)
            .then((response) => {
                this.loaderStatus = false;
                this.externalGraphData=response.data?.data
                // this.externalGraphData={
                //     "test": {
                //         "Urea serum": {
                //             "max": 45,
                //             "min": 15,
                //             "dates": [
                //                 "2023-12-14 14:47:38",
                //                 "2023-12-14 14:50:31",
                //                 "2023-12-20 15:15:53"
                //             ],
                //             "values": [
                //                 21,
                //                 22,
                //                 40
                //             ]
                //         },
                //         "Bilirubin(Total)": {
                //             "max": 1.2,
                //             "min": 0.4,
                //             "dates": [
                //                 "2023-12-14 15:01:22",
                //                 "2023-12-20 15:14:50"
                //             ],
                //             "values": [
                //                 213,
                //                 2
                //             ]
                //         },
                //         "Bilirubin(Direct)": {
                //             "max": null,
                //             "min": null,
                //             "dates": [
                //                 "2023-12-14 15:01:36",
                //                 "2023-12-14 15:03:23",
                //                 "2023-12-20 15:15:11"
                //             ],
                //             "values": [
                //                 122,
                //                 123,
                //                 12
                //             ]
                //         }
                //     },
                //     "package": {
                //         "LFT(Liver Function Test)": {
                //             "Bilirubin(Total)": {
                //                 "max": null,
                //                 "min": null,
                //                 "dates": [
                //                     "2023-12-18 16:35:09",
                //                     "2023-12-18 16:50:46",
                //                     "2023-12-20 15:32:47",
                //                     "2023-12-20 15:32:47"
                //                 ],
                //                 "values": [
                //                     10,
                //                     11,
                //                     0.6,
                //                     0.6
                //                 ]
                //             },
                //             "Bilirubin(Direct)": {
                //                 "max": null,
                //                 "min": null,
                //                 "dates": [
                //                     "2023-12-18 16:35:09",
                //                     "2023-12-18 16:50:46",
                //                     "2023-12-20 15:32:47",
                //                     "2023-12-20 15:32:47"
                //                 ],
                //                 "values": [
                //                     10,
                //                     11,
                //                     0.5,
                //                     0.5
                //                 ]
                //             }
                //         },
                //         "LFT(Liver Function Test12)": {
                //             "Bilirubin(Total)": {
                //                 "max": null,
                //                 "min": null,
                //                 "dates": [
                //                     "2023-12-18 16:35:09",
                //                     "2023-12-18 16:50:46",
                //                     "2023-12-20 15:32:47",
                //                     "2023-12-22 15:32:47"
                //                 ],
                //                 "values": [
                //                     12,
                //                     13,
                //                     1.6,
                //                     0.6
                //                 ]
                //             },
                //             "Bilirubin(Direct)": {
                //                 "max": null,
                //                 "min": null,
                //                 "dates": [
                //                     "2023-12-18 16:35:09",
                //                     "2023-12-18 16:50:46",
                //                     "2023-12-20 15:32:47",
                //                     "2023-12-21 15:32:47"
                //                 ],
                //                 "values": [
                //                     10,
                //                     11,
                //                     0.5,
                //                     0.5
                //                 ]
                //             },
                //             "Bilirubin(Direct2)": {
                //                 "max": null,
                //                 "min": null,
                //                 "dates": [
                //                     "2023-12-06 16:35:09",
                //                     "2023-12-08 16:50:46",
                //                     "2023-12-12 15:32:47",
                //                     "2023-12-18 15:32:47"
                //                 ],
                //                 "values": [
                //                     10,
                //                     11,
                //                     0.5,
                //                     0.5
                //                 ]
                //             }
                //         }
                //     }
                // }
                
                    
            
                
             
                
                
            })
            .catch((error) => {
                this.loaderStatus = false;
                this.externalGraphData=[]
            })

        },

        setActiveVisit(branch,billNo,sampleCollectionId) {
            this.investigationReports.forEach((element) => {
              element.is_active = false;
              if (element.bill_no == billNo) {
                element.is_active = true;
              }
            });
            this.getInvestigationReportDetail(branch,billNo,sampleCollectionId);
          },
          setActivePrescription(patId,visitId) {
            this.opdPrescriptionData.forEach((element) => {
              element.is_active = false;
              if (element.visit_id == visitId) {
                element.is_active = true;
              }
            });
            this.getOpdPrescriptionReport(patId,visitId);
          },
    }
})