<template>
    <div class="container-fluid  login-container" style="background: var(--foundation----background---b100)">
    <div class="row">
        <div class="col-4 col-60-md mx-auto my-5 otp-container">
            
            <div class="p40_50 forms-bg" >
                <div class='d-flex justify-content-center pb-4'>
                    <img src="../../assets/images/logo.png" alt="logo" style='width: 150px'>
                </div>
                    <p style="color: var(--foundation----primary---p300)" class="fs-4 fw-semibold text-center">Verification</p>
                    <p class="text-center fs-15">Please enter the OTP sent to your mobile number to verify your account.</p>
                 <form class="mt-4">
                    
                    <div class="mb-3 d-flex">
                        <input maxlength="1" v-model="otp_1" type="text" class="form-control otp-input me-2 text-center">
                        <input maxlength="1" v-model="otp_2" type="text" class="form-control otp-input me-2 text-center">
                        <input maxlength="1" v-model="otp_3" type="text" class="form-control otp-input me-2 text-center">
                        <input maxlength="1" v-model="otp_4" type="text" class="form-control otp-input me-2 text-center">
                        <input maxlength="1" v-model="otp_5" type="text" class="form-control otp-input me-2 text-center">
                        <input maxlength="1" v-model="otp_6" type="text" class="form-control otp-input text-center">
                        </div>
                </form>
                <button type="submit" style="width: 100%" @click="verifyOtpCode" class=" btn btn-primary btn-meraki-primary mb-4 mt-2">Confirm</button>
                <p class="text-center fs-15 mt-4">Didnt receive the code? <span style="color: var(--foundation----primary---p300)" class="c-pointer">Click Here!</span></p>
                </div>
        </div>
    </div>
</div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useAuthActivate } from '@/core/modules/auth/activate';
const store = useAuthActivate();
const  otp_1 =ref(null);
const  otp_2 =ref(null);
const  otp_3 =ref(null);
const  otp_4 =ref(null);
const  otp_5 =ref(null);
const  otp_6 =ref(null);
onMounted(() => {
    let arr = document.getElementsByClassName('otp-input');
    arr[0].focus();
    Array.from(arr).forEach((item) => {
    item.addEventListener('keyup', (event) =>{
        if(item.value){
            if(item.nextElementSibling){
            item.nextElementSibling.focus()
        }
        else {
            return;
        }
        }
        else if(event.keyCode === 8){
            if(item.previousElementSibling){
                item.previousElementSibling.focus();
                item.previousElementSibling.select()
            }
            else {
                return
            }
        }
    })
})
})

function verifyOtpCode(){
    let otp_code = parseInt(otp_1.value + otp_2.value + otp_3.value + otp_4.value + otp_5.value + otp_6.value);
    let contact_number = parseInt(window.localStorage.getItem("contact"));
    //Note: need to discuss about device_id in web
    var body = {
        contact : contact_number,
        otp: otp_code,
        device_id : 'e78ad27aea950c14'
    }
    store.verifyOtp(body);
}
</script>