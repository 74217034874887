import { defineStore } from 'pinia'
import axios from "axios";
import { toast } from 'vue3-toastify';
export const useAuthActivate = defineStore('authActivate', {
    state: () => ({
        errors: null,
        loaderStatus:false
    }),
    getters:{
        getErrors:(state)=>{
            return state.errors
        },
        getLoaderStatus: (state) => {
            return state.loaderStatus
        }
      },
    actions: {
        async activateAccount (body){
            this.errors = null;
            try {
            this.loaderStatus = true;
            await axios
                .post('/activateService', body)
                .then((response) => {
                    this.loaderStatus =  false;
                    if(response.data.status === 200 && !response.data.mobile_number_verified){
                        
                        this.$router.push({name: 'page.auth.otp'})
                    }
                    else if(response.data.status === 200 && response.data.mobile_number_verified){
                        toast.success(response.data.message)
                        this.$router.push({name: 'page.auth.login'})
                    }
                })
                .catch((error) => {
                  
                    this.errors =  error.response.data;
                    if(error.response.data.message){
                        toast.error(error.response.data.message)
                    }
                    this.loaderStatus =  false;
                });
            }
            catch(error) {
                this.errors =  error.response.data;
                this.loaderStatus =  false;
            }
        },
        async verifyOtp(body) {
            this.errors = null;
            try {
            this.loaderStatus = true;
            await axios
                .post('/verifyOtp', body)
                .then((response) => {
                    this.loaderStatus =  false;
                    if(response.data.status === 200){
                        this.$router.push({name: 'page.auth.activatepassword'})
                    }
                })
                .catch((error) => {
                    this.errors =  error.response.data;
                    if(error.response.data.message){
                        toast.error(error.response.data.message)
                    }
                    this.loaderStatus =  false;
                });
            }
            catch(error) {
                this.errors =  error.response.data;
                this.loaderStatus =  false;
            }
        },
        async sendOtp(body){
            this.errors = null;
            try {
            this.loaderStatus = true;
            await axios
                .post('/sendOtp', body)
                .then((response) => {
                    this.loaderStatus =  false;
                    if(response.data.status === 200){
                        this.$router.push({name: 'page.auth.otp'})
                    }
                })
                .catch((error) => {
                    this.errors =  error.response.data;
                    this.loaderStatus =  false;
                });
            }
            catch(error) {
                this.errors =  error.response.data;
                this.loaderStatus =  false;
            }
        }
    }
})