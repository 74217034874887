import { createApp, markRaw } from "vue";
import App from "./App.vue";
import router from "./router/index";
import { createPinia } from "pinia";
import "@/assets/css/style.css";
import piniaPluginPersistedState from "pinia-plugin-persistedstate";
import axios from "axios";
import VueApexCharts from "vue3-apexcharts";
import VueCarousel from 'vue-carousel';

// import VueGoogleMaps from '@fawmi/vue-google-maps';


// import * as VueGoogleMaps from '@fawmi/vue-google-maps'
// import { VueGoogleMaps } from 'vue2-google-maps'
// import { Map, Marker, GMapCluster } from '@fawmi/vue-google-maps';
const pinia = createPinia();

import { API_URL } from "@/core/services/config";
axios.defaults.baseURL = API_URL;
// boostrap start
import "bootstrap/dist/css/bootstrap.css";

// fontawesome start
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas, far);
// fontawesome end

// SweetAlert
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

// toastify
import "vue3-toastify/dist/index.css";
import Vue3Toastify from 'vue3-toastify';

// data table

//temp
import VueGoodTablePlugin from "vue-good-table-next";
import "vue-good-table-next/dist/vue-good-table-next.css";

import { useManageUser } from "@/core/modules/auth/user";
import { useSelfCart } from "./core/modules/self/mycart";
import { storeToRefs } from "pinia";


let previousRoute = null;

router.beforeEach(async (to, from, next) => {
  const manageUserStore = useManageUser();
  const storeCart = useSelfCart();
  
  const { getIsAuthorized } = storeToRefs(useManageUser());

  previousRoute = from;

  await manageUserStore.getUserDetails();
  if(getIsAuthorized.value){
    await manageUserStore.getDeopsitAndUnpaidAmount()
    await storeCart.getCartQuantity();
  }

  if (getIsAuthorized.value == false && to.meta.requiresAuth == true)
    next({ name: "page.auth.login" });
  else next();
});

//for using this.$router in pinio store
pinia.use(({ store }) => {
  store.$router = markRaw(router);
});
pinia.use(piniaPluginPersistedState);
const app = createApp(App);
app.use(
  Vue3Toastify,
  {
    autoClose: 5000,
    clearOnUrlChange: false,
    style: {
      opacity: '1',
      pauseOnHover: true,
      userSelect: 'initial',
    },
  },
)
app.use(VueCarousel);
app.use(pinia);

app.use(VueSweetalert2);
app.use(VueGoodTablePlugin);
app.use(VueApexCharts);
// app.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyDzuT9vxxZvX6hvRO1gJzKEC5pWgj0Sk7Y'
//   }
// })
export { previousRoute };
createApp(App)
  .use(router)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
// createApp(App).use(router).component("font-awesome-icon", FontAwesomeIcon).component("GMapMap", VueGoogleMaps).
// component("GMapCluster", VueGoogleMaps).component("GMapMarker", VueGoogleMaps).mount('#app')
