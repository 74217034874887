<template>
  <modalComp
    :idmodal="'proceedtoorder'"
    :show-header="true"
    :show-footer="true"
    :size="'modal-md'"
  >
    <template v-slot:header>
      <h5 class="modal-title">Proceed Order</h5>
      <button
        type="button"
        class="btn-close shadow-none"
        data-bs-dismiss="modal"
        aria-label="Close"
        @click="handleCancel"
      ></button>
    </template>
    <template v-slot:body>
      <div>
        <div class="mb-1 mx-2">
          <p style="font-weight: 500">
            Are you sure you want to order the following items?
          </p>
          <div class="my-3">
            <label
              style="font-weight: 500; font-size: 0.75rem"
              class="d-none form-label"
              >Select Date and Time</label
            >
            <br />
            <input
            
              type="datetime-local"
              :min="currentDateTime"
              @click="minEndDate"
              class="d-none py-15"
              required
              v-model="date"
              placeholder="Select Date and Time"
              id="order_datetime"
            />
            <div style="font-size: 0.75rem; color: red" v-if="disabledDate">
              Please select date one hour from the order date
            </div>
            <div v-else></div>
          </div>
          <span style="font-size: 0.75rem; font-weight: 500"
            >Order Summary</span
          >
          <table class="table table-striped">
            <thead>
              <tr>
                <th>SN</th>
                <th>Items</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in props.selectedCartItems" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.service }}</td>
                <td>NPR. {{ item.price.amount?.toLocaleString() }}</td>
              </tr>
            </tbody>
          </table>
          <div style="font-size: 0.75rem">
            <span>Total Amount</span>
            <span style="font-weight: 500"
              >&nbsp; NPR. {{ props.totalAmt?.toLocaleString() }}</span
            >
          </div>
          <div class="mb-3">
            <label
              style="font-weight: 500; font-size: 0.75rem"
              class="form-label"
              for="floatingTextarea"
              >Remarks</label
            >
            <textarea
              class="form-control"
              v-model="service_comments"
              placeholder="Leave Remarks here"
              id="floatingTextarea"
            ></textarea>
          </div>
          <div class="mb-3">
            <div
              class="row mb-2"
              v-for="(address, index) in getAddressDetails"
              :key="index"
            >
              <div class="col-1">
                <div style="position: relative">
                  <input
                    style="position: absolute; top: 65px"
                    :checked="address.is_default === 1"
                    :value="address"
                    v-model="selectedAddress"
                    @change="handleAddressSelection(address)"
                    type="radio"
                  />
                </div>
              </div>
              <div class="col-11">
                <div class="card c-pointer px-4 py-4">
                  <div class="address-card mt-2">
                    {{ address.location_address }}
                  </div>
                  <div class="address-card">{{ address.province }}</div>
                  <div class="address-card">{{ address.district }}</div>
                  <div class="address-card">{{ address.municipality }}</div>
                  <div class="address-card">{{ address.address }}</div>
                  <!-- <div @click="editAddress(address)" data-bs-target="#saveaddress" data-bs-toggle="modal" class="btn btn-meraki-secondary address-card mt-3 mini-btn">Edit</div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-5">
        <button
          class="btn-meraki-secondary me-2"
          @click="handleCancel"
          data-bs-dismiss="modal"
        >
          Cancel
        </button>
        <button
          :class="['btn-meraki-primary', { disabled: disabledDate }]"
          @click="confirmOrder"
        >
          Confirm
        </button>
      </div>
    </template>
  </modalComp>
</template>

<script setup>
import { ref, computed, onMounted, watch } from "vue";
import router from "@/router/index";
import { storeToRefs } from "pinia";
import modalComp from "@/components/common/modal";
import { useSelfCart } from "@/core/modules/self/mycart";
import { useSelfOrder } from "@/core/modules/self/order";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { useSelfAddress } from "@/core/modules/address";
import moment from "moment";
import { useManageUser } from "@/core/modules/auth/user";

const { getopdPatientRegId,getUser } = storeToRefs(useManageUser());

moment().format();
const props = defineProps({
  selectedCartItems: Object,
  totalAmt: Number,
  formattedDate: Date,
});

const { getAddressDetails } = storeToRefs(useSelfAddress());
const date = ref("");
const service_comments = ref("");
const currentDateTime = ref("");

const today_date = ref(moment().format("YYYY-MM-DDTHH:MM"));
const disabledDate = ref(false);

const store = useSelfOrder();
const storeCart = useSelfCart();
const storeAdd = useSelfAddress();

const amt = ref(0);
const selectedAddress = ref(null);

onMounted(async () => {
  await storeAdd.getAddressDetailsData();
  selectedAddress.value = getAddressDetails.value.find(
    (address) => address.is_default === 1
  );
  if (!selectedAddress.value)
    selectedAddress.value = getAddressDetails.value[0];
});

function minEndDate() {
  currentDateTime.value = new Date(props.selectedCartItems[0].created_at)
    .toISOString()
    .slice(0, 16);
}

watch(service_comments, (value) => {
  if (value) {
    let orderDatetimeValue = $("#order_datetime").val();
    date.value = orderDatetimeValue;
  }
});

watch(date, (value) => {
  const currentDate = moment();
  const selectedDate = moment(value);

  if (selectedDate.isBefore(currentDate)) {
    disabledDate.value = true
  }else{
    disabledDate.value = false
  }
});

function handleCancel() {
  disabledDate.value = false;
}

function confirmOrder() {
  if (!date.value || !service_comments.value) {
    if (!service_comments.value)
      document.getElementById("floatingTextarea").focus();
    if (!date.value) document.getElementById("order_datetime").focus();
  } else {
    let booking_date = moment(date.value).format("YYYY-MM-DD");
    let booking_time = moment(date.value).format("HH:mm:ss");
    if (booking_time < moment().format("HH:MM:SS")) {
      date.value = "";
      document.getElementById("order_datetime").focus();
    } else {
      $("#proceedtoorder").modal("hide");
      var orders = {
        order_date: booking_date,
        order_time: booking_time,
        remarks: service_comments.value,
        items: props.selectedCartItems,
        total_amount: props.totalAmt,
        patient_address_id: selectedAddress.value.id,
      };
      if(getopdPatientRegId.value){
      store.storeOrder(orders);
      }
      else{
      store.storeOrderCallbackReq({...orders,...getUser.value,mobile_number:getUser.value.contact_number});

      }
    }
  }
}
function handleAddressSelection(address) {
  selectedAddress.value = address;
}
</script>
<style scoped>
.default-card {
  background-color: var(--foundation----primary---p50);
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
