<template>
    <div class="container-fluid  login-container" style="background: var(--foundation----background---b100)">
    <div class="row">
        <div class="col-4 mx-auto my-5 otp-container">
            
            <div class="p40_50 forms-bg" >
                <div class='d-flex justify-content-center pb-4'>
                    <img src="../../assets/images/logo.png" alt="logo" style='width: 150px'>
                </div>
                    <p style="color: var(--foundation----primary---p300)" class="fs-4 fw-semibold text-center">Reset Your Password</p>
                 <form class="mt-4">
                    <div class="mb-3 d-flex" style='position: relative'>
                        <input :type="!showNewPassword?'password':'text'" placeholder="Enter New Password" class="form-control me-2 ">
                        <font-awesome-icon class="fo-text-color-primary-p300" @click="showNewPasswordFun()"
                                    :icon="!showNewPassword ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'"
                                    style="position: absolute;right: 22px; top: 16px; cursor: pointer; font-size:12px" />
                        </div>
                </form>
                <form class="mt-4">
                    <div class="mb-3 d-flex" style='position: relative'>
                        <input :type="!showConfirmPassword?'password':'text'" placeholder="Confirm New Password" class="form-control me-2 ">
                        <font-awesome-icon class="fo-text-color-primary-p300" @click="showConfirmPasswordFun()"
                                    :icon="!showConfirmPassword ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'"
                                    style="position: absolute;right: 22px; top: 13px; cursor: pointer; font-size:12px" />
                        </div>
                </form>
                <button type="submit" style="width: 100%" class=" btn btn-primary btn-meraki-primary mb-4 mt-2">Enter</button>
                <!-- <p class="text-center fs-15 mt-4">Didnt receive the code? <span style="color: var(--foundation----primary---p300)" class="c-pointer">Click Here!</span></p> -->
                </div>
        </div>
    </div>
</div>
</template>

<script setup>
import { ref } from 'vue'
const showNewPassword = ref('')
const showConfirmPassword = ref('')

function showNewPasswordFun() {
    showNewPassword.value = (showNewPassword.value) ? false : true;
}

function showConfirmPasswordFun() {
    showConfirmPassword.value = (showConfirmPassword.value) ? false : true;
}
</script>