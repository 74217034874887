import axios from "axios";
import { defineStore } from "pinia";
import { toast } from "vue3-toastify";
export const useSelfForms = defineStore('selfForms', {
    state: () => ({
        myFiles: [],
        errors : [],
        loaderStatus:false,
        document_type : null,
        prescriptions: [],
        lab_reports: [],
        other_docs: []
    }),
    getters: {
        getErrors: (state) => {
            return state.errors
        },
        getMyFiles: (state) => {
            return state.myFiles
        },
        getLoaderStatus: (state) => {
            return state.loaderStatus
        },
        getDocsType: (state) => {
            return state.document_type;
        },
        getPresciptionDocs: (state) => {
            return state.prescriptions
        },
        getLabReportDocs: (state) => {
            return state.lab_reports
        },
        getOtherDocs: (state) => {
            return state.other_docs
        }
    },
    actions: {
        async savePatientReport(body) {
            try {
                this.loaderStatus = true;
                await axios
                    .post('/savePatientReport', body)
                    .then((response) => {
                        if(response.data.status === 200) {
                            this.loaderStatus = false;
                            toast.success(response.data.message, {
                                autoClose: 500,
                              }); 
                              this.getPatientReport();
                        }
                        
                    })
                    .catch((error) => {
                        this.loaderStatus = false;
                })
            }
            catch(error) {
                this.loaderStatus = false;
            }
        },
        async getPatientReport() {
            try {
                this.loaderStatus = true;
                await axios
                    .get('/getPatientReport')
                    .then((response) => {
                        if(response.data.status === 200) {
                            this.loaderStatus = false;
                            this.myFiles = response.data.data;

                            this.prescriptions = this.myFiles.filter((element) => {
                                return element.group_id == 1;
                            });
                            this.lab_reports = this.myFiles.filter((element) => {
                                return element.group_id == 2;
                            });
                            this.other_docs = this.myFiles.filter((element) => {
                                return element.group_id == 3;
                            });
                        }
                    })
                    .catch((error) => {
                        this.loaderStatus = false;
                })
            }
            catch(error) {
                this.loaderStatus = false;
            }
        },
        async removePatientReport(body) {
            try {
                await axios
                    .post('/removePatientReport', body)
                    .then((response) => {
                        toast.success(response.data.message, {
                            autoClose: 500,
                          }); 
                    })
                    .catch((error) => {
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                this.loaderStatus = false;
            }
        },
        async getDocumentsType(){
            try {
                this.loaderStatus = true;
                await axios
                    .get('/getDocumentsType')
                    .then((response) => {
                        this.document_type = response.data.data;
                    })
                    .catch((error) => {
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                this.loaderStatus = false;
            }
        }
    },
    // persist: true
})