<template>
    <modalComp :idmodal="'saveaddress'" :show-header="true" :show-footer="true" :size="'modal-md'">
        <template v-slot:header>
            <h5 class="modal-title">Add Address </h5>
            <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
        </template>
        <template v-slot:body>
            <div>
                <div class="mb-2 mx-2">
                    <div class="row" style="font-size: 0.75rem">
                        <div class="col-12 mb-2">
                            <label class="acc_label pb-1" for="address_title">Address Title</label>
                            <br>
                            <select v-model="addressTitle" class="acc_input width-100" >
                                <option :value="title.title_name" v-for="(title, index) in getAddressTitle" :key="index">
                                    {{ title.title_name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-6 mb-2">
                            <label class="acc_label pb-1" for="address_title">Country</label>
                            <br>
                            <select v-model="countryId" class="acc_input width-100" >
                                <option :value="country.country_id" v-for="(country, index) in getCountries" :key="index">
                                    {{ country.country_name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-6 mb-2">
                            <label class="acc_label pb-1" for="address_title">Province</label>
                            <br>
                            <select @change="getDistricts(provinceId)" v-model="provinceId" class="acc_input width-100" >
                                <option :value="province.id" v-for="(province, index) in getProvinces" :key="index">
                                    {{ province.province }}
                                </option>
                            </select>
                        </div>
                        <div class="col-6 mb-2">
                            <label class="acc_label pb-1" for="address_title">District</label>
                            <br>
                            <select @change="getMunicipalities(districtId)" v-model="districtId" class="acc_input width-100">
                                <option :value= "district.id" v-for="(district,index) in getProvinceDistrict" :key="index">
                                    {{ district.district }}
                                </option>
                            </select>
                        </div>
                        <div class="col-6 mb-2">
                            <label class="acc_label pb-1" for="address_title">Municipality</label>
                            <br>
                            <select v-model="municipalityId" class="acc_input width-100">
                                <option :value= "muni.id" v-for="(muni,index) in getMunicipality" :key="index">
                                    {{ muni.municipality }}
                                </option>
                            </select>
                        </div>
                        <div class="col-6 mb-2">
                            <label class="acc_label pb-1" for="address_title">Ward</label>
                            <br>
                            <input v-model="wardNo" type="number" class="acc_input width-100" >
                        </div>
                        <div class="col-6 mb-2">
                            <label class="acc_label pb-1" for="address_title">Address</label>
                            <br>
                            <input v-model="addressValue"  type="text" class="acc_input width-100" >
                        </div>
                        <div class="col-6 mt-2">
                            <label for="is_default">Set Default Address?</label>
                            <input class="ms-2" type="checkbox" :true-value="1" :false-value="0" v-model="isChecked">
                        </div>
                    </div>
                    <div class="row mt-3" style="font-size: 0.75rem">
                        <div class="mb-3">
                            <span class="fw-semibold">Map URL : &nbsp;</span><span>{{mapUrl}}</span>
                        </div>
                        <GoogleMap api-key="AIzaSyDzuT9vxxZvX6hvRO1gJzKEC5pWgj0Sk7Y" style="width: 100%; height: 30vh" :center="markerPosition" @click="handleMarkerPositionChange" :zoom="15">
                            <Marker :options="{ position : markerPosition }" draggable />
                          </GoogleMap>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div class="p-2">
                <button class="btn-meraki-secondary me-2" data-bs-dismiss="modal" >Cancel</button>
               
                <button v-if="checkObjectBool" class="btn-meraki-primary" data-bs-dismiss="modal" @click="saveAddress" >Save</button>
                <button v-else class="btn-meraki-primary" data-bs-dismiss="modal" @click="updateAddressData" >Update</button>
            </div>
        </template>

    </modalComp>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import modalComp from '@/components/common/modal';
import { storeToRefs } from 'pinia';
import { useSelfAddress } from '@/core/modules/address';
import { useManageUser } from "@/core/modules/auth/user";

import { GoogleMap, Marker } from "vue3-google-map";
const { getProvinces, getProvinceDistrict, getMunicipality, getCountries } = storeToRefs(useSelfAddress());

const store = useSelfAddress();
const { getUser } = storeToRefs(useManageUser());
const provinceId = ref(null);
const districtId = ref(null)
const municipalityId = ref(null);
const countryId = ref(null);
const isChecked = ref(0);
const addressValue = ref(null);
const getAddressTitle = ref([{
    title_name: 'Home'},
    {
    title_name: 'Work'},
    {
    title_name: 'Other'
}])
const addressTitle = ref('Home')
const checkObjectBool = ref(null)
const wardNo   = ref(null)
const mapUrl = ref('https://www.google.com/maps/search/?api=1&query=27.6931196034988,85.31704387320124')
const props = defineProps({
    tempAddressVal: Object
})
onMounted(async () => {
  await store.getProvincesData();
  await store.getProvinceDistrictData(3);
  await store.getMunicipalityData(27);
  await store.getCountriesData();
  

  if (getProvinces.value.length > 0) {
    provinceId.value = getProvinces.value[0].id;
  }
  if (getProvinceDistrict.value.length > 0) {
    districtId.value = getProvinceDistrict.value[4].id;
  }
  if (getMunicipality.value.length > 0) {
    municipalityId.value = getMunicipality.value[7].id;
  }
  if (getCountries.value.length > 0) {
    countryId.value = getCountries.value[149].country_id;
  }
  addressValue.value = 'Kumari Marga, Tripura Marg, 44600 2nd Floor, Kathmandu 44600, Nepal'
  let saveAddressModal = document.getElementById('saveaddress')
  saveAddressModal.addEventListener('shown.bs.modal', function (){
        checkObjectBool.value =  Object.keys(props.tempAddressVal).length === 0;
        let propsLength = Object.keys(props.tempAddressVal).length;
        if(propsLength > 0) {
            store.getProvincesData();
            store.getProvinceDistrictData(props.tempAddressVal.province_id);
            store.getMunicipalityData(props.tempAddressVal.province_district_id);
            store.getCountriesData();
            countryId.value = props.tempAddressVal.country;
            provinceId.value = props.tempAddressVal.province_id;
            districtId.value = props.tempAddressVal.province_district_id;
            municipalityId.value = props.tempAddressVal.municipality_id;
            wardNo.value = props.tempAddressVal.ward_id;
            addressValue.value = props.tempAddressVal.location_address;
            mapUrl.value = props.tempAddressVal.location;
            const newPosition = {
                lat: parseFloat(props.tempAddressVal.lat),
                lng: parseFloat(props.tempAddressVal.lng)
            };
            markerPosition.value = newPosition;
            addressTitle.value = props.tempAddressVal.address;
            isChecked.value = props.tempAddressVal.is_default;
        }
        else {
            provinceId.value = getProvinces.value[0].id;
            districtId.value = getProvinceDistrict.value[4].id;
            municipalityId.value = getMunicipality.value[7].id;
            countryId.value = getCountries.value[149].country_id;
            wardNo.value = null;
            isChecked.value = 0;
        }
  })
  saveAddressModal.addEventListener('hidden.bs.modal', function (){
     store.getProvincesData();
     store.getProvinceDistrictData(3);
     store.getMunicipalityData(27);
     store.getCountriesData();
  })
});

const markerPosition = ref({ lat: 27.6931196034988, lng: 85.31704387320124 });
function handleMarkerPositionChange(event){
  const newPosition = {
    lat: event.latLng.lat(),
    lng: event.latLng.lng()
  };
  markerPosition.value = newPosition;
  getMapUrl(newPosition);
  getAddressName();
}
function getAddressName() {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: markerPosition.value }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            const addressName = results[0].formatted_address;
            addressValue.value= addressName;
          } else {
    
          }
        } else {
          console.log('Geocoder failed due to: ' + status);
        }
      });
}
function getMapUrl(latlng){
    return mapUrl.value = `https://www.google.com/maps/search/?api=1&query=${latlng.lat},${latlng.lng}`
}
function getDistricts(id) {
    store.getProvinceDistrictData(id);
    districtId.value = null;
    municipalityId.value = null;
}
function getMunicipalities(id) {
    store.getMunicipalityData(id);
    municipalityId.value = null

}
function saveAddress(){
    var body = {
        'country': countryId.value,
        'province': provinceId.value,
        'province_district': districtId.value,
        'municipality': municipalityId.value,
        'ward': wardNo.value,
        'location': mapUrl.value,
        'location_address' : addressValue.value,
        'lat' : markerPosition.value.lat ,
        'lng' : markerPosition.value.lng,
        'is_default': isChecked.value,
        'patient_id': getUser.value.id,
        'address': addressTitle.value
    }
    store.storeAddress(body);
    store.getAddressDetailsData();
}
function updateAddressData(){
    var body = {
        'country': countryId.value,
        'province': provinceId.value,
        'province_district': districtId.value,
        'municipality': municipalityId.value,
        'ward': wardNo.value,
        'location': mapUrl.value,
        'location_address' : addressValue.value,
        'lat' : markerPosition.value.lat ,
        'lng' : markerPosition.value.lng,
        'is_default': isChecked.value,
        'patient_id': getUser.value.id,
        'id': props.tempAddressVal.id,
        'address': addressTitle.value
    }
    store.updateAddress(body);
    store.getAddressDetailsData(); 
}
</script>
<style scoped>
.width-100 {
    width:100%;
}
.acc_input {
    font-size: 0.75rem;
    color: rgb(58, 58, 58);
}
</style>