import { defineStore } from "pinia";
import axios from "axios";
export const useServices = defineStore("services", {
  state: () => ({
    services: null,
    servicesDepartmentWise: null,
    popularServices: null,
    bannerData: null,
    // bookedServiceCategory : null
    teleCons:[],
  }),
  getters: {
    getServices: (state) => {
      return state.services;
    },
    getBanner: (state) => {
      return state.bannerData;
    },
    getServicesDepartmentWiseData: (state) => {
      return state.servicesDepartmentWise;
    },
    getpopularServices: (state) => {
      return state.popularServices;
    },
    getTeleCons:state=>state.teleCons
  },
  // note: please maintain a api.js to store api endppoints
  // note: please handle api calls in seprate service module
  actions: {
    async getServicesList() {
      await axios.get("/getDepartments").then((response) => {
        if (response.status === 200) {
          this.services = response.data.departments;
        }
      });
    },
    async getBannerData() {
      this.bannerData = null;
      await axios.get("/getBanner").then((response) => {
        if (response.status === 200) {
            this.bannerData = [...response.data?.data];
        }
      });
    },
    async getServicesDepartmentWise(id) {
      this.servicesDepartmentWise=null
      await axios
        .get("/getServicesDepartmentWise?department_id=" + id)
        .then((response) => {
          // this.servicesDepartmentWise = response.data.services;
          let temp_arr;
          temp_arr = response.data.services;
          let x = temp_arr.map((item) => {
            return { ...item, isChecked: 0 };
          });
          this.servicesDepartmentWise = x;
          let popular_arr;
          popular_arr = response.data.popular_services;
          let y = popular_arr.map((item) => {
            return { ...item, isChecked: 0 };
          });
          this.popularServices = y;
        });
    },
    async fetchTeleCons() {
      if(this.teleCons.length==0){
      // this.teleCons = [];
      await axios.get("/getTeleCons").then((response) => {
        if (response.status === 200) {
            this.teleCons = [...response.data?.tele_cons];
        }
      });
    }
    },
  },
});
