<template>
    <div class="container-fluid">
        <div class="row ctm-padding">
           <div class="col-12">
            <h6>My Services</h6>
            <div class="d-flex">
                <tablist :class="{ activeTab: index == selectedTab }" 
                        @click="getServiceCategoryData(index,tabs)"
                        class="dateTabList c-pointer" v-for="(tabs, index) in service_data_categories" :key="index" :date="tabs">
                </tablist>
            </div>
            <div class="d-flex justify-content-end">
              <input  v-model="searchFieldInput" type="text" class="main_search"  placeholder="Search by bill number">
            </div>
 <vue-good-table :columns="columns" :rows="rows"  :fixed-header="true"  :line-numbers="false"     :sort-options="{
    enabled: false,
  }"  :pagination-options="{
    enabled: true,
    mode: 'records',
    perPageDropdown: [10, 20, 50],
    dropdownAllowAll: false,
  }"  
    :search-options="{
    enabled: false,
    externalQuery: searchFieldInput
  }"  class=" mt-3"  styleClass="vgt-table striped " >  
      <template  #table-row="props">

          <span v-if="props.column.field=='sn'"> {{props.formattedRow[props.column.field]}}</span>
          <span v-if="props.column.field=='bill_no'"> {{props.formattedRow[props.column.field]}}</span>
          <span v-if="props.column.field=='itemName'"> {{props.formattedRow[props.column.field]}}</span>
          <span  v-if="props.column.field == 'status'">
            <button
              :class="{
                
                requested_btn: props.formattedRow[props.column.field] === 'requested',
                pending_btn: props.formattedRow[props.column.field] === 'pending',
                completed_btn: props.formattedRow[props.column.field] === 'completed',
                cancelled_btn:props.formattedRow[props.column.field] === 'canceled'
              }"
              class="me-2 btn-mini btn-mini-pay"
            >
              {{ props.formattedRow[props.column.field]  }}
            </button>
          </span>
          <span   v-if="props.column.field == 'payment'" >
            <button v-if="props.formattedRow['status'] == 'ongoing' && getopdPatientRegId"  @click="proceedToPay(props.formattedRow[props.column.field])"  class="me-2 btn-mini">Proceed to Pay</button>
          </span>
          <span v-if="props.column.field == 'action'">
            <font-awesome-icon class="c-pointer" @click="viewOrderDetail(props.formattedRow[props.column.field])" icon="fa-solid fa-eye" />
          </span>
          <span v-else> </span>
        </template>
        </vue-good-table>
            <div></div>
           </div>
        </div>
    </div>
    <orderdetails></orderdetails>
</template>

<script setup>
import tablist from "@/components/tablist.vue"
import { ref, onMounted, onUpdated,computed } from 'vue'
import { storeToRefs } from "pinia"
import { useSelfOrder } from '@/core/modules/self/order'
import router from '@/router/index'
import orderdetails from '@/components/modal/orderdetails'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { VueGoodTable } from 'vue-good-table-next';
import {useManageUser} from "@/core/modules/auth/user";

const { getMyOrders } = storeToRefs(useSelfOrder())
const { getopdPatientRegId } = storeToRefs(useManageUser())

const store = useSelfOrder();
const service_data_categories = ref([ 'requested','pending', 'ongoing', 'completed'])
const selectedTab = ref('');
const searchFieldInput=ref('');


const columns = ref([
  {
    label: 'SN',
    field: 'sn',
    type: 'text',
  },
  {
    label: 'Bill No.',
    field: 'bill_no',
    type: 'text',
  },
  {
    label: 'Service Name',
    field: 'itemName',
    type: 'text',
  },
  {
    label: 'Status',
    field: 'status',
    type: 'text',
 
  },
  {
    label: 'Payment',
    field: 'payment',
    type: 'text',
 
  },
  {
    label: 'Action',
    field: 'action',
    type: 'text',
 
  },

])
const rows = computed(() => {
  return getMyOrders.value.map((bill, index) => ({
    sn: index + 1,
   ...bill,
    action: bill.sbillDetailId,
    bill,
  }));
});


onMounted(() => {
    store.getOrder('requested');
})


function getServiceCategoryData(index,tabs) {
    selectedTab.value = index;
    // if(tabs==='all'){
    //   store.getOrder('')
    // }
    // else{
    store.getOrder(tabs)
    // }
}

function proceedToPay(bill){
    router.push({ name: 'page.self.checkout', params: {id: bill.billId}})
}

function viewOrderDetail(id){
    store.viewOrder(id);
}
</script>

<style scoped>
.requested_btn {
    background-color: var(--foundation----primary---p100) !important;
}
.pending_btn {
    background-color: var(--foundation----secondary---s100) !important;
}

.completed_btn {
    background-color: var(--foundation----secondary---s300) !important;
}
.cancelled_btn {
    background-color: var(--foundation----danger---d300) !important;
}
</style>