import axios from "axios";
import { defineStore } from "pinia";
import { Modal } from "bootstrap";
import { toast } from "vue3-toastify";
export const useSelfPurchaseHistory = defineStore("selfPurchase", {
  state: () => ({
    myPurchaseHistory: [],
    myPurchaseDetail: [],
    currentSelectedBillDetail:'',
    pdfDetail:'',
    errors: [],
    finalBill:[]
  }),
  getters: {
    getMyPurchaseHistory: (state) => {
      return state.myPurchaseHistory;
    },
    getErrors: (state) => {
      return state.errors;
    },
    getMyPurchaseDetail: (state) => {
      return state.myPurchaseDetail;
    },
    getCurrentSelectedBillDetail:state=>state.currentSelectedBillDetail,
    getCurrentPDf:state=>state.pdfDetail,
    getFinalBill: state=> state.finalBill
  },
  actions: {
    async getPurchaseHistory(tab=0) {
      try {
        await axios
          .get("/getItemPurchaseHistory",{params:{
            'purchaseHistory':tab,
          }})
          .then((response) => {
            if (response.status === 200) {
              this.myPurchaseHistory = response.data.data;
            }
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.myPurchaseHistory=[]
            toast.error(error.response.data?.message??"Something went wrong")

            
          });
      } catch (error) {
        this.errors = error.response.data;
        this.myPurchaseHistory=[]
        toast.error(error.response.data?.message??"Something went wrong")
        
      }
    },

    async getFinalBillData(tab=0) {
      try {
        await axios
          .get("/getFinalBillData",{
            'getFinalBillData':tab,
          })
          .then((response) => {
            if (response.status === 200) {
              this.finalBill = response.data.masterBillData;
            }
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.finalBill=[]
            toast.error(error.response.data?.message??"Something went wrong")

            
          });
      } catch (error) {
        this.errors = error.response.data;
        this.myPurchaseHistory=[]
        toast.error(error.response.data?.message??"Something went wrong")
        
      }
    },

    // async getFinalBillData(tab=1) {
    //   try {
    //     await axios
    //       .get("/pdf/bill_print/${orderItem.billNo}/${orderItem.opdRegistrationId} ",{params:{
    //         'purchaseHistory':tab,
    //       }})
    //       .then((response) => {
    //         if (response.status === 200) {
    //           this.myPurchaseHistory = response.data.data;
    //         }
    //       })
    //       .catch((error) => {
    //         this.errors = error.response.data;
    //         this.myPurchaseHistory=[]
    //         toast.error(error.response.data?.message??"Something went wrong")

            
    //       });
    //   } catch (error) {
    //     this.errors = error.response.data;
    //     this.myPurchaseHistory=[]
    //     toast.error(error.response.data?.message??"Something went wrong")
        
    //   }
    // },
   
    async viewwPurchaseDetail(bill) {

      try {
        await axios
          .get("orderListDetails" ,{params:{
            'id':bill.bill_id,
          }})
          .then((response) => {
            this.currentSelectedBillDetail=bill
            this.pdfDetail=''
              this.myPurchaseDetail = response.data.data;
              let modal1 = Modal.getOrCreateInstance("#purchaseDetail");
              modal1.show();
            
          })
          .catch((error) => {
            this.currentSelectedBillDetail=''
            this.myPurchaseDetail=[]
        toast.error(error.response.data?.message??"Something went wrong")

            this.errors = error.response.data;
          });
      } catch (error) {

      
       
        this.errors = error.response.data;
      }
    },
    async viewPurchasePdf(bill) {

      try {
        await axios
          .get("v2/pdf/final_bill/"+bill.bill_no,{
            responseType: "blob",
          })
          .then((response) => {
            const blob = new Blob([response.data], { type: "application/pdf" });
            const objectUrl = URL.createObjectURL(blob);
            this.currentSelectedBillDetail=bill
            this.pdfDetail=objectUrl

           this.myPurchaseDetail=[]
              this.myPurchaseDetail = response.data.data;
              let modal1 = Modal.getOrCreateInstance("#purchaseDetail");
              modal1.show();
            
          })
          .catch((error) => {
            this.currentSelectedBillDetail=''
            this.myPurchaseDetail=[]
        toast.error(error.response.data?.message??"Something went wrong")

            this.errors = error.response.data;
          });
      } catch (error) {

      
       
        this.errors = error.response.data;
      }
    },
 
  },
});
