<template>
  <div class="container-fluid">
    <div class="row ctm-padding">
      <div class="col-12">
        <h6>My Cart</h6>
        <div class="row p-0">
          <div class="col-9 col-60-md col-100-sm">
            <div class="d-flex justify-content-between" style="height: 40px">
              <div v-if="getCartDetailsData.length > 0">
                <input
                  type="checkbox"
                  v-model="isSelectAll"
                  class="me-2"
                  id="select_all_checkbox"
                  @change="selectAllServices($event)"
                />

                <label for="select_all_checkbox">Select All</label>
              </div>

              <font-awesome-icon
                class="btn btn-danger"
                data-bs-target="#confirmdelete"
                data-bs-toggle="modal"
                v-if="tempCurrentSelectedIds.length > 0 && getIsAuthorized"
                @click="cancelSelectedSerices()"
                style="margin: 4px"
                icon="fa fa-solid fa-trash"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="col-9 col-60-md col-100-sm" v-if="!getIsAuthorized">
        <noRecordComp
          v-if="getTempCartDetail.length < 1"
          :title="'NO RECORD FOUND'"
        ></noRecordComp>

        <div v-for="(service, index) in getTempCartDetail" :key="index">
          <div class="checkout-container px-3 py-2 mb-3">
            <div class="d-flex justify-content-between">
              <div>
                <input
                  type="checkbox"
                  class="me-2"
                  :id="`checkbox_${service.id}`"
                  v-model="service.isChecked1"
                  @change="
                    handleCheckboxChange(
                      $event,
                      index,
                      service,
                      getIsAuthorized
                    )
                  "
                />
                <label :for="`checkbox_${service.id}`">{{
                  service.name
                }}</label>
                <br />
                <!-- <div class="ps-4">
                                    <span>Amount: &nbsp;</span>
                                    <span style="font-weight: 500">NPR. {{service.sub_total}}</span>
                                </div> -->
              </div>
              <div>
                <!-- <font-awesome-icon class="c-pointer" data-bs-target="#confirmdelete" data-bs-toggle="modal" @click="cancelService(service.id)" style="color: #ff3030" icon = "fa-solid fa-close"/> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-9 col-60-md col-100-sm" v-if="getIsAuthorized">
        <SpinnerDataLoader
          :classname="'text-primary'"
          v-if="getLoaderStatus"
        ></SpinnerDataLoader>
        <noRecordComp
          v-if="getCartDetailsData.length < 1 && !getLoaderStatus"
          :title="'NO RECORD FOUND'"
        ></noRecordComp>
        <div v-for="(service, index) in getCartDetailsData" :key="index">
          <div class="checkout-container px-3 py-2 mb-3">
            <div class="d-flex justify-content-between">
              <div>
                <input
                  type="checkbox"
                  class="me-2"
                  :id="`checkbox_${service.id}`"
                  v-model="service.isChecked"
                  @change="
                    handleCheckboxChange(
                      $event,
                      index,
                      service,
                      getIsAuthorized
                    )
                  "
                />
                <label :for="`checkbox_${service.id}`">{{
                  service.service
                }}</label>
                <br />
                <div class="ps-4">
                  <span>Price: &nbsp;</span>
                  <span style="font-weight: 500"
                    >NPR. {{ service.price.amount?.toLocaleString() }}</span
                  >
                </div>
                <div class="ps-4">
                  <span>Quantity: &nbsp;</span>
                  <span style="font-weight: 500"
                    > {{ service.daysDiff }}</span
                  >
                </div>
                <div class="ps-4">
                  <span>Sub Total: &nbsp;</span>
                  <span style="font-weight: 500"
                    >NPR. {{ service.sub_total?.toLocaleString() }}</span
                  >
                </div>

                
                <div v-if="service.is_multiple">
                   <div class="ps-4">
                  <span    v-if="service.is_nursing">From Date: &nbsp;</span>
                  <span    v-else>Date Time: &nbsp;</span>
                  <span style="font-weight: 500"> {{ service.package_date_from }}</span>
                </div>
                   <div class="ps-4">
                  <span    v-if="service.is_nursing">To Date: &nbsp;</span>
                  <span style="font-weight: 500"    v-if="service.is_nursing"> {{ service.package_date_to }}</span>
                </div>
                </div>

                <div v-else>

                  </div>


               <div v-if="service.pick_up_location">
                   <div class="ps-4">
                  <span>Pick-up Location: &nbsp;</span>
                  <span style="font-weight: 500">{{ service.pick_up_location }} </span>
                </div>
                   <div class="ps-4">
                  <span>Drop-off Location: &nbsp;</span>
                  <span style="font-weight: 500">{{ service.drop_location}} </span>
                </div>
               </div>

               <div v-else>
                  <!-- <div class="ps-4">
                  <span>Pick-up Location: &nbsp;</span>
                  <span style="font-weight: 500"> </span>
                </div>
                   <div class="ps-4">
                  <span>Drop-off Location: &nbsp;</span>
                  <span style="font-weight: 500"> </span>
                </div> -->
                </div>

                <div  v-if="service.display_name">
                   <div class="ps-4">
                  <span>Mode of Teleconsultation: &nbsp;</span>
                  <span style="font-weight: 500"
                    > {{ service.display_name }}</span
                  >
                </div>
                </div>

                <div v-else>
                  </div>
              </div>
              <div>
                <!-- <font-awesome-icon class="c-pointer" data-bs-target="#confirmdelete" data-bs-toggle="modal" @click="cancelService(service.id)" style="color: #ff3030" icon = "fa-solid fa-close"/> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3 col-40-md col-100-sm" v-if="!getIsAuthorized">
        <div class="checkout-container px-3 py-2">
          <span class="fw-semibold">Service Summary</span>
          <!-- <div
            class="d-flex justify-content-between mt-1"
            style="font-size: 1rem"
          >
            <span>Total Services : </span>
            <span class="fw-semibold">{{ tempCurrentSelectedIds.length }}</span>
          </div> -->

          <button
            class="btn-meraki-primary mt-3 mb-2"
            @click="proceedOrder"
            style="width: 100%"
          >
            Proceed to Order Request
          </button>
        </div>
      </div>
      <div class="col-3 col-40-md col-100-sm" v-if="getIsAuthorized">
        <div class="checkout-container px-3 py-2">
          <span class="fw-semibold">Service Summary</span>
          <!-- <div
            class="d-flex justify-content-between mt-1"
            style="font-size: 1rem"
          >
            <span>Total Services : </span>
            <span class="fw-semibold">{{ cartDetail.length }}</span>
          </div> -->
          <div
            class="d-flex justify-content-between mt-1"
            style="font-size: 1rem"
          >
            <span>Total Amount : </span>
            <span class="fw-semibold">NPR. {{ total_amount?.toLocaleString() }}</span>
          </div>
          <button
            class="btn-meraki-primary mt-3 mb-2"
            @click="proceedOrder"
            style="width: 100%"
          >
            Proceed to Order
          </button>
        </div>
      </div>
    </div>
  </div>
  <confirmdelete
    :cart="cart_detail"
    :total="total_amount"
    :selectDeleted="deleteAllSelected"
    @completeapicall="resetcart"
  ></confirmdelete>
  <proceedorder
    :selectedCartItems="selected_cart_items"
    :formattedDate="formattedDate"
    :totalAmt="total_amount"
  ></proceedorder>
</template>

<script setup>
import { onMounted, ref, computed, watch, onUpdated } from "vue";
import { storeToRefs } from "pinia";
import { useSelfCart } from "@/core/modules/self/mycart";
import moment from "moment";
import confirmdelete from "@/components/modal/confirmdelete";
import proceedorder from "@/components/modal/proceedorder";
import Swal from "sweetalert2/dist/sweetalert2.js";
import router from "@/router/index";
import { Modal } from "bootstrap";
import { useManageUser } from "@/core/modules/auth/user";
import SpinnerDataLoader from "@/components/loaders/spinnerloader";
import noRecordComp from "@/components/norecord";
const { getCartDetailsData, getLoaderStatus, getTempCartDetail } = storeToRefs(
  useSelfCart()
);
const { getIsAuthorized, getUser, getopdPatientRegId } = storeToRefs(
  useManageUser()
);
const store = useSelfCart();
const cartDetail = ref([]);
const isSelectAll = ref(true);
const totalAmount = ref(0);
const tempCurrentSelectedIds = ref([]);
const isSingleDeleteByCross = ref([]);
const currentDate = ref();
const formattedDate = ref("");

moment().format();
const triggerEvent = () => {
      const event = new Event('change');
      document.getElementById('select_all_checkbox').dispatchEvent(event);
    };

onMounted(async() => {
  await store.getCartDetails();
  await store.getCartQuantity();
  triggerEvent();
  if (!getIsAuthorized.value) {
    cartDetail.value = getTempCartDetail.value.filter((element) => {
      if (element.isChecked1) return element;
    });
    tempCurrentSelectedIds.value = getTempCartDetail.value.filter((element) => {
      if (element.isChecked1) return element.id;
    });
  }
});

function resetcart(val) {
  if (val) {
    tempCurrentSelectedIds.value = [];
    isSelectAll.value = false;
    totalAmount.value = 0;
    cartDetail.value = [];
  }
}

const deleteAllSelected = computed(() => {
  return tempCurrentSelectedIds.value;
});
function cancelSelectedSerices() {
  return null;
}

function handleCheckboxChange(event, index, service, isAuth) {
  if (isAuth) {
    if (isSelectAll.value == true) {
      isSelectAll.value = false;
    }
    const selectedId = parseInt(event.target.value);
    if (event.target.checked) {
      tempCurrentSelectedIds.value.push(service.id);

      cartDetail.value.push(service);
      if (
        getCartDetailsData.value.length == cartDetail.value.length &&
        isSelectAll.value == false
      ) {
        isSelectAll.value = true;
      } else {
        isSelectAll.value = false;
      }
      totalAmount.value += service.sub_total;
    } else {
      const index = cartDetail.value.indexOf(selectedId);
      const tempIndex = tempCurrentSelectedIds.value.indexOf(service.id);

      if (tempIndex > -1) {
        tempCurrentSelectedIds.value.splice(tempIndex, 1);
      }

      if (index == -1) {
        cartDetail.value.splice(index, 1);
        totalAmount.value = 0;
        for (const amount of getCartDetailsData.value) {
          if (amount.isChecked) {
            totalAmount.value += amount.sub_total;
          }
        }
      }
    }
  } else {
    const selectedId = parseInt(event.target.value);
    if (event.target.checked) {
      tempCurrentSelectedIds.value.push(service.id);
      cartDetail.value.push(service);
    } else {
      const tempIndex = tempCurrentSelectedIds.value.indexOf(service.id);
      const index = cartDetail.value.indexOf(service);

      if (tempIndex > -1) {
        tempCurrentSelectedIds.value.splice(tempIndex, 1);
      }
      if (index > -1) {
        cartDetail.value.splice(tempIndex, 1);
      }
    }
  }
}

function selectAllServices(event) {
  cartDetail.value.length = 0;
  if (event.target.checked) {
    totalAmount.value = 0;
    tempCurrentSelectedIds.value = [];
    getCartDetailsData.value.forEach((group) => {
      group.isChecked = true;
      totalAmount.value += group.sub_total;
      cartDetail.value.push(group);
      tempCurrentSelectedIds.value.push(group.id);
    });
  } else {
    getCartDetailsData.value.forEach((group) => {
      group.isChecked = false;
      totalAmount.value = 0;
      cartDetail.value = [];
      tempCurrentSelectedIds.value = [];
    });
  }
}

function proceedOrder() {
  if (getIsAuthorized.value) {
    if (cartDetail.value.length === 0) {
      Swal.fire({
        title: "Error",
        text: "Please select atleast one item from the cart",
        icon: "error",
        confirmButtonText: "OK",
      });
    } else {
      let currentDate = new Date();
      currentDate.setUTCHours(currentDate.getUTCHours() + 5);
      currentDate.setUTCMinutes(currentDate.getUTCMinutes() + 45);
      currentDate.setUTCHours(currentDate.getUTCHours() + 1);

      formattedDate.value = currentDate
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");
      $("#order_datetime").val(formattedDate.value);

      let modal1 = Modal.getOrCreateInstance("#proceedtoorder");
      modal1.show();
    }
  } else {
    if (cartDetail.value.length === 0) {
      Swal.fire({
        title: "Error",
        text: "Please select atleast one item from the cart",
        icon: "error",
        confirmButtonText: "OK",
      });
    } else {
      router.push({
        name: "page.aboutus.contactus",
        params: { tempCart: cartDetail },
      });
    }
  }
}

const selected_cart_items = computed(() => {
  return cartDetail.value;
});

const total_amount = computed(() => {
  return totalAmount.value;
});
const cart_detail = computed(() => {
  return cartDetail.value;
});
</script>

<style scoped></style>
