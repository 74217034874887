import axios from "axios";
import { defineStore } from "pinia";
import { toast } from "vue3-toastify";
export const useSelfAddress = defineStore('selfAddress', {
    state: () => ({
        errors : [],
        provinces : [],
        provinceDistrict: [],
        municipalities: [],
        countries: [],
        loaderStatus: true,
        addressValue: '',
        addressDetails: []
    }),
    getters: {
        getErrors: (state) => {
            return state.errors
        },
        getProvinces: (state) => {
            return state.provinces
        },
        getProvinceDistrict: (state) => {
            return state.provinceDistrict;
        },
        getMunicipality: (state) => {
            return state.municipalities;
        },
        getCountries: (state) => {
            return state.countries;
        },
        getAddressValue: (state) => {
            return state.addressValue
        },
        getAddressDetails: (state) => {
            return state.addressDetails;
        }
    },
    actions: {
        async getCountriesData() {
            try {
                this.loaderStatus = true;
                await axios
                    .get('patient/country')
                    .then((response) => {
                        this.loaderStatus = false;
                        this.countries = response.data;
                    })
                    .catch((error) => {
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                this.loaderStatus = false;
            }
        },
        async getProvincesData() {
            try {
                this.loaderStatus = true;
                await axios
                    .get('patient/fetchProvince')
                    .then((response) => {
                        this.loaderStatus = false;
                        this.provinces = response.data;
                    })
                    .catch((error) => {
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                this.loaderStatus = false;
            }
        },
        async getProvinceDistrictData(id) {
            try {
                this.loaderStatus = true;
                await axios
                    .get('patient/fetchProvinceDistrict/'+id)
                    .then((response) => {
                        this.loaderStatus = false;
                        this.provinceDistrict = response.data;
                    })
                    .catch((error) => {
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                
                this.loaderStatus = false;
            }
        },
        async getMunicipalityData(id) {
            try {
                this.loaderStatus = true;
                await axios
                    .get('patient/fetchMunicipality/'+id)
                    .then((response) => {
                        this.loaderStatus = false;
                        this.municipalities = response.data;
                    })
                    .catch((error) => {
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                
                this.loaderStatus = false;
            }
        },
        async storeAddress(body) {
            try {
                this.loaderStatus = true;
                await axios
                    .post('patient/storeAddress',body)
                    .then((response) => {
        
                        this.loaderStatus = false;
                        if(response.status === 200) {
                            toast.success(response.data.message, {
                                autoClose: 500,
                              }); 
                        }
                    })
                    .catch((error) => {
                        
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                this.loaderStatus = false;
            }
        },
        async getAddress(latlng) {
            try {
                this.loaderStatus = true;
                await axios
                    .get('https://maps.googleapis.com/maps/api/geocode/json?latlng='+latlng.lat+','+latlng.lng+'&key=AIzaSyDzuT9vxxZvX6hvRO1gJzKEC5pWgj0Sk7Y')
                    .then((response) => {
                        this.loaderStatus = false;
                    })
                    .catch((error) => {
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                this.loaderStatus = false;
            }
        },
        async getAddressDetailsData() {
            try {
                this.loaderStatus = true;
                await axios
                    .get('patient/getAddress')
                    .then((response) => {
                        this.loaderStatus = false;
                        this.addressDetails = response.data.data.patientAddress;
                    })
                    .catch((error) => {
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                
                this.loaderStatus = false;
            }
        },
        async deleteAddress(body) {
            try {
                await axios
                    .post('patient/deleteAddress', body)
                        .then((response) => {
                            if(response.status === 200) {
                                toast.success(response.data.message, {
                                    autoClose: 500,
                                  }); 
                            }
                        })
                        .catch((error) => {
                            this.loaderStatus = false;
                        })
            }
            catch(error) {
                
            }
        },
        async updateAddress(body) {
            try {
                this.loaderStatus = true;
                await axios
                    .post('patient/updateAddress',body)
                    .then((response) => {
                        this.loaderStatus = false;
                    })
                    .catch((error) => {
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                this.loaderStatus = false;
            }
        }
    }
})