<template>
<div class="d-flex  align-items-center p-3 mb-3 c-pointer card-container"
     :class="bg ? getSelectCardId != patientid ? 'card-secondary' : 'card-success' : 'card-primary'"
     @click="selectAccount(patientid, token)">

    <div class="avatar-container me-3">
        <img src="@/assets/images/user.png" alt="User Avatar" class="avatar rounded-circle bg-white">
    </div>
    <div class="info-container">
        <h5 class="patient-id text-white mb-1">{{ patientid }}</h5>
        <p class="patient-name text-white m-0 ">{{ patientname }}</p>
        <p class="registration-date text-white m-0 small">{{ regdate }}</p>
    </div>
</div>
</template>


<script setup>
import { useComponentCardProfile } from '@/core/modules/components/card/profile'
import { useSelectAccount } from '@/core/modules/manage/selectaccount'
import { storeToRefs } from 'pinia';
import {useManageUser} from '@/core/modules/auth/user'
import { onMounted } from 'vue';
const props = defineProps({
    patientid: Number,
    patientname: String,
    regdate: String,
    avatar: String,
    token: String,
    bg: Boolean
});
const componentCardProfile = useComponentCardProfile();
const selectAccountStore = useSelectAccount();
const manageUserStore =  useManageUser();
const { getSelectCardId } = storeToRefs(useComponentCardProfile())
const {getUser} = storeToRefs(useManageUser())
const emit = defineEmits(['setselecttoken'])


onMounted(()=>{  
    componentCardProfile.selectAccount(manageUserStore.getUser.patient_id);
})


function selectAccount(patient_id, token = '') {
    componentCardProfile.selectAccount(patient_id);
    if (token != '') {
        selectAccountStore.setSelectToken(token)
    }
}


</script>
<style scoped>

    .fo-bg-color-success-s50 {
        background-color: #198754;
    }

    .card-container {
    transition: box-shadow 0.3s ease-in-out;
}

.card-container:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-primary {
    background-color: #007bff;
    border: 2px solid #0056b3;
}

.card-secondary {
    background-color: #1f01427d;
    border: 2px solid #1f01427d;
}

.card-success {
    background-color: var(--foundation----primary---p300);
    border: 2px solid var(--foundation----primary---p300);
}

.avatar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px; 
}

.avatar {
    width: 100%;
    height: auto;
}

.info-container {
    flex-grow: 1;
}

.patient-id {
    font-size: 1rem;
    font-weight: bold;
}

.patient-name {
    font-size: 0.9rem;
}

.registration-date {
    font-size: 0.8rem;
}
</style>