<template>
<div class="container-fluid" style="background: var(--foundation----background---b100);
">
    <div class="row">
        <div class="col-9 mx-auto my-5">
            
            <div class="p40_50 forms-bg">
                <div class=" mb-2 ">
                    <p style="color: var(--foundation----primary---p300)" class=" fw-semibold text-start fs-4">Register Your Account Here!</p>
                    <p style="font-size: 12px; margin-top: -10px">Fields with <span style="color: red; ">*</span> are mandatory</p>
                </div>
                <div class="row">
                    <span style="color: var(--foundation----primary---p300)" class="fs-5 fw-semibold text-start mb-2">Basic Details</span>
                    <div class="col-3 col-md-50">
                        <form>
                            <div class="mb-4">
                                <label for="register_fullname" class="form-label fs-15">Firstname <span style="color: red">*</span></label>
                                <input placeholder="Fullname " type="text" class="form-control " id="register_fullname" >
                            </div>
                        </form>
                    </div>
                    <div class="col-3 col-md-50">
                        <form>
                            <div class="mb-4">
                                <label for="register_lastname" class="form-label fs-15">Lastname <span style="color: red">*</span></label>
                                <input placeholder="Lastname " type="text" class="form-control " id="register_lastname" >
                            </div>
                        </form>
                    </div>
                    <div class="col-3 col-md-50">
                        <form>
                            <div class="mb-4">
                                <label for="register_gender" class="form-label fs-15">Gender <span style="color: red">*</span></label>
                                <input placeholder="Gender " type="text" class="form-control " id="register_gender" >
                            </div>
                        </form>
                    </div>
                    <div class="col-3 col-md-50">
                        <form>
                            <div class="mb-4">
                                <label for="register_dob" class="form-label fs-15">Date of Birth <span style="color: red">*</span></label>
                                <input placeholder="DD/MM/YYYY" onblur="(this.type='text')" type="text" onfocus="(this.type='date')" class="form-control" id="register_dob" >
                            </div>
                        </form>
                    </div>
                    <span style="color: var(--foundation----primary---p300)" class="fs-5 fw-semibold text-start mb-2">Contact Details</span>
                    <div class="col-3 col-md-50">
                        <form>
                            <div class="mb-4">
                                <label for="register_mobile" class="form-label fs-15">Mobile Number <span style="color: red">*</span></label>
                                <input placeholder="Mobile Number " type="number" class="form-control" id="register_mobile" >
                            </div>
                        </form>
                    </div>
                    <div class="col-3 col-md-50">
                        <form>
                            <div class="mb-4">
                                <label for="register_address" class="form-label fs-15">Address <span style="color: red">*</span></label>
                                <input placeholder="Address " type="text" class="form-control" id="register_address" >
                            </div>
                        </form>
                    </div>
                    <div class="col-3 col-md-50">
                        <form>
                            <div class="mb-4">
                                <label for="register_email" class="form-label fs-15">Email Address</label>
                                <input placeholder="Email Address " type="email" class="form-control" id="register_email" >
                            </div>
                        </form>
                    </div>
                    <div class="col-3 col-md-50">
                        <form>
                            <div class="mb-4">
                                <label for="register_google_location" class="form-label fs-15">Google Location</label>
                                <input placeholder="Google Location " type="text" class="form-control" id="register_google_location" >
                            </div>
                        </form>
                    </div>
                    <span style="color: var(--foundation----primary---p300)" class="fs-5 fw-semibold text-start mb-2">Source of Referral</span>
                    <div class="col-3 col-md-50">
                        <form>
                            <div class="mb-4">
                                <label for="register_referral" class="form-label fs-15">How did you hear about us? <span style="color: red">*</span></label>
                                <input placeholder="How did you hear about us? " type="text" class="form-control" id="register_referral" >
                            </div>
                        </form>
                    </div>
                    <span style="color: var(--foundation----primary---p300)" class="fs-5 fw-semibold text-start mb-2">Other Details</span>
                    <div class="col-3 col-md-50">
                        <form>
                            <div class="mb-4">
                                <label for="register_service" class="form-label fs-15">Service Required</label>
                                <input placeholder="Service Required" type="text" class="form-control" id="register_service" >
                            </div>
                        </form>
                    </div>
                        <div class="col-3 col-md-50">
                        <form>
                            <div class="mb-4">
                                <label for="register_photo" class="form-label fs-15">Picture</label>
                                <input placeholder="Upload Picture" type="text" class="form-control" id="register_photo" >
                            </div>
                        </form>
                    
                    </div>
                    <div class="col-3 col-md-50">
                        <form>
                            <div class="mb-4">
                                <label for="register_identity" class="form-label fs-15">Passport/Identity Number</label>
                                <input placeholder="Passport/Identity Number" type="text" class="form-control" id="register_identity" >
                            </div>
                        </form>
                    </div>
                        <div class="col-3 col-md-50">
                        <form>
                            <div class="mb-4">
                                <label for="register_identity_copy" class="form-label fs-15">Passport/Identity Copy</label>
                                <input placeholder="Upload Passport/Identity Copy" type="text" class="form-control" id="register_identity_copy" >
                            </div>
                        </form>
                    
                    </div>
                    <span style="color: var(--foundation----primary---p300)" class="fs-5 fw-semibold text-start mb-2">Guardian Details</span>
                    <div class="col-3 col-md-50">
                        <form>
                            <div class="mb-4">
                                <label for="register_guardian_name" class="form-label fs-15">Guardian Name</label>
                                <input placeholder="Guardian Name" type="text" class="form-control" id="register_guardian_name" >
                            </div>
                        </form>
                    </div>
                        <div class="col-3 col-md-50">
                        <form>
                            <div class="mb-4">
                                <label for="register_guardian_number" class="form-label fs-15">Guardian Contact Number</label>
                                <input placeholder="Guardian Contact Number" type="text" class="form-control" id="register_guardian_number" >
                            </div>
                        </form>
                    
                    </div>
                    <div class="col-3 col-md-50">
                        <form>
                            <div class="mb-4">
                                <label for="register_guardian_relation" class="form-label fs-15">Guardian Relation to Patient</label>
                                <input placeholder="Guardian Relation to Patient" type="text" class="form-control" id="register_guardian_relation" >
                            </div>
                        </form>
                    </div>
                    <div class="row">
                    <div class="col-4">
                    <button type="submit" @click="routeTo()" class="btn btn-primary btn-meraki-primary mb-4 mt-2">Register</button>
                </div>
                </div>
                </div>
                </div>
        </div>
    <!-- </div> -->
    </div>
</div>
</template>

<script setup>
import router from '@/router/index'
// import { ref } from 'vue';
// const password = ref('');
// const reg_address = ref('')
// const password_label = ref("The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc).")
// const strongPassword = ref(true)
// const showPassword = ref('')
// const locationArray = ref([])
// function showPasswordFun() {
//     showPassword.value = (showPassword.value) ? false : true;
// }

// function checkPassword(){
//     // const lengthScore = Math.min(this.password.length - 7, 3);
//       const uppercaseRegex = /[A-Z]/;
//       const lowercaseRegex = /[a-z]/;
//       const digitRegex = /\d/;
//       const specialRegex = /[$-/:-?{-~!"^_`[\]]/;
//       const uppercaseScore = uppercaseRegex.test(this.password) ? 1 : 0;
//       const lowercaseScore = lowercaseRegex.test(this.password) ? 1 : 0;
//       const digitScore = digitRegex.test(this.password) ? 1 : 0;
//       const specialScore = specialRegex.test(this.password) ? 1 : 0;
//       const strength = uppercaseScore + lowercaseScore + digitScore + specialScore;
//       if (strength < 4) {
//         strongPassword.value = false;
//       }  else {
//         strongPassword.value = true;
//       }
// }
// function addLocation(address_val) {
//     if(locationArray.value.length < 3){
//     locationArray.value.push(address_val);
//     reg_address.value = ""}
// }

function routeTo(){
    router.push({name: 'page.auth.otp'})
}
</script>