<template>
    <modalComp :idmodal="'escortingaddress'" :show-header="true" :show-footer="true" :size="'modal-md'">
        <template v-slot:header>
            <h5 class="modal-title">Select Address </h5>
            <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
        </template>
        <template v-slot:body>
            <div>
                <div class="mb-2 mx-2">
                    <div class="row mt-3" style="font-size: 0.75rem">
                        <div class="mb-3">
                            <span class="fw-semibold">Map URL : &nbsp;</span><span>{{mapUrl}}</span>
                        </div>
                        <GoogleMap api-key="AIzaSyDzuT9vxxZvX6hvRO1gJzKEC5pWgj0Sk7Y" style="width: 100%; height: 30vh" :center="markerPosition" @click="handleMarkerPositionChange" :zoom="15">
                            <Marker :options="{ position : markerPosition }" draggable />
                          </GoogleMap>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div class="p-2">
                <button class="btn-meraki-secondary me-2" data-bs-dismiss="modal" >Cancel</button>
               
                <button v-if="checkObjectBool" class="btn-meraki-primary" data-bs-dismiss="modal" @click="saveAddress" >Save</button>
                <!-- <button v-else class="btn-meraki-primary" data-bs-dismiss="modal" @click="updateAddressData" >Update</button> -->
            </div>
        </template>

    </modalComp>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import modalComp from '@/components/common/modal';

import { GoogleMap, Marker } from "vue3-google-map";


const addressValue = ref(null);

const checkObjectBool = ref(null)
const mapUrl = ref('https://www.google.com/maps/search/?api=1&query=27.6931196034988,85.31704387320124')
const props = defineProps({
    tempAddressVal: Object
})


const markerPosition = ref({ lat: 27.6931196034988, lng: 85.31704387320124 });
function handleMarkerPositionChange(event){
  const newPosition = {
    lat: event.latLng.lat(),
    lng: event.latLng.lng()
  };
  markerPosition.value = newPosition;
  getMapUrl(newPosition);
  getAddressName();
}
function getAddressName() {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: markerPosition.value }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            const addressName = results[0].formatted_address;
            addressValue.value= addressName;
          } else {
    
          }
        } else {
        //   console.log('Geocoder failed due to: ' + status);
        }
      });
}
function getMapUrl(latlng){
    return mapUrl.value = `https://www.google.com/maps/search/?api=1&query=${latlng.lat},${latlng.lng}`
}


function saveAddress(){
// emit the address from there to there
}

</script>
<style scoped>
.width-100 {
    width:100%;
}
.acc_input {
    font-size: 0.75rem;
    color: rgb(58, 58, 58);
}
</style>