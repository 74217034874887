<template>
  <div class="container-fluid">
    <div class="row ">
      <div class="col-1">
              </div>
            <div class="col-11">
        <h6>My Vitals</h6>
      </div>
      <div class="row">
      <div class="col-1"></div>


      <div class="col-11">
        <div>

          <h7>Latest Vital Data</h7>
          <SpinnerDataLoader :classname="'text-primary'"  v-if="getLoaderStatus"></SpinnerDataLoader>
          <!-- <div v-for="(vitals, index) in getPatientLatestVitals" :key="index"> -->
            <noRecordComp v-if="!getPatientLatestVitals && !getLoaderStatus" :title="'NO RECORD FOUND'"></noRecordComp>
            <div class="vital-container" v-if="getPatientLatestVitals && !getLoaderStatus">
            
              <div class="vital-icons">
                <div class="vital-icons-div">
                  <div class="vital-div-child">
                    <div>
                      <img
                        class="align_texts"
                        style="height: 25px"
                        src="@/assets/icons/bmi.svg"
                        alt="bmi"
                      />
                    </div>
                    <div>
                      <span class="vitals-span-title align_texts">BMI</span>
                    </div>
                    <div>
                      <span class="vitals-span align_texts"
                        >{{ Number(getPatientLatestVitals.bmi)?.toFixed(2) }} kg/m<sup >2</sup></span
                      >
                    </div>
                  </div>
                  <div class="vital-div-child">
                    <div>
                      <img
                        class="align_texts"
                        style="height: 25px"
                        src="@/assets/icons/height.svg"
                        alt="height"
                      />
                    </div>
                    <div>
                      <span class="vitals-span-title align_texts">Height</span>
                    </div>
                    <div>
                      <span class="vitals-span align_texts">{{
                        Math.floor(getPatientLatestVitals.height / 2.54 / 12) }}ft {{  Math.round((getPatientLatestVitals.height / 2.54) % 12)}}in

                      </span>
                    </div>
                  </div>
                  <div class="vital-div-child">
                    <div>
                      <img
                        class="align_texts"
                        style="height: 25px"
                        src="@/assets/icons/weight.svg"
                        alt="weight"
                      />
                    </div>
                    <div>
                      <span class="vitals-span-title align_texts">Weight</span>
                    </div>
                    <div>
                      <span class="vitals-span align_texts">
                       {{ Number(getPatientLatestVitals.weight_kg)?.toFixed(2) 
                      }} kg</span>
                    </div>
                  </div>
                  <div class="vital-div-child">
                    <div>
                      <img
                        class="align_texts"
                        style="height: 25px"
                        src="@/assets/icons/blood_pressure.svg"
                        alt="blood_pressure"
                      />
                    </div>
                    <div>
                      <span class="vitals-span-title align_texts"
                        >Blood Pressure</span
                      >
                    </div>
                    <div>
                      <span class="vitals-span align_texts"
                        >{{ getPatientLatestVitals.bp }} mmHg</span
                      >
                    </div>
                  </div>
                  <div class="vital-div-child">
                    <div>
                      <img
                        class="align_texts"
                        style="height: 25px"
                        src="@/assets/icons/pulse.svg"
                        alt="pulse"
                      />
                    </div>
                    <div>
                      <span class="vitals-span-title align_texts">Pulse</span>
                    </div>
                    <div>
                      <span class="vitals-span align_texts"
                        >{{ getPatientLatestVitals.pulse }} BPM</span
                      >
                    </div>
                  </div>
                  <div class="vital-div-child">
                    <div>
                      <img
                        class="align_texts"
                        style="height: 25px"
                        src="@/assets/icons/temperature.svg"
                        alt="temperature"
                      />
                    </div>
                    <div>
                      <span class="vitals-span-title align_texts"
                        >Temperature</span
                      >
                    </div>
                    <div>
                      <span class="vitals-span align_texts"
                        >{{ Number(getPatientLatestVitals.temp_f) }}°F</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class=" float-end" v-if="getPatientLatestVitals">
              <span style="font-size: 10px;">Last updated at {{ new Date(getPatientLatestVitals?.created_at)?.toLocaleDateString() + " at "+  new Date(getPatientLatestVitals?.created_at)?.toLocaleTimeString() }}</span>
            </div>
        </div>
        <!-- </div> -->
      </div>
      <!-- <div class="col-2"></div> -->
    </div>
    <!-- <div class="row mt-5">
      <div class="col-2"></div>
      <div class="col-8">
        <h5>Other Vital Data</h5>
        <SpinnerDataLoader :classname="'text-primary'"  v-if="getLoaderStatus"></SpinnerDataLoader>
        <noRecordComp v-if="getPatientVitals.length < 1 && !getLoaderStatus" :title="'NO RECORD FOUND'"></noRecordComp>
        <div style="overflow-y: scroll; height: 50vh" class="mt-3" v-if="getPatientVitals.length > 0 && !getLoaderStatus">
          <table class="table table-striped">
              <thead>
                  <tr>
                      <th>S.N</th>
                      <th>Date</th>
                      <th>BP (mmHg)</th>
                      <th>Temperature(°F)</th>
                      <th>Pulse (bpm)</th>
                      <th>BMI (kg/m2)</th>
                      <th>SPO2 (%)</th>
                  </tr>
              </thead>
              <tbody>
                  <tr class="tableRow" v-for="(vital, index) in getPatientVitals" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>
                          {{ vital.app_date }}
                      </td>
                      <td>{{ vital.bp }}</td>
                      <td>{{ vital.temp }}</td>
                      <td>{{ vital.pulse }}</td>
                      <td>{{ vital.bmi }}</td>
                      <td>{{ vital.spo }}</td>
                  </tr>
              </tbody>
          </table>
      </div>
      </div>
    </div> -->
    <div class="row mt-5">
      <div class="col-1"></div>
      <!-- <div class="col-4 col-100-sm"></div> -->
      <div class="col-11" v-if="getPatientVitals.length > 0">
        <div style=" justify-content: center; padding: 0% 5%;" class="d-lg-bloc k d-block w-100 ">

          <vue-apex-charts height="400px" width="100%" type="line" :options="options" :series="series" ></vue-apex-charts>
          <vue-apex-charts width="100%"  height="400px" type="line" :options="optionsBP" :series="seriesBP" ></vue-apex-charts>

        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed, watch } from "vue";
import VueApexCharts from "vue3-apexcharts";
import {useManageUser} from "@/core/modules/auth/user"
import { storeToRefs } from "pinia";
import { useSelfReports } from "@/core/modules/reports/reports";
import noRecordComp from "@/components/norecord";
import SpinnerDataLoader from '@/components/loaders/spinnerloader'
const { getPatientVitals, getPatientLatestVitals, getLoaderStatus } = storeToRefs(useSelfReports());
const { getUser,getopdPatientRegId } = storeToRefs(useManageUser());
const userstore=useManageUser();

const store = useSelfReports();
const options = ref({
  
});

const series = ref([])
const optionsBP = ref({
  
});

const seriesBP = ref([])
onMounted(async() => {
  await userstore.getUserDetails()
  //  store.getPatientVitalsData(getUser.value?.id);
   await store.getPatientLatestVitalsData(getUser.value?.id,getopdPatientRegId.value??0);
  // console.log(spo_data.value)
  options.value = {
    chart: {
      id: 'bmi-chart',
      dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              }

    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth'
    },
    title: {
      text: 'BMI',
      align: 'left'
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      },
    },
    markers: {
      size: 1
    },

    xaxis: {
      categories: date_values.value,
      title: {
                text: 'Vitals Date'
              }

    },
    yaxis: {
      title: {
        text: 'BMI Value (Kg/m2)'
      }
    }
  }
  series.value = [{
    name:'BMI',
    data: bmi_data.value
  }]


  optionsBP.value = {
    chart: {
      id: 'bp-chart',
      dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              }
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth'
    },
    title: {
      text: 'BP',
      align: 'left'
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      },
    },
    markers: {
      size: 1
    },
    xaxis: {
      categories: date_values.value,
      title: {
        text: 'Vitals Date'
      }
    },
    yaxis: {
      title: {
        text: 'BP Value(mmHg)'
      }
    }
  }
  seriesBP.value = [{
    name:'Systolic',
    data: bp_data.value
  },
  {
    name:'Diastolic',
    data: bp_diastolic_data.value
  }]
});



const bp_data = computed(() => {
  return getPatientVitals.value.map((element) => {
    return element.bp.split('/')[0];
  })
})
const bp_diastolic_data = computed(() => {
  return getPatientVitals.value.map((element) => {
    return element.bp.split('/')[1];
  })
})
const bmi_data = computed(() => {
  return getPatientVitals.value.map((element) => {
    return Number(element.bmi).toFixed(2);
  })
})
const date_values = computed(() => {
  return getPatientVitals.value.map((element) => {
    return element.vital_date;
  })
})

// watch(() => getPatientVitals.value, () => {
//     return getPatientVitals.value.visit_date
// });
</script>

<style scoped>
.vital-container {
  margin-top: 20px;
}
.vital-container-title {
  font-weight: 700;
  font-size: var(--large---medium);
  text-align: center;
  padding: 5px;
  border: 1px solid var(--foundation----neutral---n400);
  border-radius: 5px 5px 0 0;
  background-color: var(--foundation----primary---p50);
}
.vital-icons-div {
  display: flex;
  justify-content: space-around;
  padding: 15px;
  border: 1px solid var(--foundation----neutral---n400);
  border-radius: 0 0 5px 5px;
  margin-bottom: 10px;
  border-top: 0;
}
.vitals-span-title {
  font-size: var(--small---regular);
  text-align: center;
  padding: 5px 0 0 0;
}
.vitals-span {
  text-align: center;
  font-size: var(--base---medium);
  font-weight: 700;
}
.align_texts {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

</style>
