import axios from "axios";
import { defineStore } from "pinia";
import { toast } from 'vue3-toastify';
import { useApiHeader } from "@/core/services/apiheader";
import { useJwtService } from "@/core/services/jwt";
export const useManageUser = defineStore("manageuser", {
  state: () => ({
    errors: null,
    user: {},
    isAuthorized: false,
    opdPatientRegId:'',
    paidUnpaidAmount:'',
    salutations:[],

  }),
  getters: {
    getErrors: (state) => {
      return state.errors;
    },
    getUser: (state) => {
      return state.user;
    },
    getIsAuthorized:(state) => state.isAuthorized,
    getopdPatientRegId:state=> state.opdPatientRegId,
    getPaidUnpaidAmount:state=> state.paidUnpaidAmount,
    getSalutations:state=> state.salutations
  },
  actions: {
    pageAuth(){
      
      this.isAuthorized =  false
      this.user =  {}
      const jwtService = useJwtService();
      jwtService.destroyToken();
    },
    setUser(user){
      this.user =  user;

    },
    setIsAuthorized(isAuthorized){
      this.isAuthorized =  isAuthorized
    },
    async fetchSalutations() {
      const apiHeader = useApiHeader();
      try {
        this.setErrors(null)
        apiHeader.setHeader();
        await axios
          .get("/getSalutations")
          .then((response) => {
            this.salutations=response.data.salutations

            })
          .catch((error) => {
            this.setErrors(error.response.data)
           
          });
      } catch (error) {
       
      }
    },
    async getUserDetails() {
      const apiHeader = useApiHeader();
      try {
        this.setErrors(null)
        this.loaderStatus = true;
        apiHeader.setHeader();
        await axios
          .get("/getUserInfo")
          .then((response) => {
            this.loaderStatus = false;
            if (response.status == 200) {
              this.opdPatientRegId=response.data.user_information.opdPatientRegId
              var user = response.data.user_information;
              this.user = user;
              this.isAuthorized = true;
            }
          })
          .catch((error) => {
            this.setErrors(error.response.data)
            this.loaderStatus = false;
            this.isAuthorized = false;
            // this.$router.push({ name : 'page.auth.login'})
          });
      } catch (error) {
        this.loaderStatus = false;
      }
    },
    async getDeopsitAndUnpaidAmount(){
      const apiHeader = useApiHeader();
      try {
        // this.paidUnpaidAmount=''
        this.setErrors(null)
        apiHeader.setHeader();
        await axios
          .get("/getDepositDueAmount")
          .then((response) => {

            
              this.paidUnpaidAmount=response.data.data
            
          })
          .catch((error) => {
            this.setErrors(error.response.data)
            this.paidUnpaidAmount=''

            // this.$router.push({ name : 'page.auth.login'})
          });
      } catch (error) {
        this.paidUnpaidAmount=''

      }
    },
    async updateEmailAddress(payload){
      const jwtService = useJwtService();
      try {
        
      await axios
          .post('/updatePatientData', payload)
          .then((response) => {
              this.loaderStatus =  false;
              toast.success(response.data.message)
              // this.$router.push({name:'page.landing'});
          })
          .catch(error=>{
            toast.error(error.response.data.message);
          })
        }
        catch(error){
          // console.log(error)
        }

    },
    setErrors(error) {
      this.errors = error;
      },
      setIsAuthorizedFalse(){
        this.isAuthorized = false;
      }
  },
});
