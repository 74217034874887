<template>
    <div class="container-fluid">
      <div class="row ctm-padding">
        <div class="col-1"></div>
        <div class="col-11">
          <h6>My Advices</h6>
        </div>
     

       
        <div class="row">
          <div class="col-1"></div>
     
                  <!-- <div class="col-2">
                <div v-for="(date, index) in getPatientAdviceDates" :key="index">
           
                    <sideItemComp v-on:onactive="store.getPatientLatestAdvicesData(date.visit_id)"  :isActive="!date.is_active"   :title1="date.visit_date"   :title3="date.doctor_name" :isShowComment="false" :labelOfTitle3="'Advice By : '"></sideItemComp>
                </div>
            </div> -->
            <div class="col-10">
          <SpinnerDataLoader :classname="'text-primary'"  v-if="getLoaderStatus &&!getPatientAdvice"></SpinnerDataLoader>
          <noRecordComp v-if="getPatientAdvice?.length == 0" :title="'NO RECORD FOUND'">
            </noRecordComp>
          <div  v-if="getPatientAdviceDates"  class="row p-3" >
      
            <div v-for="(pack,ind) in getPatientAdviceDates" :key="ind" class="col-md-12 col-xl-6 col-12 my-2" >
              <div  style="border: 1px solid black; border-radius: 16px; padding: 16px; background-color: #eeecf5; border: none; height: 230px; overflow: auto;" >
                  <div class="  pt-1">
                          <label class="acc_label ">{{ moment(new Date(pack.visit_date)).format('MMMM D, YYYY') }} </label>
                      </div>
                          <label class="acc_label ">Advice By: {{ pack.doctor_name }}</label>
                     <hr/>
                      
              
                  <div class=" ">
                    <div style="">Advice : </div>
                          <div class="acc_label " style='word-wrap:break-word;'>{{ pack.advice}} </div>
                      </div>
                      <div class="   pt-1" v-if="pack.refer_to">
                          <label class="acc_label ">Refer To :  {{ pack.refer_to }}</label>
                         
                      </div>
                    </div>
                  </div>
     
            </div>
          </div>
        <div class="col-1"></div>
      </div>
      </div>
    </div>

  </template>
  
  <script setup>
  import { onMounted } from "vue";
  import moment from "moment";
  import { storeToRefs } from "pinia";
  import { useSelfReports } from "@/core/modules/reports/reports";
  import sideItemComp from '@/components/reportscomponent';
  import { useManageUser } from "@/core/modules/auth/user";
  import SpinnerDataLoader from '@/components/loaders/spinnerloader'
  import noRecordComp from "@/components/norecord";
  const {getopdPatientRegId}=storeToRefs(useManageUser());
  const {  getLoaderStatus,getPatientAdvice,getPatientAdviceDates } = storeToRefs(useSelfReports());
  const store = useSelfReports();
  const userprofile=useManageUser();
  onMounted(async() => {
    userprofile.getUserDetails();
    await store.getPatientLatestAdvicesData();
    console
    if(getPatientAdviceDates.value && getPatientAdviceDates.value[0].visit_id){
      
    await store.getPatientLatestAdvicesData(getPatientAdviceDates.value[0].visit_id)
    }

  });
  </script>
  
  <style scoped>
  .vital-container {
    margin-top: 20px;
  }
  .vital-container-title {
    font-weight: 700;
    font-size: var(--large---medium);
    text-align: center;
    padding: 5px;
    border: 1px solid var(--foundation----neutral---n400);
    border-radius: 5px 5px 0 0;
    background-color: var(--foundation----primary---p50);
  }
  .vital-icons-div {
    display: flex;
    justify-content: space-around;
    padding: 15px;
    border: 1px solid var(--foundation----neutral---n400);
    border-radius: 0 0 5px 5px;
    margin-bottom: 10px;
    border-top: 0;
  }
  .vitals-span-title {
    font-size: var(--small---regular);
    text-align: center;
    padding: 5px 0 0 0;
  }
  .vitals-span {
    text-align: center;
    font-size: var(--base---medium);
    font-weight: 700;
  }
  .align_texts {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  </style>
  