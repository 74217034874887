<template>
    <div class="conatiner"  v-if="getIsPaymentSuccess">
        <div class="row">
            <div class="col-lg-12 col-12 d-none"  style="margin-left: 6%;">
            <div>
        <!-- <h4 class=" pt-3 text-success "  >Payment successfully completed
        </h6> -->
        <div class="" >
            <a class="btn  btn-primary " style=" background-color:  var(--foundation----primary---p200); border:none " @click="handleGoHomeHandler" >Home</a>
        </div>
    </div>
        </div>
        <div class="col-lg-12 col-12">
            <div class="p-3 d-flex justify-content-center">
            <iframe :src="pdfsrc" style="width: 90%; height: 90vh; border: none;" class="responsive">
                Oops! an error has occurred.
            </iframe>
        </div>
        </div>

 
    </div>
    </div>
</template>

<script setup>
import { onMounted, watch, computed, ref } from 'vue'
import { storeToRefs } from "pinia";
import router from "@/router/index";


import { useRoute } from 'vue-router';
import { usePayment } from '@/core/modules/payment/payment';


const pdfsrc = ref("")

const route = useRoute();
const { getIsPaymentSuccess, paymentResponsePdf } = storeToRefs(usePayment())



function openFile(f) {
    return `https://uatapi.meraki.dolphin.com.np${f}`
    // return `http://10.2.3.214:8001${f}`
}
if(paymentResponsePdf.value){
    
        pdfsrc.value = openFile(paymentResponsePdf.value);
    }
    else{
        router.push({name: "page.landing", });
    }

    const handleGoHomeHandler = () => {
 
        router.push({name: "page.landing", });
}

</script>
