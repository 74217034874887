<template>
    <modalComp :idmodal="'confirmdelete'" :show-header="true" :show-footer="true" :size="'modal-md'">
        <template v-slot:header>
            <h5 class="modal-title">Confirm Delete </h5>
            <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
        </template>
        <template v-slot:body>
            <div>
                <div class="mb-1 mx-2">
                    <p>
                        Are you sure you want to delete this item from the cart?
                    </p>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div class="p-2">
                <button class="btn-meraki-secondary me-2" data-bs-dismiss="modal" >Cancel</button>
                <button class="btn-meraki-primary me-2 px-5" data-bs-dismiss="modal"  @click="removeItem">Yes</button>
            </div>
        </template>

    </modalComp>
</template>

<script setup>
import { onMounted } from "vue";
import modalComp from '@/components/common/modal'
import { useSelfCart } from '@/core/modules/self/mycart'
const store = useSelfCart();
const emit=defineEmits(["completeapicall"])
const props = defineProps({
    cart: Array,
    total: Number,
    selectDeleted:Array,
})

function removeItem(){
 if (props.selectDeleted.length > 0) { 
     try {
       for (const element of props.selectDeleted) {
         const tempPayload = {
           cart_id:element,
         }
        store.removeCartItems(tempPayload);
         }
         emit("completeapicall",true)
         store.getCartDetails();
         store.getCartQuantity();
          }
      catch (error) {
       console.error("Error:", error) // Handle any unexpected errors
     }
   }
}
</script>