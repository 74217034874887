<template>
    <div class="container-fluid">
      <div class="row ctm-padding">
        <div class="col-1">
              </div>
            <div class="col-11">
          <h6>My Prescriptions</h6>
        </div>
        <noRecordComp v-if="!getPatientPrescriptions && !getLoaderStatus" :title="'NO RECORD FOUND'">
            </noRecordComp>
        <div class="row">
        
      
          <SpinnerDataLoader :classname="'text-primary'"  v-if="getLoaderStatus &&!getPatientPrescriptions"></SpinnerDataLoader>

          <div  v-if="getPatientPrescriptions"  class="row p-3" >
            <div class="col-1"></div>
            <div class="col-2">
                <div v-for="(inv, index) in getPatientPrescriptions" :key="index">
                    <!-- <div class="report-card">
                        <div class="py-2">{{ reports.bill_no }}</div>
                        <div class="pb-2">{{ reports.package }}</div>
                        <div class="pb-2">{{ reports.created_at }}</div>
                    </div> -->
                    {{  }}
                    <sideItemComp v-on:onactive="callMedication(inv.visit_id)" :isActive="inv.visit_id!=getCurrentSelectedVisitId"   :title1="moment(new Date(inv.visit_date)).format('MMMM D, YYYY')"  :title2="inv.visit_id"></sideItemComp>
                </div>
            </div>
            <div class="col-1"></div>
            <div class="col-8">
                <h6>Medication Data</h6>
                <div class="formTable" style="overflow: auto
                ;">
                  <vue-good-table :columns="columns" :rows="getPatientMedications"  :fixed-header="true"  :line-numbers="false"   :sort-options="{
    enabled: false,
  
  }"   class=" mt-3"  styleClass="vgt-table striped " >  
      <!-- <template  #table-row="props">
    
     
          <span v-if="props.column.field=='sn'"> {{props.formattedRow[props.column.field]}}</span>
          <span v-if="props.column.field=='path'"> <a >
                          {{getPathName(props.formattedRow[props.column.field])}}
                       </a></span>
          <span  v-if="props.column.field == 'remarks'">
            {{props.formattedRow[props.column.field]}}
          </span>
      
          <span v-if="props.column.field == 'action'">
            <font-awesome-icon data-bs-target="#confirmdeletereport" data-bs-toggle="modal" @click="removePatientReport(props.formattedRow[props.column.field].id)" class="nav-icon remove-icon c-pointer me-3" icon="fa-solid fa-trash" />
                          <a :href="openFile(props.formattedRow[props.column.field].path)" target="_blank">
                            <font-awesome-icon class="nav-icon remove-icon c-pointer" icon="fa-solid fa-eye" />
                          </a>
          </span>
          <span v-else> </span>
        </template> -->
        </vue-good-table>
                </div>
            </div>
      
  
     
            </div>
      </div>

      </div>
    </div>
  </template>
  
  <script setup>
  import { onMounted,ref } from "vue";
  import { storeToRefs } from "pinia";
  import moment from "moment";
  import { useSelfReports } from "@/core/modules/reports/reports";
  import { useManageUser } from "@/core/modules/auth/user";
  import SpinnerDataLoader from '@/components/loaders/spinnerloader'
import sideItemComp from '@/components/reportscomponent'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { VueGoodTable } from 'vue-good-table-next';


  import noRecordComp from "@/components/norecord";
  const {getopdPatientRegId}=storeToRefs(useManageUser());
  const {  getLoaderStatus,getPatientPrescriptions,getPatientMedications,getCurrentSelectedVisitId } = storeToRefs(useSelfReports());
  const store = useSelfReports();
  const userprofile=useManageUser();
  onMounted(async() => {
      await store.setCurrentSelectedMedicationid(0)
    await store.getPatientLatestPrescriptionsData();
  });
  async function callMedication(medicationid){
    await store.setCurrentSelectedMedicationid(medicationid)

  await store.getPatientLatestPrescriptionsData(medicationid);
}
const columns = ref([

  {
    label: 'Drug Name',
    field: 'drug_name',
    type: 'text',
  },
  {
    label: 'Dose',
    field: 'dose',
    type: 'text',
 
  },
  {
    label: 'Duration',
    field: 'duration',
    type: 'text',
 
  },
  {
    label: 'Remarks',
    field: 'remarks',
    type: 'text',
 
  },
])
  </script>
  
<style  >

td> span{
color: black  !important;
}
th>span{
  color: black  !important;
}

</style>
  