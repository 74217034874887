import { defineStore } from "pinia";
// import { useRoute } from "vue-router";
export const useManageAccount = defineStore('manageaccount', {
    state: () => ({
        account_sidenav_list : [{
                id: 1,
                name: 'Personal Information',
                icon: 'fa-solid fa-user',
                route_name: 'page.manage.myaccount'
            },
            {
                id: 2,
                name: 'Address Information',
                icon: 'fa-solid fa-arrows-rotate',
                route_name: 'page.manage.updateaccount'
            },
            {
                id: 3,
                name: 'Change Password',
                icon: 'fa-solid fa-lock',
                route_name: 'page.manage.changepassword'
            }]
    }),
    getters: {
        getAccountSidenav: (state) => {
            return state.account_sidenav_list
        }
    },
    actions: {
        setSelectedMenu(id) {
            this.$router.push({ name: this.account_sidenav_list[id - 1].route_name})
        }
    }
})