<template>
  <div class="container-fluid">
    <div class="row mt-md-0 mt-3">
      <div class="row">
        <div class="col-12 col-xl-6 p-2">
          <h7 class="mt-4">Basic Details</h7>
          <div
            style="
              border-radius: 16px;
              padding: 16px;
              background-color: #eeecf5;
              min-height: 250px;
              overflow: auto;
            "
          >
            <div class="col-md-6 col-12 pt-1">
              <label class="acc_label">Name : </label>
              <div class="fw-semibold">{{ getUser.patient_name }}</div>
            </div>
            <div class="col-md-6 col-12 pt-1">
              <label class="acc_label">Gender :</label>
              <div class="fw-semibold">{{ getUser.gender }}</div>
            </div>
            <div class="col-md-6 col-12 pt-1">
              <label class="acc_label">Date of Birth :</label>
              <div class="fw-semibold">&nbsp;{{ getUser.dob_ad }}</div>
            </div>
            <div class="col-md-6 col-12 pt-1">
              <label class="acc_label">Patient ID :</label>
              <div class="fw-semibold">&nbsp;{{ getUser.id }}</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-6 p-2">
          <h7 class="mt-4">Contact Details</h7>
          <div
            style="
              border-radius: 16px;
              padding: 16px;
              background-color: #eeecf5;
              min-height: 250px;
              overflow: auto;
            "
          >
            <div class="col-md-6 col-12 pt-1">
              <label class="acc_label">Address : </label>
              <div class="fw-semibold">{{ getUser.location_address ?? "-" }}</div>
            </div>

            <div class="col-md-6 col-12 pt-1">
              <label class="acc_label">Contact Number : </label>
              <div class="fw-semibold">
                {{ getUser.contact_number ? getUser.contact_number : "-" }}
              </div>
            </div>
            <div class="col-md-6 col-12 pt-1">
              <label class="acc_label">Email Address : </label>
              <div style="position: relative">
                  <font-awesome-icon
                      @click="()=>isEdit=!isEdit"
                  :class="isEdit?'text-danger':''"
                    style="
                      position: absolute;
                      top: -18px;
                      right: 60px;
                      color: #413f3f;
                      font-size: 12px;
                    "
                    :icon="isEdit?'fa-solid color-danger fa-cancel':'fa-solid fa-edit'"
                  />
                  <font-awesome-icon 
                  v-if="isEdit"
                      @click="handleEdit"
                  :class="isEdit?'text-success':''"
                    style="
                      position: absolute;
                      top: -18px;
                      right: 40px;
                      color: #413f3f;
                      font-size: 12px;
                    "
                    icon="fa-solid fa-check"
                  />
                </div>
              <div class="fw-semibold" v-if="!isEdit">
                {{ getUser.email ? getUser.email : "-" }}
              </div>
              <div class="fw-semibold" v-if="isEdit">
                <input class="form-control me-1"  id= "email-address" :defaultValue="getUser.email" style="font-size: medium;">
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-6 p-2">
          <h7 class="mt-4">Additional Details</h7>
          <div
            style="
              border-radius: 16px;
              padding: 16px;
              background-color: #eeecf5;
              min-height: 250px;
              overflow: auto;
            "
          >
            <div class="col-md-6 col-12 pt-1">
              <label class="acc_label">Guardian Name : </label>
              <div class="fw-semibold">
                {{ getUser?.patientGuardianName ?? "-" }}
              </div>
            </div>
            <div class="col-md-6 col-12 pt-1">
              <label class="acc_label">Guardian Contact Number : </label>
              <div class="fw-semibold">
                {{ getUser?.patientGuardianContact ?? "-" }}
              </div>
            </div>
            <div class="col-md-6 col-12 pt-1">
              <label class="acc_label">Relation with Guardian : </label>
              <div class="fw-semibold">
                {{ getUser?.patientGuardianRelation ?? "-" }}
              </div>
            </div>
            <div class="col-md-6 col-12 pt-1">
              <label class="acc_label">Identity Number : </label>
              <div class="fw-semibold">
                {{ getUser?.patientGuardianIdentityNumber ?? "-" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from 'vue';
import { storeToRefs } from "pinia";
import { useManageUser } from "@/core/modules/auth/user";
const { getUser } = storeToRefs(useManageUser());
const isEdit=ref(false)
const store=useManageUser()

async function handleEdit(event){
  let email=document.getElementById('email-address').value
  // console.log(email)
  const payload={
    "email":email
  }
  await store.updateEmailAddress(payload)
  isEdit.value=false
}

</script>

<style >

</style>
