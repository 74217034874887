<template>
  <div
    :class="
      getRouteName == 'page.manage.selectaccount'
        ? 'col-md-4 offset-md-4 '
        : 'col-md-12'
    "
  >
    <div
      class="osahan-cart-item mb-3 rounded-lg overflow-hidden"
      :class="
        getRouteName == 'page.manage.selectaccount' ? 'shadow-lg bg-white' : ''
      "
    >
      <div class="osahan-cart-item-profile  rounded-lg bg-white p-3">
        <div class="d-flex flex-column">
          <div class="mb-3" v-if="getRouteName == 'page.manage.selectaccount'">
            <font-awesome-icon
              icon="fa-solid fa-user-group"
              class="mx-2 fo-text-color-primary-p300"
            />Select a user
          </div>
          <SpinnerDataLoader
            :classname="'text-primary'"
            v-if="getDataLoader"
          ></SpinnerDataLoader>
          <div
            v-if="!getDataLoader"
            style="max-height: calc(100vh - 250px); overflow: auto"
            class="p-2"
          >
            <CardProfileComponent
              v-for="(account, index) in getAccounts"
              :key="index"
              :patientid="account.patient_id"
              :patientname="account.patient_name"
              :regdate="account.reg_date"
              :avatar="'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6FI5wnFojJPN6gWk1lF7mIAdzd3D8dhQaHVRd1snFHw&s'"
              :token="account.token"
              :bg="true"
              class="card-porfile"
            >
            </CardProfileComponent>
          </div>
        </div>
      </div>
      <button
        class="btn  rounded-24 w-100 btn-lg"
        v-if="!getDataLoader"
        @click="Continue()"
        :disabled="getSelectToken == ''"
        style="background-color: var(--foundation----primary---p300); color: white;"
      >
        {{
          getRouteName == "page.manage.selectaccount" ? "Continue" : "Selected"
        }}
      </button>
    </div>
  </div>
</template>

<script setup>
import CardProfileComponent from "@/components/card/profile";
import SpinnerDataLoader from "@/components/loaders/spinnerloader";
// import { useComponetSideNavItem } from '@/core/modules/components/sidenav/item'
import { useCommonStore } from "@/core/modules/common";
import { useManageUser } from "@/core/modules/auth/user";
import { storeToRefs } from "pinia";
import { useJwtService } from "@/core/services/jwt";
import { useRoute } from "vue-router";
import { useSelectAccount } from "@/core/modules/manage/selectaccount";
import { onMounted } from "vue";
import router from "@/router/index";
const store = useSelectAccount(); // store
const route = useRoute();

const commonStore = useCommonStore();
// const componentSideNavItemStore = useComponetSideNavItem();
const manageUserStore = useManageUser();

const { getErrors, getAccounts, getDataLoader, getSelectToken } = storeToRefs(
  useSelectAccount()
);
const { getRouteName } = storeToRefs(useCommonStore());

const jwtService = useJwtService();

onMounted(() => {
  store.fetchAccounts();
  const idToken = localStorage.getItem("id_token");
  if (idToken) {
    jwtService.destroyToken(idToken);
  }
});

function Continue() {
  if (getSelectToken.value != "") {
    store.submitContinue(getSelectToken.value);
    manageUserStore.getUserDetails();
    router.push({ name: "page.landing" });
  }
}
</script>
<style scoped>
.card-porfile{
  border-radius: 10px;
}
</style>
