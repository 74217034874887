import { defineStore } from 'pinia'
export const useLanding = defineStore('landing', {
    state: () => ({
        services: [
            {
                service_head: 'Nursing Care',
                id: 1,
                img_source: 'assets/images/illustrations/meds.svg',
                is_service_multiple: false,
            },
            {
                service_head: 'Visit',
                id: 2,
                img_source: 'assets/images/illustrations/visit.svg',
                is_service_multiple: true,
            },
            {
                service_head: 'Lab Test',
                id: 3,
                img_source: 'assets/images/illustrations/lab.svg',
                is_service_multiple: true,
            },
            {
                service_head: 'Physiotherapy',
                id: 4,
                img_source: 'assets/images/illustrations/physiotherapy.svg',
                is_service_multiple: true,
            },
            {
                service_head: 'Teleconsultation',
                id: 5,
                img_source: 'assets/images/illustrations/tele.svg',
                is_service_multiple: true,
            },
            {
                service_head: 'Vaccination',
                id: 6,
                img_source: 'assets/images/illustrations/vaccination.svg',
                is_service_multiple: true,
            },
            {
                service_head: 'Escorting',
                id: 7,
                img_source: 'assets/images/illustrations/tele.svg',
                is_service_multiple: true,
            },
            {
                service_head: 'Medical Equipment',
                id: 8,
                img_source: 'assets/images/illustrations/meds1.svg',
                is_service_multiple: true,
            },
            {
                service_head: 'Medical Procedure',
                id: 9,
                img_source: 'assets/images/illustrations/lab.svg',
                is_service_multiple: true,
            },
            {
                service_head: 'Wellness Package',
                id: 10,
                img_source: 'assets/images/illustrations/doctor.svg',
                is_service_multiple: true,
            },
            {
                service_head: 'Medical & Consumables',
                id: 11,
                img_source: 'assets/images/illustrations/meds1.svg',
                is_service_multiple: true,
            }
        ],
        bookedServiesList: [],
    }),
    getters: {
        getServiceList : (state) => {
            return state.services
        },
        getBookedServicesList : (state) => {
            return state.bookedServiesList
        },
    },
    actions: {
        bookServices(body){
            this.bookedServiesList = body;
        }
    }
})