<template>
  <div class="container-fluid">
    <!-- new req carousel same as old website -->
    <carousel
      :items-to-show="getBanner?.length > 1 ? 1 : 1"
      v-if="getBanner?.length>0"
      height
      autoplay="8000"
      
      :wrapAround="true"
    >
      <slide v-for="slide in getBanner" :key="slide.id" class="mx-0">
        <div class="" style="max-height: 400px; width: 100%; min-height: 80px">
          <img
            class=""
            :src="`${baseUrlImage + slide.image}`"
            alt="deal"
            style="
              object-fit: contain;
              height: auto;
              width: 100%;
              border-radius: 12px;
            "
          />
        </div>
      </slide>

      <template #addons>
        <navigation>
          <template #next>
            <span
              style="
                color: var(--foundation----primary---p200);
                font-size: 3rem;
              "
            >
              >
            </span>
          </template>
          <template #prev>
            <span
              style="
                color: var(--foundation----primary---p200);
                font-size: 3rem;
              "
            >
              {{ "<" }}
            </span>
          </template>
        </navigation>
        <pagination />
      </template>
    </carousel>

    <div class="row ctm-padding services-container">
      <div class="col-6">
        <!-- <router-link
          :to="{ name: 'page.self.allServices' }"
          class="nav-link"
          href="#"
          > -->
          <h6>All Services</h6>
          <!-- </router-link> -->
      </div>
      <div class="col-6 p-0"></div>
      <div class="col-12 mt-3">
        <div class="row" v-if="getServices">
          <div
            class="col-sm-6 col-md-4 col-lg-3 col-12 card-landing-depart"
            v-for="(service, index) in filteredItem"
            :key="index"
          >
            <div class="mb-4">
              <div class="service-landing-card">
                <img
                  class="service-landing-img"
                  :src="
                    `${baseUrlImage + service.icon}` ??
                    require('@/assets/images/illustrations/meds.svg')
                  "
                  alt="src"
                  style="object-fit: contain"
                />
              </div>
              <div class="service-landing-detail">
                <p>{{ service.department }}</p>
                <button
                  class="outline-btn cart-btn"
                  @click="
                    addToCartBtn(
                      service.department,
                      service.is_multiple,
                      service.department_id,
                      service.icon
                    )
                  "
                >
                  Show More
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="!getServices">
          <div
            class="col-sm-6 col-md-4 col-lg-3 col-12"
            v-for="(service, index) in [1, 2, 3, 4, 5, 6, 7, 8]"
            :key="index"
            style="height: 270px"
          >
            <div class="shimmer">
              <div class="wrapper m-2">
                <div class="image-card animate"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <addtocart
      :serviceName="service_name"
      :isMultiple="is_multiple_flag"
      :serviceId="service_id"
      :getopdPatientRegId="getopdPatientRegId"
      :serviceIcon="service_icon"
    ></addtocart>
  </div>
</template>
<script setup>
import { ref, computed, onMounted, watch } from "vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { storeToRefs } from "pinia";
import { useItemsStore } from "@/core/modules/header";
// import { useLanding } from "@/core/modules/landing";
import { useServices } from "@/core/modules/self/services";
import { useSelfCart } from "@/core/modules/self/mycart";
import addtocart from "@/components/modal/addtocart";
import { useManageUser } from "@/core/modules/auth/user";
import { previousRoute } from "../main.js";
// const { getServiceList } = storeToRefs(useLanding());
const { getServices, getServicesDepartmentWiseData, getBanner } = storeToRefs(
  useServices()
);
const { getopdPatientRegId, getPaidUnpaidAmount,getIsAuthorized} = storeToRefs(
  useManageUser()
);
const store = useServices();
const storecart = useSelfCart();
const ManageStore = useManageUser();
const servicename = ref(null);
const serviceIcon = ref(null);
const serviceid = ref(null);
const is_multiple = ref(null);
const temporaryStore = ref([]);
const previousRoutePath = ref("");
import { useRouter } from "vue-router";
const { getSearchQuery } = storeToRefs(useItemsStore());
import { Modal } from "bootstrap";
import { toast } from "vue3-toastify";
import router from "@/router/index";


const baseUrlImage = "https://uatapi.meraki.dolphin.com.np";

const route = useRouter();

onMounted(async () => {
  await store.getServicesList();
  await store.getBannerData();
  await store.fetchTeleCons();
  if (
    previousRoute.fullPath == "/manage/select-account" ||
    previousRoute.fullPath == "/login"
  ) {
    let modal1 = Modal.getOrCreateInstance("#paymentInfoModal");
    if (getPaidUnpaidAmount.value.due_amount > 0) {
      modal1.show();
    }
  }
});

function addToCartBtn(name, flag, id, icon) {
if(getIsAuthorized.value){
  let modal1 = Modal.getOrCreateInstance("#addtocart");
  modal1.show();
  servicename.value = name;
  is_multiple.value = flag;
  serviceid.value = id;
  serviceIcon.value = icon;
  store.getServicesDepartmentWise(serviceid.value);
 
}
// redirect to login if not loggedin
else{
  toast.error("Please login before procedding")
  router.push({name:'page.auth.login'});
}
  // service_image.value = imagesource;
}
const service_name = computed(() => {
  return servicename.value;
});
const service_icon = computed(() => {
  return serviceIcon.value;
});
const is_multiple_flag = computed(() => {
  return is_multiple.value;
});
const service_id = computed(() => {
  return serviceid.value;
});
// const serviceImage = computed(() => {
//     return service_image.value;
// })
const filteredItem = computed(() => {
  return getServices.value.filter((element) => {
    return (
      element.department
        .toLowerCase()
        .includes(getSearchQuery.value.toLowerCase()) ||
      element.items.toLowerCase().includes(getSearchQuery.value.toLowerCase())
    );
  });
});
</script>

<style scoped></style>
