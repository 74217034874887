<template>
    <div class="container-fluid">
        <div class="row ctm-padding">
            <div class="col-12">
                <h6>Checkout</h6>
            </div>
            
            <div class="  col-sm-12 col-md-10  col-12 mt-1">
                <div class="checkout-container px-3 py-3">
                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-12">
                            <p style="font-weight: 500">Service Order Summary</p>
                            <div v-if="billData" class="bill-summary">
                                <div>Bill For : {{ billData.fname }}</div>
                                <div>Bill No. : {{ billData.bill_no }}</div>
                                <div>Bill Created At : {{ billData.created_at }}</div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-12">
                            <p style="font-weight: 500">Bill Summary</p>
                            <table class="table" style="font-size: 0.75rem;">
                                <thead>
                                    <tr>
                                        <th>S.N</th>
                                        <th>Item Name</th>
                                        <th>Service Provider</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(bill, index) in getOrderDetails" :key =index>
                                        <td>{{ index + 1}}</td>
                                        <td v-if="bill.is_package == 0">{{bill.item_name}}</td>
                                        <td v-else>
                                            <table>
                                                <tr v-for="(pack,ind) in bill.package" :key="ind">
                                                    <td> {{ pack.name }} </td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td v-if="bill.is_package == 0">{{ bill.provider[0]?.provider_name}}</td>
                                        <td v-else>
                                            <table>
                                                <tr v-for="(pack,ind) in bill.package" :key="ind">
                                                    <td v-for="(provider, i ) in pack.provider" :key="i">
                                                        {{ provider.provider_name }}
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td>NPR. {{ bill.price.amount?.toLocaleString()}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-2 col-12 mt-1">
                <div class="checkout-container px-2 py-2">
                    <span class="px-1"> Total Amount</span>
                    <p style="font-weight: 500" class="px-1">NPR. {{ totalPayableAmount?.toLocaleString() }}</p>
                </div>
            </div>
        </div>
        <div class="row ctm-padding payment_buttons">
                <p>Please choose your payment method</p>
                    <div v-for="(i, index) in payment_data" class="col-2 col-3-md payment-mode" :key="index" style="height: 50px width:100px;">
                        <img :class="i.payment_method"  data-bs-target="#paymentsure" data-bs-toggle="modal" data-bs-config={backdrop:true} @click="choosePayment1(i.payment_id)" class="payment-img" :src='getImg(i.payment_method_image)' alt="payment" style="height: 50px, width:auto;;">
                    </div>
        </div>
        <paymentsure :totalPayableAmount = totalPayableAmount :selectedPaymentId=selectedPaymentId   @proceedPaymentSure="choosePayment"></paymentsure>
    </div>
</template>
<script setup>
import { ref, onMounted, computed } from 'vue'
import moment from 'moment';
import { storeToRefs } from "pinia";
import { useSelfCart } from '@/core/modules/self/mycart';
import { useSelfOrder } from '@/core/modules/self/order'
import { usePayment } from '@/core/modules/payment/payment'
import { useManageUser } from "@/core/modules/auth/user";
import paymentsure from '@/components/modal/paymentsure';
import { useRoute } from 'vue-router';
const store = usePayment();
const storeOrder = useSelfOrder();
const { getMyOrders, getOrderDetails } = storeToRefs(useSelfOrder());
const { getUser } = storeToRefs(useManageUser());
const { getCheckoutCart } = storeToRefs(useSelfCart());
const billData = ref(null)
const totalPayableAmount=ref(0)
const selectedPaymentId=ref("")
const selectedPatmentMethod=ref("")
const route = useRoute();
moment().format()
const payment_data = ref([
    {
        payment_id: 1,
        payment_method: 'eSewa',
        payment_method_image: 'assets/images/eSewa-logo.png',
        min_image: 'assets/images/esewa_min.png'
        
    },
    {
        payment_id: 2,
        payment_method: 'Khalti',
        payment_method_image: 'assets/images/khalti-logo.svg',
        min_image: 'assets/images/khalti_min.png'
    }
])
 onMounted(async () => {
    await storeOrder.getOrder('');
    await storeOrder.viewCheckoutOrder(route.params.id);
    billData.value = getMyOrders.value.find((order) => order.billId == route.params.id);

    if(getOrderDetails.value?.length>0){
    
    totalPayableAmount.value=computed(()=>{
        let tempPrice=0
        getOrderDetails.value.forEach((element)=>{
            tempPrice+=element.price?.amount
        })
        return tempPrice
})
}
})

function getImg(source_image){
    return require('@/'+source_image);
}
function choosePayment1(id){ 
    selectedPaymentId.value=id 




return null}

function choosePayment(id){
   
    if(id == 2){

        
    const khaltiConfig = {
        transaction_id: billData.value.bill_no,
        customer_name: getUser.value.patient_name,
        customer_email: getUser.value.email,
        customer_dob: getUser.value.dob_ad,
        amount: totalPayableAmount.value.value,
        customer_phone: getUser.value.phoneNo,
        patient_id:getUser.value.id,
        sMasterBillId: billData.value.billId,
        total_amount:totalPayableAmount.value.value
    }
    store.savePayment(khaltiConfig)}
}
</script>

<style scoped>


</style>