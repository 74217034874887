<template>
    <div class="container-fluid">
        <div class="row ctm-padding">
           <div class="col-12">
            <h6>My Purchase History</h6>
            <div class="d-flex">
                <tablist :class="{ activeTab: index == selectedTab }" 
                        @click="getServiceCategoryData(index,tabs)"
                        class="dateTabList c-pointer" v-for="(tabs, index) in service_data_categories" :key="index" :date="tabs">
                </tablist>
            </div>
            <div class="d-flex justify-content-end">
              <input  v-model="searchFieldInput" type="text" class="main_search"  placeholder="Search by bill number">
            </div>
 <vue-good-table :columns="columns" :rows="rows"  :fixed-header="true"  :line-numbers="false"     :sort-options="{
    enabled: false,
  }"  :pagination-options="{
    enabled: true,
    mode: 'records',
    perPageDropdown: [10, 20, 50],
    dropdownAllowAll: false,
  }"  
    :search-options="{
    enabled: false,
    externalQuery: searchFieldInput
  }"  class="mt-3"  styleClass="vgt-table striped">  
      <template  #table-row="props">
          <span v-if="props.column.field=='sn'"> {{props.formattedRow[props.column.field]}}</span>
          <span v-if="props.column.field=='bill_no'"> {{props.formattedRow[props.column.field]}}</span>
          <span  v-if="props.column.field == 'created_at'">
            {{new Date(props.formattedRow[props.column.field])?.toLocaleDateString() +' at ' + new Date(props.formattedRow[props.column.field])?.toLocaleTimeString() }}
          </span>
          
          <span v-if="props.column.field == 'action'" style="font-size: 1.25rem;">
            <!-- <font-awesome-icon class="c-pointer" @click="viewOrderDetail(props.formattedRow[props.column.field])" icon="fa-solid fa-eye" /> -->
            <font-awesome-icon  class="c-pointer mx-2" @click="viewPdfOfPurchase(props.formattedRow[props.column.field])" icon="fa-solid fa-file" />
          </span>
          <span v-else> </span>
        </template>
        </vue-good-table>
            <div></div>
           </div>
        </div>
    </div>
    <pdfView></pdfView>
    <purchaseDetail></purchaseDetail>
</template>

<script setup>
import tablist from "@/components/tablist.vue"
import { ref, onMounted, onUpdated,computed } from 'vue'
import { storeToRefs } from "pinia"
import { useSelfPurchaseHistory } from '@/core/modules/self/mypurchase'
import router from '@/router/index'
import purchaseDetail from '@/components/modal/purchaseDetail.vue'
import pdfView from '@/components/modal/pdfView.vue'
import { usePurchaseHistory} from '../../core/modules/purchase/purchaseHistory'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { VueGoodTable } from 'vue-good-table-next';
import {useManageUser} from "@/core/modules/auth/user";
import { Modal } from "bootstrap";

const { getMyPurchaseHistory, getFinalBill } = storeToRefs(useSelfPurchaseHistory())
const { getopdPatientRegId } = storeToRefs(useManageUser())
const { getRefundHistory } = storeToRefs(usePurchaseHistory())

const store = useSelfPurchaseHistory();
const service_data_categories = ref(['Active', 'History'])
const selectedTab = ref('');
const searchFieldInput=ref('');
const refundStore = usePurchaseHistory()


const columns = ref([
  {
    label: 'SN',
    field: 'sn',
    type: 'text',
  },
  {
    label: 'Bill No.',
    field: 'bill_no',
    type: 'text',
  },
  {
    label: 'Billing Date',
    field: 'created_at',
    type: 'text',
 
  },

  {
    label: 'Action',
    field: 'action',
    type: 'text',
 
  },

])
const rows = computed(() => {
  if (selectedTab.value === 1) {
    return getFinalBill?.value?.map((bill, index) => ({
      ...bill,
      sn: index + 1,
      action: bill,
      bill,
    }));
  } else {
    return getMyPurchaseHistory?.value?.map((bill, index) => ({
      ...bill,
      sn: index + 1,
      action: bill,
      bill,
    }));
  }
});


onMounted(async () => {
    await store.getPurchaseHistory(0);  
    await store.getFinalBillData(0)
})


function getServiceCategoryData(index,tabs) {
    selectedTab.value = index;
    store.getFinalBillData(index)
    store.getPurchaseHistory(index)
}



function viewOrderDetail(bill){

    store.viewwPurchaseDetail(bill);
}
async function viewPdfOfPurchase(bill) {
  if(selectedTab.value == 0){
await refundStore.depositRefund(bill.id, bill.type) 
   let modal1 = Modal.getOrCreateInstance("#pdfView");
                modal1.show(); 
  }
  if(selectedTab.value == 1){
    await refundStore.billPrint(bill.bill_no, bill.opd_registration_id) 
   let modal1 = Modal.getOrCreateInstance("#pdfView");
                modal1.show(); 
  }  
}
</script>

<style scoped>
.pending_btn {
    background-color: var(--foundation----secondary---s100) !important;
}

.completed_btn {
    background-color: var(--foundation----secondary---s300) !important;
}
</style>