<template >
    <div class="border-bottom">
    <a style="cursor: pointer; text-decoration: none;" @click="onClick()" :class="isActive ? 'active_report' : ''"
        class="cursor-pointer d-flex align-items-center  px-3 py-3">
        <div class="left me-3">
            
            <h6 v-if="title3" style="font-size: 12px;" class="m-0"><span v-if="labelOfTitle3">{{ labelOfTitle3 }}</span>{{ title3 }}</h6>
            <h6 v-if="title1" style="font-size: 14px;" class="mb-1"> {{ title1 }} </h6>
            <h6  v-if="title2" style=" font-size: 12px;" class="mb-1 text-sm"> {{ title2 }}</h6>
        </div>
        <div class="right ms-auto">
            <span class="fw-bold m-0"><font-awesome-icon v-if="!isActive" icon="fa-solid fa-arrow-right"
                class="h6 m-0" /></span>
            </div>
        </a>
        <a class="text-success mx-3"  data-bs-target="#docComment" data-bs-toggle="modal" data-bs-config={backdrop:true} style="cursor: pointer;" v-if="!isActive && isShowComment" @click="viewDoctorsCommnetHandler()" >                    
           
 Doctor's Comment 
 <!-- <font-awesome-icon    icon="mx-1 fa fa-solid fa-comment"/> -->
</a>   
    </div>
</template>

<script setup>
const props = defineProps({
    title1: String,
    title2: String,
    title3: String,
    labelOfTitle3: String,
    isActive: Boolean,
    isShowComment:String,
})
const emit = defineEmits(['onactive','viewDocReport'])
function onClick() {
    emit("onactive");
}
function viewDoctorsCommnetHandler() {
    emit("viewDocReport");
}
</script>

<style scoped>
.active_report {
    color: black;
}
</style>