<template>
  <div class="container-fluid">
    <div class="row ctm-padding">
      <!-- <div class="col-6 col-100-sm">
        <h6>Upload Files</h6>
        <p>Add your documents here.</p>
        <div class="dropbox">
          <input class="input-file mb-3" @change="handleFileChange" multiple type="file">
          <p>
            <font-awesome-icon style="font-size: 2rem; color: var(--foundation----primary---p300) !important"
              icon="fa-solid fa-upload" />
            <br>
            Drag Files here or <span style="color: var(--foundation----primary---p300) !important">Browse</span>
          </p>
        </div>
        <br>
        <span>Uploaded Files</span>
        <div v-for="(files,index) in selectedFiles" :key="index">{{files.name}}</div>
        <br>
        <label style="font-weight: 500; font-size: 0.75rem" class="form-label" for="floatingTextarea">Remarks</label>
        <textarea class="form-control mb-3" v-model="file_remarks" placeholder="Leave Remarks here"
          id="floatingTextarea"></textarea>
        <button :disabled="getLoaderStatus" class="btn-meraki-primary" @click="submitFile">Upload <span
            v-if="getLoaderStatus" class="spinner-border spinner-border-sm" role="status"
            aria-hidden="true"></span></button>
      </div> -->
      <div class="col-12 col-100-sm">
        <select @change="selectDoc" v-model="documentType" style="text-transform: capitalize; border-right: 10px solid transparent" class="acc_input width-100">
          <option value="0" disabled>Select Document Type</option>
          <option  v-for="(doc,index) in getDocsType" :key="index" :value="doc"  style="text-transform: capitalize;">
            {{ doc.name}}
          </option>
        </select>
      </div>
      <div class="col-6 col-100-sm">
        <!-- <div class="formTable"> -->
         
          <h6 class="mt-5">Prescriptions Docs</h6>
          <vue-good-table :columns="columns" :rows="prescriptionsrows" :fixed-header="true" :line-numbers="false" :sort-options="{ enabled: false,}" class=" mt-3" styleClass="vgt-table striped ">
            <template #table-row="props" >
              <span v-if="props.column.field=='sn'"> {{props.formattedRow[props.column.field]}}</span>
              <span v-if="props.column.field=='path'"> <a>
                  {{getPathName(props.formattedRow[props.column.field])}}
                </a></span>
              <span v-if="props.column.field == 'remarks'">
                {{props.formattedRow[props.column.field]}}
              </span>

              <span v-if="props.column.field == 'action'">
                <font-awesome-icon data-bs-target="#confirmdeletereport" data-bs-toggle="modal"
                  @click="removePatientReport(props.formattedRow[props.column.field].id)"
                  class="nav-icon remove-icon c-pointer me-3" icon="fa-solid fa-trash" />
                <a :href="openFile(props.formattedRow[props.column.field].path)" target="_blank">
                  <font-awesome-icon class="nav-icon remove-icon c-pointer" icon="fa-solid fa-eye" />
                </a>
              </span>
              <span v-else> </span>
            </template>
          </vue-good-table>
          <h6 class="mt-5">Lab Reports Docs</h6>
          <vue-good-table :columns="columns" :rows="labrows" :fixed-header="true" :line-numbers="false" :sort-options="{ enabled: false,}" class=" mt-3" styleClass="vgt-table striped ">
            <template #table-row="props" >
              <span v-if="props.column.field=='sn'"> {{props.formattedRow[props.column.field]}}</span>
              <span v-if="props.column.field=='path'"> <a>
                  {{getPathName(props.formattedRow[props.column.field])}}
                </a></span>
              <span v-if="props.column.field == 'remarks'">
                {{props.formattedRow[props.column.field]}}
              </span>

              <span v-if="props.column.field == 'action'">
                <font-awesome-icon data-bs-target="#confirmdeletereport" data-bs-toggle="modal"
                  @click="removePatientReport(props.formattedRow[props.column.field].id)"
                  class="nav-icon remove-icon c-pointer me-3" icon="fa-solid fa-trash" />
                <a :href="openFile(props.formattedRow[props.column.field].path)" target="_blank">
                  <font-awesome-icon class="nav-icon remove-icon c-pointer" icon="fa-solid fa-eye" />
                </a>
              </span>
              <span v-else> </span>
            </template>
          </vue-good-table>
          <h6 class="mt-5">Others Docs</h6>
          <vue-good-table :columns="columns" :rows="otherrows" :fixed-header="true" :line-numbers="false" :sort-options="{ enabled: false,}" class=" mt-3" styleClass="vgt-table striped ">
            <template #table-row="props" >
              <span v-if="props.column.field=='sn'"> {{props.formattedRow[props.column.field]}}</span>
              <span v-if="props.column.field=='path'"> <a>
                  {{getPathName(props.formattedRow[props.column.field])}}
                </a></span>
              <span v-if="props.column.field == 'remarks'">
                {{props.formattedRow[props.column.field]}}
              </span>

              <span v-if="props.column.field == 'action'">
                <font-awesome-icon data-bs-target="#confirmdeletereport" data-bs-toggle="modal"
                  @click="removePatientReport(props.formattedRow[props.column.field].id)"
                  class="nav-icon remove-icon c-pointer me-3" icon="fa-solid fa-trash" />
                <a :href="openFile(props.formattedRow[props.column.field].path)" target="_blank">
                  <font-awesome-icon class="nav-icon remove-icon c-pointer" icon="fa-solid fa-eye" />
                </a>
              </span>
              <span v-else> </span>
            </template>
          </vue-good-table>
        <!-- </div> -->
        <!-- <div class="formTable"> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
  <confirmdelete :deletedItem=deleted_item></confirmdelete>
  <uploaddoc :document = document_type></uploaddoc>
</template>

<script setup>
  import { ref, onMounted, computed } from 'vue';
  import { storeToRefs } from 'pinia';
  import { useSelfForms } from '@/core/modules/self/forms'
  import confirmdelete from "@/components/modal/confirmdeletereport"
  import SpinnerDataLoader from '@/components/loaders/spinnerloader'
  import 'vue-good-table-next/dist/vue-good-table-next.css'
  import { VueGoodTable } from 'vue-good-table-next';
  import uploaddoc from '@/components/modal/uploaddoc'
import { Modal } from 'bootstrap'


  const { getMyFiles, getLoaderStatus, getDocsType, getPresciptionDocs, getLabReportDocs, getOtherDocs } = storeToRefs(useSelfForms());
  const store = useSelfForms();
  const file_remarks = ref('');
  const files = ref([]);
  const file_upload = ref('');
  const selectedFiles = ref([]);
  const deletedId = ref('');
  const documentType = ref(0)

  const columns = ref([
    {
      label: 'SN',
      field: 'sn',
      type: 'text',
    },
    {
      label: 'File Name',
      field: 'path',
      type: 'text',
    },
    {
      label: 'Remarks',
      field: 'remarks',
      type: 'text',

    },
    {
      label: 'Action',
      field: 'action',
      type: 'text',

    },
  ])

  
  onMounted(async () => {
    await store.getPatientReport();
    await store.getDocumentsType();

  })
  const prescriptionsrows = computed(() => {
    return getPresciptionDocs.value.map((file, index) => ({
      sn: index + 1,
      path: file.path,
      remarks: file.remarks,
      
      action: file,
      group_id: file.group_id,
      file,
    }));
  });

  const labrows = computed(() => {
    return getLabReportDocs.value.map((file, index) => ({
      sn: index + 1,
      path: file.path,
      remarks: file.remarks,

      action: file,
      group_id: file.group_id,
      file,
    }));
  });
  const otherrows = computed(() => {
    return getOtherDocs.value.map((file, index) => ({
      sn: index + 1,
      path: file.path,
      remarks: file.remarks,
      
      action: file,
      group_id: file.group_id,
      file,
    }));
  });
  function handleFileChange(e) {
    selectedFiles.value = Array.from(e.target.files);
    file_upload.value = selectedFiles.value
  }

  function submitFile() {
    const base64Data = [];
    const uploadPromises = selectedFiles.value.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (e) => {
          const fileData = e.target.result
          base64Data.push(fileData);
          resolve();
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsDataURL(file);
      });
    });

    Promise.all(uploadPromises)
      .then(async () => {
        const requestData = {
          'upload_files': base64Data,
          'remarks': file_remarks.value
        };
        await store.savePatientReport(requestData);
        selectedFiles.value = []
        file_remarks.value = ""
      })
      .catch((error) => {
      });


  }

  function openFile(f) {
    return `https://uatapi.meraki.dolphin.com.np${f}`
    // return `http://10.2.3.214:8001${f}`
  }

  function getPathName(f) {
    // return f;
    if (f.length > 35) {
      return f.substring(35);
    }
  }

  function removePatientReport(id) {
    var idx = {
      'patientReportId': id
    }
    deletedId.value = idx;
  }

  function selectDoc() {
    let  modal1 = Modal.getOrCreateInstance('#uploaddocument');
    modal1.show();
  }
  const deleted_item = computed(() => {
    return deletedId.value;
  })
  const document_type = computed(() => {
    return documentType.value;
  })

</script>

<style scoped>
</style>