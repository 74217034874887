import axios from "axios";
import { defineStore } from "pinia";
import { Modal } from 'bootstrap'
import { toast } from "vue3-toastify";
import { useManageUser } from '@/core/modules/auth/user'
import { storeToRefs } from "pinia";
import moment from "moment";
export const useSelfCart = defineStore('selfCart', {
    state: () => ({
        myCartLength: '',
        checkoutCart: [],
        cartDetails : [],
        tempCartDetails:[],
        tempCartLength:'',
        errors : [],
        errorsAddCart:[],
        loaderStatus:false
    }),
    getters: {
        getMyCartLength: (state) => {
            return state.myCartLength
        },
        getCheckoutCart: (state) => {
            return state.checkoutCart;
        },
        getCartDetailsData: (state) => {
            return state.cartDetails;
        },
        getErrors: (state) => {
            return state.errorsAddCart
        },
        getLoaderStatus: (state) => {
            return state.loaderStatus
        },
        getTempCartLength:state=>state.tempCartLength,
        getTempCartDetail:state=>state.tempCartDetails


    },
 
    actions: {
        getCheckoutCartData(body){
           this.checkoutCart = body;
        },
        async storeServicesToCart(body) {
            const { getIsAuthorized } = storeToRefs(useManageUser());
            try {
                if(getIsAuthorized.value === false){
                  let tempArrayOfSelectedArray=body.items.map((element)=>{if(element.isChecked===1){return {...element,isChecked1:true}}})?.filter(notUndefined => notUndefined !== undefined)

                
                 let allSelectedTempCart=[...this.tempCartDetails]
                //  handle if the selcted value is already in the array or not
                tempArrayOfSelectedArray.forEach(element => {
                    // console.log(element, allSelectedTempCart[0])
                    if( allSelectedTempCart.findIndex(element1=>element1.id==element.id)<0){
                        allSelectedTempCart.push(element)
                    
                    }
                    else{
                        toast.error(`${element.name} already in cart` )
                    }
                });
                  localStorage.setItem('tempCartDetails',JSON.stringify(allSelectedTempCart))
                  this.tempCartDetails=[...allSelectedTempCart]
                  this.tempCartLength=allSelectedTempCart.length
                  let  modal1 = Modal.getOrCreateInstance('#addtocart');
                  modal1.hide();


                }
                else {
                await axios
                    .post('/storeServicesToCart', body)
                    .then((response) => {
                            if(response.status === 200) {
                            toast.success(response.data.message, {
                                autoClose: 1000,
                            });
                            let  modal1 = Modal.getOrCreateInstance('#addtocart');
                            modal1.hide();
                            this.getCartQuantity();
                            }
                    })
                    .catch((error) => {
                        toast.error(error.response.data.message)
                        this.errorsAddCart = error.response.data;
                    })}
            }
            catch(error) {
                // console.log(error)
                this.errorsAddCart = error.response.data.errors;
            }
        },
        async getCartQuantity() {
            try {
                this.loaderStatus = true;
                await axios
                    .get('/getCartQuantity')
                    .then((response) => {
                        this.loaderStatus = false;
                        this.myCartLength = response.data.data.cart_quantity
                    })
                    .catch((error) => {
                        // this.errors = error.response.data;
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                // this.errors = error.response.data;
                this.loaderStatus = false;
            }
        },
        async getCartDetails() {
            try {
                this.loaderStatus = true;
                await axios
                    .get('/getCartDetails')
                    .then((response) => {
                        this.loaderStatus = false;
                        let temp_arr;
                        temp_arr = response.data.data.cart_details;
                        let x = temp_arr.map((item) => {
                            var diff=0
                            if(item.package_date_from&&item.package_date_to){
                            let dateFrom=moment(item.package_date_from,'YYYY-MM-DD')
                            let dateTo=moment(item.package_date_to,'YYYY-MM-DD')
                            let package_interval = item.package_interval;
                            var date2InHour_from = moment(dateFrom).hour();
                            var date2InHour_to = moment(dateTo).hour();
                             diff = moment.duration(dateTo.diff(dateFrom)).asDays();
                            let from_diff = date2InHour_from + package_interval;
                            let to_diff = date2InHour_to + package_interval;
                        
                            if(diff > 0 && ( from_diff > 24 ||  to_diff > 24) && package_interval < 24) {
                                diff = diff + 1;
                            }

                            // note: date matra her vanya cha aaba malai
                            // let daysDiff=dateTo.diff(dateFrom,'days')
                            // let diffInMilliseconds = dateTo.diff(dateFrom);
                            // let daysDiff = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));
                        }
                            if (diff<=1)diff=1;

                            let sub_total=diff>1?diff*item.price.amount:item.price.amount
                            return {...item, isChecked: false,sub_total:sub_total,daysDiff:diff};
                        })
                        this.cartDetails = x;
                    })
                    .catch((error) => {
                        // console.log(error)
                        this.errors = error;
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                // console.log(error)
                this.errors = error;
                this.loaderStatus = false;
            }
        },
        async removeCartItems(id) {
            try {
                this.loaderStatus = true;
                await axios
                    .post('/removeCartItems', id)
                    .then((response) => {
                        this.loaderStatus = false;
                        if(response.data.status === 200) {           
                        let  modal2 = Modal.getOrCreateInstance('#confirmdelete');
                        modal2.hide();
                        toast.success(response.data.message, {
                            autoClose: 500,
                          }); 
                        }
                    })
                    .catch((error) => {
                        // this.errors = error.response.data;
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                // this.errors = error.response.data;
                this.loaderStatus = false;
            }
        },
        emptyErrors() {
            this.errors = [];
        },
        setCartDetailDataEmpty(){this.cartDetails=[]
            this.tempCartDetails=[]
            localStorage.setItem('tempCartDetails',[])

            this.myCartLength=''
            this.tempCartLength=''
        return 0},

         setCartDetailDataTemp (){
            
             let temp=localStorage.getItem('tempCartDetails')? JSON.parse( localStorage.getItem('tempCartDetails')):[]
            
            //  console.log(temp)
            this.tempCartLength=temp?.length
            this.tempCartDetails=temp
        return 0},
        handleDeleteTempCart(currentIndex){
            this.tempCartDetails.splice(currentIndex, 1);
            this.tempCartLength=this.tempCartDetails.length
            localStorage.setItem("tempCartDetails",JSON.stringify(this.tempCartDetails))
        }
    },
})