<template>
  <div class="container pt-3 pb-3">
    <!-- <h5 class="text-center" >Contact Us</h5> -->
    <div class="row">
      <div class="col-md-3 col-12 p-0 d-md-block d-none"></div>
      <div
        class="col-md-6 col-12"
        style="
          background-color: var(--foundation----primary---p50);
          border-radius: 1rem;
        "
      >
     
        <h5 class="pt-3 text-left px-2">Contact Us</h5>
        <form class="container mt-3" @submit="submitHandler">
          <div class="row" v-if="!getUser.id">
            
            <div class="mb-3 col-md-3 col-12 ">
              <div class="required " >Salutation</div>
              <select
              class="form-select required"
              v-model="sendMessage.name_title"
              
              >
            <option v-for="salutaion in getSalutations" :value="salutaion.id" :key="salutaion.id" >{{ salutaion.title_name }}</option>
            </select>
            </div>
            <div class="mb-3 col-md-3 col-12">
              <div class="required">First Name</div>
              <input
                v-model="sendMessage.first_name"
                type="text"
                placeholder="First Name"
                class="form-control me-2"
                required
              />
            </div>
            <div class="mb-3 col-md-3 col-12">
              <div class="">Middle Name</div>
              <input
                v-model="sendMessage.middle_name"
                type="text"
                placeholder=" Middle Name"
                class="form-control me-2"
              />
            </div>
            <div class="mb-3 col-md-3 col-12">
              <div class="required">Last Name</div>
              <input
                v-model="sendMessage.last_name"
                type="text"
                placeholder=" Last Name"
                class="form-control me-2"
                required
              />
            </div>
          </div>
          <div class="mb-3" v-if="!getUser.id">
            <div class="required">Email</div>
            <input
              v-model="sendMessage.email"
              type="email"
              placeholder="Email"
              class="form-control me-2"
              required
            />
          </div>
          <div class="mb-3" v-if="!getUser.id">
            <div class="required">Contact Number</div>
            <vue-tel-input
    defaultCountry="NP"
    v-model="sendMessage.mobile_number"
    @input="handleSetPhone"
    mode="international"
    class=""
    required
  ></vue-tel-input>
            <!-- <input v-model="sendMessage.mobile_number" type="tel" placeholder="Contact Number" class="form-control me-2 " required  > -->
            <span v-if="!isContactNumberValid" class="text-danger"
              >Contact number must be exactly 10 digits.</span
            >
          </div>
          <div class="mb-3" v-if="!getUser.id">
            <div class="required">Date of Birth</div>
            <input
              v-model="sendMessage.dob"
              type="date"
              placeholder="Date of Birth"
              class="form-control me-2"
              required
              :max="new Date().toISOString().split('T')[0]"
            />
          </div>
          <div class="mb-3">
            <div class="required">Delivery Date</div>
            <input
              v-model="sendMessage.date"
              type="date"
              placeholder="Delivery Date"
              class="form-control me-2"
              required
              :min="new Date().toISOString().split('T')[0]"
            />
          </div>
          <div class="mb-3">
            <div class="required">Delivery Address</div>
            <input
              v-model="sendMessage.address"
              type="text"
              placeholder="Delivery Address"
              class="form-control me-2"
              required
            />
          </div>
          <!-- <div class="mb-3 ">
                                <div class=""> Select Service</div> 
                                    <select v-model="sendMessage.service" style="text-transform: capitalize;" class="form-control form-select" >
                                        <option value=""  selected >Select Service</option>
          <option  v-for="(doc,index) in [{id:1,name:'Investigation (Lab Service)'},{id:2,name:'PCR'},{id:3,name:'Medicine Delivery'},{id:4,name:'TELE Consulting'},{id:5,name:'Geriatric Care'},{id:6,name:'Prosthotics & Orthotics'},{id:7,name:'Education / Training'},{id:8,name:'Other'}]" :key="index" :value="doc.name"  style="text-transform: capitalize;" >
            {{ doc.name}}
          </option>
        </select>
                       </div>  -->
          <div class="mb-3">
            <div class="required">Message</div>
            <textarea
              v-model="sendMessage.message"
              type="text"
              placeholder="Message"
              class="form-control me-2"
              required
            ></textarea>
          </div>
          <div class="mb-3" style="max-height: 60vh; overflow-y: auto;">
            <h6>Selected Services</h6>
<div v-if="!getIsAuthorized||getopdPatientRegId" >
            <noRecordComp
              v-if="getTempCartDetail.length < 1"
              :title="'NO RECORD FOUND'"
            ></noRecordComp>

            <div v-for="(service, index) in getTempCartDetail" :key="index">
              <div class="checkout-container px-3 py-1">
                <div class="d-flex justify-content-between">
                  <div>
                    <input
                      type="checkbox"
                      class="me-2"
                      :id="`checkbox_${service.id}`"
                      v-model="service.isChecked1"
                    />
                    <label :for="`checkbox_${service.id}`">{{
                      service.name
                    }}</label>
                    <font-awesome-icon
                      class="text-danger mx-3"
                      @click="deleteSelectedCart(service)"
                      icon="fa  fa-solid fa-trash "
                    />
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <noRecordComp
              v-if="getCartDetailsData.length < 1"
              :title="'NO RECORD FOUND'"
            ></noRecordComp>

            <div v-for="(service, index) in getCartDetailsData" :key="index">
              <div class="checkout-container px-3 py-2 mb-3">
            <div class="d-flex justify-content-between">
              <div>
                <input
                  type="checkbox"
                  class="me-2"
                  :id="`checkbox_${service.id}`"
                  
                      v-model="service.isChecked1"
                />
                <label :for="`checkbox_${service.id}`">{{
                  service.service
                }}</label>
                          <font-awesome-icon
                      class="text-danger mx-3"
                      @click="deleteSelectedCartLogedIn(service)"
                      icon="fa  fa-solid fa-trash "
                    />
                <br />
                <!-- <div class="ps-4">
                  <span>Amount: &nbsp;</span>
                  <span style="font-weight: 500"
                    >NPR. {{ service.price.amount }}</span
                  >
                </div> -->

                
                <div v-if="service.is_multiple">
                   <div class="ps-4">
                  <span>From Date: &nbsp;</span>
                  <span style="font-weight: 500"> {{ service.package_date_from }}</span>
                </div>
                   <div class="ps-4">
                  <span>To Date: &nbsp;</span>
                  <span style="font-weight: 500"> {{ service.package_date_to }}</span>
                </div>
                </div>

                <div v-else>

                  </div>


               <div v-if="service.pick_up_location">
                   <div class="ps-4">
                  <span>Pick-up Location: &nbsp;</span>
                  <span style="font-weight: 500">{{ service.pick_up_location }} </span>
                </div>
                   <div class="ps-4">
                  <span>Drop-off Location: &nbsp;</span>
                  <span style="font-weight: 500">{{ service.drop_location}} </span>
                </div>
               </div>

               <div v-else>
                  <!-- <div class="ps-4">
                  <span>Pick-up Location: &nbsp;</span>
                  <span style="font-weight: 500"> </span>
                </div>
                   <div class="ps-4">
                  <span>Drop-off Location: &nbsp;</span>
                  <span style="font-weight: 500"> </span>
                </div> -->
                </div>

                <div  v-if="service.display_name">
                   <div class="ps-4">
                  <span>Mode of Teleconsultation: &nbsp;</span>
                  <span style="font-weight: 500"
                    > {{ service.display_name }}</span
                  >
                </div>
                </div>
                

                <div v-else>
                  </div>
                  
              </div>
              <div>
                <!-- <font-awesome-icon class="c-pointer" data-bs-target="#confirmdelete" data-bs-toggle="modal" @click="cancelService(service.id)" style="color: #ff3030" icon = "fa-solid fa-close"/> -->
              </div>
            </div>
          </div>
            </div>
          </div>
          </div>
          <div class="pt-3 pb-3 d-flex justify-content-end">
            <button
              class="btn"
              style="
                background-color: var(--foundation----primary---p200);
                color: white;
              "
            >
              Send
            </button>
          </div>
        </form>
      </div>

      <div class="col-md-3 col-12 p-0 d-md-block d-none"></div>
    </div>
  </div>
</template>

<script setup>
// import accountSidenav from '@/components/accountSidenav.vue'
import moment from "moment";
import { ref ,onMounted,watch} from "vue";
import { storeToRefs } from "pinia";
import { useAuthChangePassword } from "@/core/modules/manage/changepassword";
import { useContactUs } from "@/core/modules/self/contactUs";
import { useSelfCart } from "@/core/modules/self/mycart";
import { useManageUser } from "@/core/modules/auth/user";
import noRecordComp from "@/components/norecord";
import { VueTelInput } from "vue3-tel-input";

import "vue3-tel-input/dist/vue3-tel-input.css";
const { getIsAuthorized, getUser,getopdPatientRegId,getPaidUnpaidAmount,getSalutations } = storeToRefs(useManageUser());
const { getTempCartDetail ,getCartDetailsData} = storeToRefs(useSelfCart());
const storeCart = useSelfCart();
import { GoogleMap, Marker } from "vue3-google-map";
import { useRoute } from "vue-router";
const { getIsPostSuccess } = storeToRefs(useContactUs());
const store = useContactUs();
const userStore=useManageUser();
import router from "@/router/index";




// const  markerPosition=ref({
//     lat: parseFloat(27.6931218),
//                 lng: parseFloat(85.3144682)
// })
const route = useRoute();
// console.log(route.params)
const sendMessage = ref({ service: "",country_code:"NP" });
const isContactNumberValid = ref(true);


onMounted(async()=>{

  await userStore.fetchSalutations()
  if(getIsAuthorized){
    await  storeCart.getCartDetails();
    storeCart.getCartQuantity();
    
    sendMessage.value={...getUser.value,mobile_number:getUser.value.contact_number,country_code:"NP"}
  }
  if(getSalutations.value?.length>0){
    sendMessage.value.name_title=getSalutations.value[0].id

  }
  
})

const handleSetPhone = (e, value) => {
  if (value?.nationalNumber) {
    sendMessage.value.mobile_number = value?.nationalNumber;
  }
  if (value?.countryCallingCode) {
    sendMessage.value.country_code = value?.countryCallingCode;
  }

  if (value?.nationalNumber !== 10) {
    sendMessage.value.msg;
  }
};

const validateContactNumber = () => {
  const nationalNumber = sendMessage.value.mobile_number;
  if (nationalNumber.length !== 10) {
    return false;
  }
  return true;
};

const deleteSelectedCart = (service) => {
  // console.log(service.id)
  const currentIndex = getTempCartDetail.value.indexOf(service);
  // console.log(currentIndex)
  storeCart.handleDeleteTempCart(currentIndex);
};

const deleteSelectedCartLogedIn=async (service)=>{
  await storeCart.removeCartItems({cart_id:service.id})
  await storeCart.getCartDetails() 
}
const submitHandler = async (e) => {
  e.preventDefault();
  if (!getUser.value.id){
    if (sendMessage.value.dob){
      sendMessage.value.age=moment().diff(sendMessage.value.dob, 'years');


    }
  if (!validateContactNumber()) {
    isContactNumberValid.value = false;
    return;
  }
}


  isContactNumberValid.value = true;
  if(!getIsAuthorized.value){
  sendMessage.value.items = [];

  getTempCartDetail.value.forEach((element) => {
    if (element.isChecked1) {
      sendMessage.value.items.push(element);
    }
  });
}
else{
  sendMessage.value.items=[]
  getCartDetailsData.value.forEach((element) => {
   
    if (element.isChecked1) {
      sendMessage.value.items.push(
      {...element,name:element.service,isChecked:1}

      );
    }
  });
}

  // console.log(sendMessage.value)
  await store.submitContactUs(sendMessage.value);

  if (getIsPostSuccess.value) {
    storeCart.setCartDetailDataEmpty();
    sendMessage.value = {};
    router.push({ name: "page.landing" });
  }
};
</script>
<style scoped>
.required::after {
  content: " * ";
  color: red;
}
</style>
