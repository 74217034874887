<template>
     <div class="container-fluid " >
    <div class="row">
        <div class="col-12 mx-auto my-5 otp-container avtivateuser-form" >
            
            <div class="p40_50 forms-bg" >
                 <form class="mt-4">
                    <p>New Password</p>
                    <div class="mb-4" style="position: relative">
      
              <input :type="!showNewPassword ? 'password' : 'text'" placeholder="New Password" class="form-control mb-2" id="password"
                v-model="new_password" />
              <font-awesome-icon class="fo-text-color-primary-p300" @click="showNewPasswordFun()" :icon="!showNewPassword ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'
                " style="
                  position: absolute;
                  right: 10px;
                  top: 35%;
                  cursor: pointer;
                  font-size: 12px;
                " />
        
          
            </div> 
            <div class="mb-4" style="position: relative ">
              
              <input :type="!showConfirmPasword ? 'password' : 'text'" placeholder="Confirm Password" class="form-control" id="password"
                v-model="confirm_password" />
              <font-awesome-icon class="fo-text-color-primary-p300" @click="showConfirmPasswordFun()" :icon="!showConfirmPasword ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'
                " style="
                  position: absolute;
                  right: 10px;
                  top: 35%;
                  cursor: pointer;
                  font-size: 12px;
                " />
        
      
            </div>
                </form>
                <button type="submit" style="width: 100%" @click="confirmPassword" class=" btn btn-primary btn-meraki-primary mb-4 mt-2">Confirm</button>
                </div>
        </div>
    </div>
</div>
</template>
<script setup>
import { ref } from "vue";
import { useAuthForgotPassword } from '@/core/modules/auth/forgotpassword';
const showNewPassword = ref(false);
const showConfirmPasword = ref(false);
const new_password = ref('');
const confirm_password = ref('');
const store = useAuthForgotPassword();
function showNewPasswordFun(){
    showNewPassword.value=!showNewPassword.value
}
function showConfirmPasswordFun(){
    showConfirmPasword.value=!showConfirmPasword.value
}
function confirmPassword(){
  let contact_number = parseInt(window.localStorage.getItem("contact"));
    var body = {
        contact : contact_number,
        new_password: new_password.value,
        password_confirmation : confirm_password.value,
        device_id : 'e78ad27aea950c14'
    }
    store.forgetPassword(body);
}
</script>