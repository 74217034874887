<template>
  <modalComp
    :idmodal="'orderdetails'"
    :show-header="true"
    :show-footer="true"
    :size="'modal-lg'"
  >
    <template v-slot:header>
      <h5 class="modal-title">{{ billNumber }}</h5>
      <button
        type="button"
        class="btn-close shadow-none"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
    </template>
    <template v-slot:body>
      <div class="mb-2 mx-2" style="font-size: 0.75rem">
        <span>Appointment Date : </span
        ><span style="font-weight: 500"> {{ appointmentDate }}</span>
        <br />
        <span>Appointment Time : </span
        ><span style="font-weight: 500"> {{ appointmentTime }}</span>
      </div>
      <div style="overflow: auto">
        <div class="mb-1 mx-2">
          <table class="table table-striped" style="font-size: 0.75rem">
            <thead>
              <tr>
                <th>S.N</th>
                <th>Item Name</th>
                <th>Service Provider</th>
                <th>Price</th>
                <th>Service Status</th>
                <!-- <th>Status</th> -->
                <th>Check-In Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(bill, index) in getOrderDetails" :key="index">
                <td>{{ index + 1 }}</td>

                <td v-if="bill.is_package == 0">{{ bill.item_name }}</td>
                <td class="patient" v-else>
                  <div
                    @click="toggleTableVisibility(bill.sBillDetailsId)"
                    :style="{
                      'text-decoration': isOpen(bill.sBillDetailsId) ? 'underline' : 'none',
                      'font-weight': isOpen(bill.sBillDetailsId) ? 'bold' : 'normal',
                    }">
                    {{ bill.item_name }}
                    <span v-if="isOpen(bill.sBillDetailsId)" class="click-indicator"> ▼ </span>
                    <span v-else class="click-indicator"> ▲ </span>
                  </div>

                  <div v-if="isOpen(bill.sBillDetailsId)">
                    <tr v-for="(pack, ind) in bill.package" :key="ind">
                      <td>
                        <span style="font-size: 1em">&bull;</span>
                        {{ pack.name }}
                      </td>
                    </tr>
                  </div>
                </td>
             
                <td v-if="bill.is_package == 0 && bill.provider.length == 0">
                  N/A
                </td>

                 <td
                  v-else-if="
                    bill.is_package == 0 &&
                    bill.provider[0].status == 'assigned'
                  "
                >
                  {{ bill.provider[0].provider_name }}
                </td>
               
                <!-- <td
                  v-else-if="
                    (bill.is_package == 0 &&
                      bill.provider[0].status == 'N/A' || 'na')
                  "
                >
                  N/A 
                </td> -->
               
                <td v-else>
                  <div class="invisible" v-if="isOpen(bill.sBillDetailsId)">" "</div>
                  <table v-if="isOpen(bill.sBillDetailsId)">
                    <tr v-for="(pack, ind) in bill.package" :key="ind">
                      <td v-if="isOpen(bill.sBillDetailsId)">
                        <div>
                          {{
                            pack.provider[0]
                              ? pack.provider[0].provider_name ?? "N/A"
                              : null
                          }}
                        </div>
                      </td>
                      <td v-if="isOpen(bill.sBillDetailsId) && pack.provider.length == 0">
                     N/A
                    </td>
                    </tr>
                  </table>
                </td>

                <td v-if="bill.is_package == 0 || bill.is_package == 1">
                  NPR. {{ bill.price?.toLocaleString() }}
                </td>
                <td v-else>
                  <div class="invisible">" "</div>
                  <tr v-for="(pack, ind) in bill.package" :key="ind">
                    <td>
                      {{ null }}
                    </td>
                  </tr>
                </td>

                <td
                  v-if="bill.is_package == 0"
                  style="text-transform: capitalize"
                >
                  <!-- {{ bill.completedStatus == 0 ? "Completed" : bill.status }} -->
                  {{ bill.status }}
                </td>
                <td v-else>
                  <div class="invisible">" "</div>
                  <tr v-for="(pack, ind) in bill.package" :key="ind">
                    <td v-if="isOpen(bill.sBillDetailsId)">
                      {{
                        bill.is_package == 1
                          ? pack?.provider[0]?.service_status
                          : 'pending'
                      }}
                    </td>
                    <td v-if="isOpen(bill.sBillDetailsId) && pack.provider.length == 0">
                     N/A
                    </td>
                  </tr>
                </td>

                <!-- <td v-if="bill.is_package == 0">
                  {{
                    bill.provider[0]
                      ? bill.provider[0].status
                        ? bill.provider[0].status
                        : "pending"
                      : "N/A"
                  }}
                </td>
                <td v-else>
                  <div class="invisible">" "</div>
                  <tr v-for="(pack, ind) in bill.package" :key="ind">
                    <td v-if="isOpen(index)">
                  {{console.log("sahaj",pack)}}
                      {{ bill.completedStatus == 1 ? pack.status : null }}
                      {{ pack.provider[0]?.status }}
                    </td>
                  </tr>
                </td> -->

                <td
                  v-if="bill.is_package == 0"
                  style="text-transform: capitalize"
                >
                  {{
                    bill.checkin_exists == 1
                      ? "Checked-In"
                      : bill.checkin_exists == 0
                      ? "Not Checked-In"
                      : bill.checkin_exists == 2
                      ? "Checked-Out"
                      : null
                  }}
                </td>
                <td v-else>
                  <div class="invisible">" "</div>
                  <tr v-for="(pack, ind) in bill.package" :key="ind">
                    <td v-if="isOpen(bill.sBillDetailsId)">
                      {{
                         pack.provider[0]?.checkin_exists === 1
                            ? "Checked-In"
                            : pack.provider[0]?.checkin_exists === 0
                            ? "Not Checked-In"
                            : pack.provider[0]?.checkin_exists === 2
                            ? "Checked-Out"
                            : null
                      }}
                    </td>
                    <td v-if="isOpen(bill.sBillDetailsId) && pack.provider.length == 0">
                     N/A
                    </td>
                  </tr>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template v-slot:footer> </template>
  </modalComp>
</template>

<script setup>
import { onMounted, ref, computed } from "vue";
import modalComp from "@/components/common/modal";
import moment from "moment";
import { storeToRefs } from "pinia";
import { useSelfOrder } from "@/core/modules/self/order";
const store = useSelfOrder();
const { getOrderDetails } = storeToRefs(useSelfOrder());
const showTableData = ref(false);
const tableVisible = ref(false);
const oneStepDown = ref(false);
const openPackage = ref([]);

const isOpen = (Sbillno) => openPackage.value.includes(Sbillno);

moment().format();
// const emit = defineEmits(['totalChange']);
onMounted(() => {
  // console.log(getOrderDetails);
});

const billNumber = computed(() => {
  if (getOrderDetails.value) {
    return getOrderDetails.value[0].bill_no;
  } else {
    return null;
  }
});

const toggleTableVisibility = (Sbillno) => {
  if (isOpen(Sbillno)) {
    openPackage.value = openPackage.value.filter((Packageitem) => Packageitem !== Sbillno);
  } else {
    openPackage.value.push(Sbillno);
  }
};

const appointmentDate = computed(() => {
  if (getOrderDetails.value) {
    return getOrderDetails.value[0].appointment_date;
  } else {
    return null;
  }
});

const appointmentTime = computed(() => {
  if (getOrderDetails.value) {
    return getOrderDetails.value[0].appointment_time;
  } else {
    return null;
  }
});

function showTable() {
  showTableData.value = true;
}
</script>
<style scoped>
.clickable-item {
  cursor: pointer;
}

.patient table thead th {
  border-bottom: 1px solid #080808;
}

.invisible {
  visibility: hidden;
}



</style>  