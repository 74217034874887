import axios from "axios";
import { defineStore } from "pinia";
import { toast } from "vue3-toastify";
import router from "@/router/index";
import { Modal } from "bootstrap";

export const usePurchaseHistory = defineStore("purchaseHistory", {
  state: () => ({
    errors: [],
    loaderStatus: false,
    refundHistory: null,
    pdfbillprint: null
  }),
  getters: {
    getErrors: (state) => {
      return state.errors;
    },
    getLoaderStatus: (state) => {
      return state.loaderStatus;
    },
    getRefundHistory: (state) => {
      return state.refundHistory;
    },
    getBillPrint:(state)=>{
      return state.pdfbillprint
    }
  },
  actions: {
    async depositRefund(id,type) {
      try {
        await axios
          .get(`/getDepositRefundBill?id=${id}&type=${type}`,{responseType: "blob"})
          .then((response) => {
            const blob = new Blob([response.data], { type: "application/pdf" });
            const objectUrl = URL.createObjectURL(blob);
                this.refundHistory = objectUrl
               
          })
          .catch((error) => {
            this.loaderStatus = false;
          });
      } catch (error) {
        this.loaderStatus = false;
      }
    },

    async billPrint(id,type) {
      try {
        await axios
          .get(`/pdf/bill_print/${id}/${type}`,{responseType: "blob"})
          .then((response) => {
            const blob = new Blob([response.data], { type: "application/pdf" });
            const objectUrl = URL.createObjectURL(blob);
                this.pdfbillprint = objectUrl   
          })
          .catch((error) => {
            this.loaderStatus = false;
          });
      } catch (error) {
        this.loaderStatus = false;
      }
    },

  },
});
