<template>
  <div class="container-fluid w-100">
    <div class="row">
      <div class="col-lg-12   mx-auto my-5 login-form">
        <div class="p40_50 forms-bg p20">
          <div class="d-flex justify-content-center">
            <p style="color: var(--foundation----primary---p300)" class="fs-4 fw-semibold text-center">
              Hi, Welcome Back
            </p>
          </div>
          <div class="d-flex justify-content-center">
            <p style="
                color: var(--foundation----primary---p300);
                margin-top: -10px;
              " class="fs-4 fw-semibold text-center">
              Login to your account
            </p>
          </div>
          <form class="mt-3" @submit="routeTo">
            <div class="mb-3">
              <label for="mobilenumber" class="form-label fs-10">Mobile Number</label>
              <input placeholder="Mobile Number" type="tel" class="form-control" id="mobilenumber"
                aria-describedby="emailHelp" v-model="phoneNumber"/>
              <label for="" v-if="getErrors ? (getErrors['errors']['id'] ? true : false) : false
                " class="text-danger">{{
    getErrors
    ? getErrors["errors"]["id"]
      ? getErrors["errors"]["id"][0]
      : ""
    : ""
  }}</label>
            </div>
            <div class="mb-1" style="position: relative">
              <label for="password" class="form-label fs-10">Password</label>
              <input :type="!showPassword ? 'password' : 'text'" placeholder="Password" class="form-control" id="password"
                v-model="password" />
              <font-awesome-icon class="fo-text-color-primary-p300" @click="showPasswordFun()" :icon="!showPassword ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'
                " style="
                  position: absolute;
                  right: 10px;
                  top: 45px;
                  cursor: pointer;
                  font-size: 12px;
                " />
              <label for="" v-if="getErrors
                  ? getErrors['errors']['password']
                    ? true
                    : false
                  : false
                " class="text-danger">{{
    getErrors
    ? getErrors["errors"]["password"]
      ? getErrors["errors"]["password"][0]
      : ""
    : ""
  }}</label>
              <label v-if="getErrors
                    ? getErrors['status'] == 401
                      ? true
                      : false
                    : false
                  " class="text-danger">{{ getErrors["message"] }}</label>
            </div>
            <div class="d-flex justify-content-between pb-2 ">
              <router-link @click="forgetPassword" class="routerLink  " style="border-top: none; font-size:12px;"
                :to="{ name: 'page.auth.forgotpassword' }"><span class="c-pointer  "
                  style="color: var(--foundation----primary---p300)">Forgot Password?</span></router-link>
            </div>

            <button style="width: 100%" class="btn btn-primary btn-meraki-primary mb-3  mt-3 " :disabled="getLoaderStatus">
              Login
              <span v-if="getLoaderStatus" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            </button>
          
            
            <!-- <router-link class="routerLink pt-4 text-center" :to="{ name: 'page.auth.register' }"><span
                class="c-pointer fs-10" style="color: var(--foundation----primary---p300)">Create a new
                account</span></router-link> -->
               
          </form>
          <div class="detween pb-0 ">
              <span class="  " style="border-top: none; font-size:12px;"
                ><span class=" "
                  style="color: var(--foundation----primary---p300)"> If you are logging in for the first time, Please activate your account.</span></span>
            </div>
         
          <router-link class="routerLink text-center" style="border-top: none"
          :to="{ name: 'page.auth.forgotpassword' }">
          <button @click="activateAcc" style="width: 100%" class="btn btn-meraki-secondary mb-4 mt-2">
            Activate
          </button>
        </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useAuthLogin } from "@/core/modules/auth/login";
import { useManageUser } from "@/core/modules/auth/user";
const { getIsAuthorized } = storeToRefs(useManageUser());
const storeUSer = useManageUser();
const { getErrors, getLoaderStatus } = storeToRefs(useAuthLogin());
const store = useAuthLogin();
const showPassword = ref("");
const phoneNumber = ref("");
const password = ref("");


onMounted(() => {
  window.localStorage.clear('id_token');
  storeUSer.setIsAuthorizedFalse();
  
})
function showPasswordFun() {
  showPassword.value = showPassword.value ? false : true;
}
function routeTo(e) {
  e.preventDefault();
  var credentials = {
    id: phoneNumber.value,
    password: password.value,
  };
  store.submitLogin(credentials);
}

function activateAcc(){
  window.localStorage.setItem('password', 1);
}
function forgetPassword(){
  window.localStorage.setItem('password', 0)
}
</script>
