<template :activeAccount>
    <div class="container-fluid">
        <div class="row ctm-padding">
            <div class="col-12 my-2">
                <h6>My Account</h6>
            </div>
            <div class="col-12 col-md-3 ">
                <div class="account-sidenav-section">
                    <ul class="p-0 list-unstyled mb-0">
                        <!-- <li @click="routeTo(acc_list.id)" :class="(activeTab == acc_list.id)   ? 'active_tab' : ''"  class="c-pointer" style="padding: 12px 0px;" v-for="acc_list in getAccountSidenav" :key="acc_list.id">  -->
                            <li @click="routeTo(acc_list.id, acc_list.route_name)" :class="_activeTab(acc_list.route_name)  ? 'active_tab' : ''"  class="c-pointer" style="padding: 12px 0px;" v-for="acc_list in getAccountSidenav" :key="acc_list.id"> 
                            <font-awesome-icon class="acc-icons" :icon= acc_list.icon />
                            <span> {{ acc_list.name }} </span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-md-9 ">
                <router-view></router-view>
            </div>
        </div> 
 
    </div>
</template>
<script setup>
import { ref, onMounted, watch } from 'vue';
import { storeToRefs } from 'pinia'
import { useManageAccount  } from '@/core/modules/manage/account'
import { useRoute } from 'vue-router';
const route = useRoute();
const { getAccountSidenav } = storeToRefs(useManageAccount())
let activeTab = ref('')
const routeAccountSection = useManageAccount(); 
function _activeTab(route_name){
    if(activeTab.value == route_name){
        return true;
    }else{
        return false;
    }
}
function routeTo(id, route_name){
    activeTab.value = route_name;
    routeAccountSection.setSelectedMenu(id);
} 
onMounted(() => {
    activeTab.value = route.name;
})
watch(() => route.name, () => {
    return activeTab.value = route.name;
});
</script>
<style scoped>
</style>
