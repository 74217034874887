<template>
    <modalComp :idmodal="'deleteaddress'" :show-header="true" :show-footer="true" :size="'modal-md'">
        <template v-slot:header>
            <h5 class="modal-title">Confirm Delete </h5>
            <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
        </template>
        <template v-slot:body>
            <div>
                <div class="mb-1 mx-2">
                    <p>
                        Are you sure you want to delete this address?
                    </p>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div class="p-2">
                <button class="btn-meraki-secondary me-2" data-bs-dismiss="modal" >Cancel</button>
                <button class="btn-meraki-primary" data-bs-dismiss="modal"  @click="removeItem">Yes</button>
            </div>
        </template>

    </modalComp>
</template>

<script setup>
import { onMounted } from "vue";
import modalComp from '@/components/common/modal'
import { useSelfAddress } from '@/core/modules/address'
const store = useSelfAddress();
const props = defineProps({
    deletedAddress: String,
})
// const emit = defineEmits(['totalChange']);
onMounted(() => {
})
function removeItem(){
    var  body = {
        id: props.deletedAddress
    }
    store.deleteAddress(body);
    store.getAddressDetailsData();
}
</script>