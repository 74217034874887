<template>
    <modalComp :idmodal="'purchaseDetail'" :show-header="true" :show-footer="true" :size="'modal-lg'">
        <template v-slot:header>
            <h5 class="modal-title">{{ getCurrentSelectedBillDetail?.bill_no??'-' }} </h5>
            <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
        </template>
        <template v-slot:body>
            <div v-if="getCurrentPDf">
                <iframe :src="getCurrentPDf" width="100%" style="height: calc(100vh - 150px);"
              frameborder="0"></iframe>
            </div>
            <div v-else>
            <div class="mb-2 mx-2" style="font-size: 0.75rem" >
                <span>Billed At : </span><span style="font-weight: 500">  {{new Date(getCurrentSelectedBillDetail?.created_at)?.toLocaleDateString() +' at ' + new Date(getCurrentSelectedBillDetail?.created_at)?.toLocaleTimeString() }}</span>
                
            </div>
            <div style="overflow: auto;" >
                <div class="mb-1 mx-2">
                    <table class="table table-striped" style="font-size: 0.75rem; ">
                        <thead>
                            <tr>
                                <th>S.N</th>
                                <th>Bill Number</th>
                                <th>Service Status</th>
                                <th>Service Provider</th>
                                <th>Service Provider Status</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(bill, index) in getMyPurchaseDetail" :key =index>
                                <td>{{ index + 1}}</td>
                                <td >{{bill.bill_no??"-"}}</td>
                                <td >{{bill.completedStatus?"completed":"pending"}}</td>
                            
                                <td>{{ bill?.provider && bill.provider[0] ? (bill.provider[0]?.provider_name ?? "-") : "-" }}</td>
                                <td>{{ bill?.provider && bill.provider[0] ? (bill.provider[0]?.status ?? "-") : "-" }}</td>
                                
                                    
                                
                                <td>NPR. {{ bill.price?.amount?.toLocaleString()??"-"}}</td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
            
        </template>
        <template v-slot:footer>
        </template>

    </modalComp>
</template>

<script setup>
import { onMounted, ref, computed } from "vue";
import modalComp from '@/components/common/modal'
import moment from 'moment';
import { storeToRefs } from 'pinia'
import { useSelfPurchaseHistory } from '@/core/modules/self/mypurchase'
const store = useSelfPurchaseHistory();
const { getMyPurchaseDetail,getCurrentSelectedBillDetail ,getCurrentPDf} = storeToRefs(useSelfPurchaseHistory());
const showTableData = ref(false);
moment().format()
// const emit = defineEmits(['totalChange']);
onMounted(() => {
    // console.log(getOrderDetails);
})






function showTable() {
    showTableData.value = true
}
</script>
<style scoped>

</style>