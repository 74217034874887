<template>
    <div class="container-fluid">
        <div class="row ctm-padding">
           <div class="col-12">
            <h6>All Services</h6>
   
        
        <a href="#" class="nav-link">
              <input type="text" v-model="searchQuery"   class="main_search" placeholder="Search service name">
            </a>

        <div class="pt-3">

            
                <noRecordComp v-if="searchedAllServices.length<1" :title="'NO RECORD FOUND'">
            </noRecordComp>
            <div v-if="searchQuery">
            <div class="row ">
                <div v-for="(service,index) in searchedAllServices" class="  col-6 col-md-4 col-lg-4 " :key="index">
                    <div class=" all-service-landing-card mb-4 py-3 px-1 mx-1">
                        <div class="d-flex justify-content-between px-2">
                            <div>
                       <h6 class="text-left">{{ service.name }}</h6> 
                       <div class="text-left">NPR. {{ service.total_amount?.toLocaleString() }}</div> 
                       <div class="text-left " style="font-size :0.8rem;">Department: {{ service.department }}</div> 

                    </div>
                       
                       <!-- <font-awesome-icon   class="nav-icon pr-3" style="font-size :1rem; color: var(--foundation----primary---p200);" icon="fa-solid fa-cart-shopping"  @click="bookServices(service.department, service.is_multiple, service.department_id,service.icon)" />  -->
                   
                    </div>
                       <!-- <p style="font-size: 12px; font-weight: 300">Lorem ipsum dolor sit ametLorem ipsum dolor sit amet</p> -->
                       <!-- <button class="outline-btn cart-btn" @click="addToCartBtn(service.department, service.is_multiple, service.department_id,service.icon)" data-bs-toggle="modal" data-bs-target="#addtocart" >Show More</button> -->
                       
              
                    </div>  
                </div>

            </div>
        </div>
        <noRecordComp v-if="searchedAllServices.length>0 &&!searchQuery " :title="'Please search the service first'">
            </noRecordComp>
        </div>


            <div></div>
           </div>
        </div>
    </div>
</template>

<script setup>
import {watch, onMounted,ref } from "vue";
import noRecordComp from "@/components/norecord";

import { storeToRefs } from "pinia";
import { useAllServices } from '@/core/modules/self/allservices';


const searchedAllServices=ref([])
const searchQuery=ref('')
const { getAllServices} = storeToRefs(useAllServices())
const store = useAllServices();

onMounted(async() => {
   await store.getAllServicesList() 
   searchedAllServices.value=[...getAllServices.value]
});

// const handleSearchChange=()=>{
    
// }


watch(searchQuery, (newValue, oldValue) => {
// console.log(getAllServices.value[0].name)
 searchedAllServices.value=getAllServices.value.filter((elemet)=>{
       return  elemet.name?.toLowerCase().includes(searchQuery.value?.toLocaleLowerCase()) 
    })
})


</script>


<style scoped>
.main_search{
    width: 33% !important;
    height:2rem;
}
.pending_btn {
    background-color: var(--foundation----secondary---s100) !important;
}

.completed_btn {
    background-color: var(--foundation----secondary---s300) !important;
}
</style>