<template>
    <modalComp :idmodal="'paymentsure'" :show-header="true" :show-footer="true" :size="'modal-md'">
        <template v-slot:header>
            <h5 class="modal-title">Payment </h5>
            <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
        </template>
        <template v-slot:body>
            <div>
                <div class="mb-1 mx-2">
                    <p>
                        Are you sure you want to pay NPR. {{ total_payable_amount?.toLocaleString() }}  
                    </p>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div class="p-2">
                <button class="btn-meraki-secondary me-2" data-bs-dismiss="modal" >Cancel</button>
                <button class="btn-meraki-primary" data-bs-dismiss="modal" @click="proceedPaymentSureEmitter" >Pay</button>
            </div>
        </template>
    </modalComp>
</template>

<script setup>
import { ref, onMounted, computed ,watch} from 'vue'
import modalComp from '@/components/common/modal'
const emit=defineEmits(["proceedPaymentSure"])
const props = defineProps({
    selectedPaymentId:Number,
    totalPayableAmount: Number
})

function proceedPaymentSureEmitter(){
    emit("proceedPaymentSure",selected_payment_id.value)
}
watch(()=>props.selectedPaymentId,()=>{
    return props.selectedPaymentId;
})

const total_payable_amount=computed(()=>{
    return props.totalPayableAmount
})

const selected_payment_id=computed(()=>{
    return props.selectedPaymentId
})
</script>