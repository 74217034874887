import { defineStore, storeToRefs } from 'pinia'
import { useJwtService } from "@/core/services/jwt";
import {useManageUser} from '@/core/modules/auth/user'
import axios from "axios";
import { toast } from 'vue3-toastify';
import {useSelectAccount} from '@/core/modules/manage/selectaccount'
import { useComponentCardProfile } from '@/core/modules/components/card/profile'
import { Modal } from 'bootstrap';

export const useAuthLogin = defineStore('authLogin', {
    state: () => ({
        errors: null,
        loaderStatus:false
    }),
    getters:{
        getErrors:(state)=>{
            return state.errors
        },
        getLoaderStatus: (state) => {
            return state.loaderStatus
        }
      },
    actions: {
       
        async submitLogin (body){
            const jwtService = useJwtService();
            const manageUserStore =  useManageUser();
            
            this.errors = null;
            try {
                this.loaderStatus = true;
            await axios
                .post('/login', body)
                .then(async(response) => {
                    this.loaderStatus =  false;
                    if(response.status === 200){
                        var token = response?.data?.data?.refreshToken
                        ;
              var has_multiple_account = response.data.data.has_multiple_account;

              if(!has_multiple_account){
                jwtService.saveToken(token);
                // const { getIsAuthorized } = storeToRefs(useManageUser()); 
                await manageUserStore.getDeopsitAndUnpaidAmount()
                const {getPaidUnpaidAmount}=storeToRefs(useManageUser())
                  let  modal1 = Modal.getOrCreateInstance('#paymentInfoModal');
                  if(getPaidUnpaidAmount.value.due_amount > 0){
                      modal1.show();
                  }
              }
                        jwtService.saveToken(token);
                        manageUserStore.setUser(response.data.data.user_info);
                        manageUserStore.setIsAuthorized(true);
                        // this.$router.push({ name: 'page.landing'})
                        if(has_multiple_account){
                            const componentCardProfile = useComponentCardProfile();
                            const selectAccountStore =  useSelectAccount();
                            componentCardProfile.selectAccount(response.data.data.user_info.patient_id,response.data.data.refreshToken
                            );
                            selectAccountStore.setSelectToken(response.data.data.refreshToken
                            )
                            this.$router.push({name:'page.manage.selectaccount'});
                          }
                          else {
                            this.$router.push({name:'page.landing'});
                          }
                    }
                    else{
                        manageUserStore.setIsAuthorized(false)
                    }
                })
                .catch((error) => {
                    // Note: need to understand what status 400 error is not being rendered to the ui  monday
                    if(error.response.status==400){
                        toast.error(error.response.data.message);

                        this.errors={
                            
                                "message":error.response.data.message ,
                                "errors": error.response.data.data,
                                "status": 401
                            

                        };  this.loaderStatus = false;
                        manageUserStore.setIsAuthorized(false);

                    }
                    else{                      
                    this.loaderStatus = false;
                    this.errors =  error.response.data;                   
                    manageUserStore.setIsAuthorized(false);
                    }           
                });
               
            }
            catch(error) {
              
                this.loaderStatus =  false;
                this.errors =  error.response.data;
             
            }
        }
    }
})