<!-- eslint-disable vue/require-v-for-key -->
<template>
    <div class="container-fluid">
        <div class="row ctm-padding">
          <div class="col-1"></div>
            <div class="col-11  ">
                <h6>My Investigation Reports</h6>
            </div>
            <div v-if="true" style="padding:0% 10%" >

              <div class=" d-flex justify-content-end" >
                <button  v-if ='!isShowInvestigationReport' type="" class=" btn btn-sm btn-meraki-secondary me-2" :onClick="async()=>{ await store.getExternalGraphDataApi() ;isShowInvestigationReport=true}" > View Investigation Graphs</button>
                <button  v-if ='isShowInvestigationReport' type="" class=" btn btn-sm btn-meraki-danger me-2"  :onClick="()=>isShowInvestigationReport=false">Close Investigation Graph</button>
                
              </div>
                

<h6>
  <div v-if="isShowInvestigationReport">
    <div v-if="getExternalGraphdata!=[]">
<div v-for="keyIs in Object.keys(getExternalGraphdata)"  >
  <h6>{{keyIs?.toUpperCase()}} </h6>
<div v-if="keyIs==='test'" class="p-2" >
<div v-for='(testdata,index) in getExternalGraphdata.test'  :key="index">

<vue-apex-charts height="400px" width="100%" type="line"  :options=" {
    chart: {
      id: index,
      dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              }
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve:'straight',
      dashArray:[0,1,1]
    },
    title: {
      text: index,
      align: 'left'
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      },
    },
    markers: {
      size: 1
    },
    
    xaxis: {
       
        min:new Date(testdata?.dates[0])?.toDateString(),
        max:new Date(testdata?.dates[testdata.dates.length -1])?.toDateString(),
      categories: testdata.dates?.map(element=> new Date(element)?.toDateString()),
      title: {
        text: 'Date'
      },
     
      type:'datetime'
    },
    yaxis: {
      title: {
        text: 'Value'
      }
    }
  }" :series='[{data:testdata.values,name:index},{data:Array(testdata.values?.length).fill(testdata.max),name:"max"},{data:Array(testdata.values?.length).fill(testdata.min),name:"min"}]' > 
  </vue-apex-charts>

</div>
</div>
<div v-if="keyIs==='package'"  class="p-2">
<div v-for='(testdata,index) in getExternalGraphdata.package'  :key="index">

<vue-apex-charts height="400px" width="100%" type="line"  :options=" {
    chart: {
      id: index,
      dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              }
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'stepline'
    },
    title: {
      text: index,
      align: 'left'
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      },
    },
    markers: {
      size: 1
    },
    xaxis: {
    
        // categories: [1,2],
      title: {
        text: 'Date'
      },
      type:'datetime'

    },
    yaxis: {
      title: {
        text: 'Value'
      }
    }
  }" :series="Object.keys(testdata)?.map((element)=>{return {data:testdata[element].values?.map((data,index)=>[testdata[element].dates[index],data]),name:element,type:'line'} })" >
  </vue-apex-charts>

</div>
</div>
</div>
</div>
<div v-if="getExternalGraphdata?.length==0">
  <noRecordComp :title="'NO GRAPH TO PREVIEW'" ></noRecordComp>

</div>
</div>
</h6>


                
            </div>
           
            <div v-if="!isShowInvestigationReport">
            <noRecordComp v-if="getInvestigationReports.length < 1 && !getLoaderStatus" :title="'NO RECORD FOUND'">
            </noRecordComp> <div class="row">
            <div class="col-1"></div>
            <div class="col-2">
                <div v-for="(inv, index) in getInvestigationReports" :key="index">
                    <!-- <div class="report-card">
                        <div class="py-2">{{ reports.bill_no }}</div>
                        <div class="pb-2">{{ reports.package }}</div>
                        <div class="pb-2">{{ reports.created_at }}</div>
                    </div> -->
                    <sideItemComp v-on:onactive="openPdf(inv.branch_id,inv.bill_no,inv.itemDetails?inv.itemDetails[0]?.sampleCollectionId:'')" v-on:viewDocReport="previewDocCom(inv.comment)" :isActive="!inv.is_active"   :title1="moment(new Date(inv.created_at)).format('MMMM D, YYYY')"  :title2="inv.bill_no" :isShowComment="inv.comment"></sideItemComp>
                </div>
            </div>
            <div class="col-8">
              <div v-if="getPdfLoaderStatueInvestigationReport" class="mt-5">
                <div class="d-flex justify-content-around align-items-center mt-5">
                <span  style="width:50px;height:50px; color: var(--foundation----primary---p200);" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
              </div>

              </div>
              <div  v-if="getReportIframe && getInvestigationReports.length > 0"> 
                <iframe :src="getReportIframe" width="100%" style="height: calc(100vh - 150px);"
              frameborder="0"></iframe>
            </div>
            </div>
          </div>
          </div>
        </div>
        </div>

    <commment  :comment="docComment"/>
</template>

<script setup>
import { onMounted,ref } from "vue";
import VueApexCharts from "vue3-apexcharts";

import { storeToRefs } from "pinia";
import { useSelfReports } from '@/core/modules/reports/reports'
import commment from "@/components/modal/commment.vue";
import sideItemComp from '@/components/reportscomponent'
import noRecordComp from "@/components/norecord";
import moment from "moment";
const { getInvestigationReports, getReportIframe ,getExternalGraphdata,getPdfLoaderStatueInvestigationReport} = storeToRefs(useSelfReports());
const store = useSelfReports();
const docComment=ref("")
const pdfValue=ref(false)
const isShowInvestigationReport=ref(false)
onMounted(async() => {
   await  store.getInvestigationReportsData()
    //  await store.getExternalGraphDataApi()
})
function openPdf(branchId,billNum,sampleCollectionId){
  store.setActiveVisit(branchId,billNum,sampleCollectionId);
}
function previewDocCom(comment){
  docComment.value=comment?.description
  // show a model here 

}
</script>

<style scoped>
.report-card {
    text-align: center;
    padding: 10px;
    background-color: var(--foundation----primary---p50);
    border-radius: 5px;
}

</style>