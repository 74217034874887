<template>
    <div class="full-load" v-if="getLoaderStatus">
    
        <h1 class="full-load-h1">Please wait while the payment is being processed</h1>
    
        <!-- <SpinnerDataLoader class="full-load-spinner" :classname="'text-primary'"></SpinnerDataLoader> -->
    
    </div>
    <div class="" v-if="getIsPaymentError">
    
        <div class=" full-load-h1 ">
    
            <h1 class=" text-danger text-center">Payment failed</h1>
    
            <div class="text-center">
    
                <a class="  btn btn-danger text-white" type="danger" variant="danger" @click="handleRePayaHandler">Try again</a>
    
            </div>
    
        </div>  
    </div>
</template>

<script setup>
import { onMounted, watch, computed, ref } from 'vue'
import { storeToRefs } from "pinia";

import router from "@/router/index";
import SpinnerDataLoader from '@/components/loaders/spinnerloader'
import { useRoute } from 'vue-router';
import { usePayment } from '@/core/modules/payment/payment';

const loadPage = true;
const pdfsrc = ref("")
const store = usePayment()
store.getIsPaymentSuccess
const route = useRoute();
const { getIsPaymentSuccess, getIsPaymentError, getLoaderStatus, paymentResponsePdf } = storeToRefs(usePayment())

onMounted(async () => {
    var body = {
        pidx: route.query.pidx,
        amount: route.query.amount,
        mobile: route.query.mobile,
        purchase_order_name: route.query.purchase_order_name,
        transaction_id: route.query.transaction_id,
    }
    await store.paymentResponse(body)


})

const handleRePayaHandler = () => {
    const sMasterBillId = localStorage.getItem("sMasterBillId")
    router.push({ path: `checkout/${sMasterBillId}` });
}
// watch(() => getIsPaymentError.value, (n) =>{
//     if(n){

//     const sMasterBillId= localStorage.getItem("sMasterBillId")
//                               router.push({ path: `checkout/${sMasterBillId}` });              
//     }
// } );
function openFile(f) {
    return `https://uatapi.meraki.dolphin.com.np${f}`
    // return `http://10.2.3.139:8001${f}`
}
watch(() => paymentResponsePdf.value, (newVal) => {
    if (newVal) {
        pdfsrc.value = openFile(newVal);
    }
});

</script>
<style scoped>
.full-load-h1 {
    position: absolute;
    top: 41%;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
}
</style>