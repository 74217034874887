import axios from "axios";
import { toast } from "vue3-toastify";
import { defineStore } from "pinia";

export const useAuthChangePassword = defineStore("authchangepassword", {
  state: () => ({
    errors: null,
    loaderStatus:false
  }),
  getters: {
    getErrors: (state) => {
      return state.errors;
    },
    getLoaderStatus: (state) => {
        return state.loaderStatus
    }
  },
  actions: {
    clearForm() {
      this.setErrors(null)
    },
    async submitChangePassword(password_credentials) {
      try {
        this.setErrors(null)
        this.loaderStatus = true;
        await axios
          .post("/change_password", password_credentials)
          .then((response) => {
            if (response.data.status == 200) {
              toast("Password has been successfully updated sss!", {
                autoClose: 1000,
              });
              this.loaderStatus = false;
              this.clearForm();
              this.$router.push({name: 'page.auth.login'})
            }
          })
          .catch((error) => {
            this.setErrors(error.response.data);
            toast.error(error.response.data.message, {
              autoClose: 1000,
            });
            this.loaderStatus = false;
          });
      } catch (error) {
        this.loaderStatus = false;
      }
    },
    setErrors(error) {
      this.errors = error;
      },
  },
});
