import axios from "axios";
import { toast } from "vue3-toastify";
import { defineStore } from "pinia";

export const useContactUs = defineStore("contactUs", {
  state: () => ({
    errors: null,
    loaderStatus:false,
    isPostSuccess:false,
    privayData:''
  }),
  getters: {
    getErrors: (state) => {
      return state.errors;
    },
    getLoaderStatus: (state) => {
        return state.loaderStatus
    },
    getIsPostSuccess:state=>{return state.isPostSuccess},
    getPrivacyData:state=>state.privayData
  },
  actions: {
    clearForm() {
      this.setErrors(null)
    },
    async fetchPrivacyData(){
      if(this.privayData==''){
        await axios
        .get("/privacy-policy")
        .then((response) => {
       
            this.privayData = response.data.data;
          })
      
      }
    },
    async submitContactUs(payload) {
      try {
        this.setErrors(null)
        this.loaderStatus = true;
        this.isPostSuccess = false;
        await axios
          .post("/storeCallBackRequest",payload)
          .then((response) => {
            if (response.data.status == 200) {
              toast.success("Form submitted successfully", {
                autoClose: 1000,
              });
              this.isPostSuccess = true;
              this.clearForm();
            }
          })
          .catch((error) => {
            this.setErrors(error.response.data);
            toast.error(error.response.data.message, {
              autoClose: 1000,
            });
            this.loaderStatus = false;
          });
      } catch (error) {
        this.loaderStatus = false;
      }
    },
    setErrors(error) {
      this.errors = error;
      },
  },
});
