<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 p-0">
        <div class="account-container">
          <h7 class="my-4">Contact Details</h7>
          <div class="row mt-2">
            <div
              class="col-4 col-100-sm"
              v-for="(address, index) in getAddressDetails"
              :key="index"
            >
              <div
                class="card c-pointer px-4 py-4"
                :class="address.is_default == 1 ? 'default-card' : ''"
              >
                <div style="position: relative">
                  <font-awesome-icon
                    data-bs-target="#deleteaddress"
                    data-bs-toggle="modal"
                    @click="deleteAddress(address.id)"
                    style="
                      position: absolute;
                      top: -15px;
                      right: -10px;
                      color: #413f3f;
                      font-size: 12px;
                    "
                    icon="fa-solid fa-trash"
                  />
                </div>
                <div style="position: relative">
                  <font-awesome-icon
                    data-bs-target="#saveaddress"
                    data-bs-toggle="modal"
                    @click="editAddress(address)"
                    style="
                      position: absolute;
                      top: -15px;
                      right: 8px;
                      color: #413f3f;
                      font-size: 12px;
                    "
                    icon="fa-solid fa-edit"
                  />
                </div>
                <div class="address-card mt-2">
                  {{ address.location_address }}
                </div>
                <div class="address-card">{{ address.province }}</div>
                <div class="address-card">{{ address.district }}</div>
                <div class="address-card">{{ address.municipality }}</div>
                <div class="address-card">{{ address.address }}</div>
                <!-- <div @click="editAddress(address)" data-bs-target="#saveaddress" data-bs-toggle="modal" class="btn btn-meraki-secondary address-card mt-3 mini-btn">Edit</div> -->
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-4">
              <button
                :disabled="isButtonDisabled"
                data-bs-target="#saveaddress"
                data-bs-toggle="modal"
                style="width: 100px"
                class="btn-meraki-primary"
                @click="saveAddress"
              >
                + Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <deleteaddress :deletedAddress="deleted_item"></deleteaddress>
  <saveaddress :tempAddressVal="temp_address"></saveaddress>
</template>

<script setup>
import { onMounted, computed, ref } from "vue";
import saveaddress from "@/components/modal/address";
import { storeToRefs } from "pinia";
import { useSelfAddress } from "@/core/modules/address";
import deleteaddress from "@/components/modal/deleteaddress";
const { getAddressDetails } = storeToRefs(useSelfAddress());
const store = useSelfAddress();
const deletedId = ref("");
const tempAddress = ref({});
onMounted(() => {
  store.getAddressDetailsData();
});
const isButtonDisabled = computed(() => {
  return getAddressDetails.value.length >= 3;
});
const deleted_item = computed(() => {
  return deletedId.value;
});
const temp_address = computed(() => {
  return tempAddress.value;
});
function deleteAddress(id) {
  deletedId.value = id;
}
function editAddress(address) {
  tempAddress.value = address;
}
function saveAddress() {
  tempAddress.value = {};
}
</script>

<style scoped>
.default-card {
  background-color: var(--foundation----primary---p50);
}
</style>
