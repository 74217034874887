<template>
    <modalComp :idmodal="'uploaddocument'" :show-header="true" :show-footer="true" :size="'modal-md'">
        <template v-slot:header>
            <h5 class="modal-title">Upload Document </h5>
            <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
        </template>
        <template v-slot:body>
              <div class="col-12 col-100-sm">
        <!-- <h6>Upload Files</h6> -->
        <p>Add your documents here.</p>
        <div class="dropbox">
          <input class="input-file mb-3" @change="handleFileChange" multiple type="file">
          <p>
            <font-awesome-icon style="font-size: 2rem; color: var(--foundation----primary---p300) !important"
              icon="fa-solid fa-upload" />
            <br>
            Drag Files here or <span style="color: var(--foundation----primary---p300) !important">Browse</span>
          </p>
        </div>
        <br>
        <span>Uploaded Files</span>
        <div style="font-weight: 600;" v-for="(files,index) in selectedFiles" :key="index">{{files.name}}</div>
        <br>
        <label style="font-weight: 500; font-size: 0.75rem" class="form-label" for="floatingTextarea">Remarks</label>
        <textarea class="form-control mb-3" v-model="file_remarks" placeholder="Leave Remarks here"
          id="floatingTextarea"></textarea>
        <!-- <button data-bs-dismiss="modal" :disabled="getLoaderStatus" class="btn-meraki-primary" @click="submitFile">Upload <span
            v-if="getLoaderStatus" class="spinner-border spinner-border-sm" role="status"
            aria-hidden="true"></span></button> -->
            <button data-bs-dismiss="modal" class="btn-meraki-primary" @click="submitFile">Upload</button>
      </div>
        </template>
        <!-- <template v-slot:footer>
            <div class="p-2">
                <button class="btn-meraki-secondary me-2" data-bs-dismiss="modal" >Cancel</button>
                <button class="btn-meraki-primary" data-bs-dismiss="modal"  @click="removeItem">Yes</button>
            </div>
        </template> -->

    </modalComp>
</template>

<script setup>
import { onMounted, ref } from "vue";
import modalComp from '@/components/common/modal'
import { useSelfForms } from '@/core/modules/self/forms'
import { storeToRefs } from 'pinia';
const store = useSelfForms();
const file_remarks = ref('');
const files = ref([]);
const file_upload = ref('');
const selectedFiles = ref([]);
const { getLoaderStatus } = storeToRefs(useSelfForms());
const props = defineProps({
    document: Object,
})
// const emit = defineEmits(['totalChange']);
onMounted(() => {
})
function handleFileChange(e) {
    selectedFiles.value = Array.from(e.target.files);
    file_upload.value = selectedFiles.value;
  }

  function submitFile() {
    const base64Data = [];
    const uploadPromises = selectedFiles.value.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (e) => {
          const fileData = e.target.result
          base64Data.push(fileData);
          resolve();
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsDataURL(file);
      });
    });

    Promise.all(uploadPromises)
      .then(async () => {
        const requestData = {
          'upload_files': base64Data,
          'remarks': file_remarks.value,
          'is_report': 1,
          'group_id' : props.document.id
        };
        await store.savePatientReport(requestData);
        selectedFiles.value = []
        file_remarks.value = ""
      })
      .catch((error) => {
      });


  }
</script>