<template>
    <div class="container-fluid ">
   <div class="row">
       <div class="col-12 mx-auto my-5 otp-container reserpassword-form">
           
           <div class="p40_50 forms-bg" >
                   <p class="text-center fs-15">Please enter your mobile number and we will send you 6 digit OTP code to activate your account.</p>
                <form class="mt-4">
                   
                   <div class="mb-3 d-flex">
                       <input v-model="mobileNumber" type="number" placeholder="Enter your mobile number" class="form-control me-2 ">
                       </div>
               </form>
               <button @click="submitMobileNumber" type="submit" style="width: 100%" class=" btn btn-primary btn-meraki-primary mb-4 mt-2">Enter</button>

               <div class=" pb-2 " style="font-size:12px;">
                Already activated your account?
              <router-link class="routerLink  p-0" style="border-top: none; font-size:12px; display: inline;"
                :to="{ name: 'page.auth.login' }"><span class="c-pointer  "
                  style="color: var(--foundation----primary---p300)">Login</span></router-link>
            </div>
               </div>
       </div>
   </div>
</div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import { storeToRefs } from 'pinia';
import { useAuthActivate } from '@/core/modules/auth/activate';
const store = useAuthActivate();
const mobileNumber = ref('');
function submitMobileNumber(){
    var body = {
        mobile_number : mobileNumber.value,
        device_id : 'e78ad27aea950c14'
    }
    window.localStorage.setItem('contact',mobileNumber.value);
    store.activateAccount(body)
}
</script>