<template>
    <modalComp :idmodal="'paymentInfoModal'" :show-header="true" :show-footer="true" :size="'modal-md'">
        <template v-slot:header>
            <h5 class="modal-title">Payment Detail</h5>
            <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
        </template>
        <template v-slot:body>
            <div>
                <div class="mb-1 mx-2">
                    <p>
                       Please give us a day to update your transaction
                    </p>
                </div>
                <div class="mb-1 mx-2">
                    <div v-if=" true" class="" style="">
                        <div class=" text-danger ">Due: Rs. {{ dueAmount?.toLocaleString()}}</div> 
                    </div>
                    <div class="text-success ">Deposit: Rs. {{ depositAmount?.toLocaleString() }}</div> 
                </div>

            </div>    <div  style="height: 700px width:100px;" class="d-flex justify-content-center mt-3">
                <img  :src='getImg("assets/images/qr.jpg")' style="width:auto height:100%"> 
                </div>
        </template>
        <template v-slot:footer>
            <div class="p-2">
                <button class="btn-meraki-secondary me-2" data-bs-dismiss="modal" >Cancel</button>
               
            </div>
        </template>

    </modalComp>
</template>

<script setup>
import { onMounted } from "vue";
import modalComp from '@/components/common/modal'

const props = defineProps({
    dueAmount:Number,
    depositAmount:Number
})
function getImg(source_image){
    return require('@/'+source_image);
}

</script>