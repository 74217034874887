import axios from "axios";
import { defineStore } from "pinia";
import { Modal } from "bootstrap";
import { toast } from "vue3-toastify";
export const useSelfOrder = defineStore("selfOrder", {
  state: () => ({
    myOrders: [],
    orderDetails: null,
    errors: [],
  }),
  getters: {
    getMyOrders: (state) => {
      return state.myOrders;
    },
    getErrors: (state) => {
      return state.errors;
    },
    getOrderDetails: (state) => {
      return state.orderDetails;
    },
  },
  actions: {
    async getOrder(tabs) {
      try {
        await axios
          .get("/getOrder?status=" + tabs)
          .then((response) => {
            if (response.data.status === 200) {
              this.myOrders = response.data.data.sMasterBills;
            }
          })
          .catch((error) => {
            this.errors = error.response.data;
          });
      } catch (error) {
        this.errors = error.response.data;
      }
    },
    async storeOrder(body) {
      try {
        await axios
          .post("/storeOrder", body)
          .then((response) => {
            if(response.data.status == 200){
              toast.success(response.data.message, {
                autoClose: 500,
              });

            this.$router.push({ name: "page.self.myorders" });
            }
          })
          .catch((error) => {
            this.errors = error.response.data;
          });
      } catch (error) {
        this.errors = error.response.data;
      }
    },
    async storeOrderCallbackReq (body) {
      try {
        await axios
          .post("/storeCallBackRequest", body)
          .then((response) => {
            if(response.data.status == 200){
              toast.success(response.data.message, {
                autoClose: 500,
              });

            this.$router.push({ name: "page.landing" });
            }
          })
          .catch((error) => {
            this.errors = error.response.data;
          });
      } catch (error) {
        this.errors = error.response.data;
      }
    },
    async viewOrder(id) {
      try {
        await axios
          .get("/orderListDetails?id=" + id)
          .then((response) => {
            if (response.data.success === 200) {
              this.orderDetails = response.data.data;
              let modal1 = Modal.getOrCreateInstance("#orderdetails");
              modal1.show();
            }
          })
          .catch((error) => {
            this.errors = error.response.data;
          });
      } catch (error) {
        this.errors = error.response.data;
      }
    },
    async viewCheckoutOrder(id) {
      try {
        await axios
          .get("/orderListDetails?id=" + id)
          .then((response) => {
            if (response.data.success === 200) {
              this.orderDetails = response.data.data;
              // let  modal1 = Modal.getOrCreateInstance('#orderdetails');
              // modal1.show();
            }
          })
          .catch((error) => {
            this.errors = error.response.data;
          });
      } catch (error) {
        this.errors = error.response.data;
      }
    },
  },
});
