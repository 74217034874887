import { defineStore } from "pinia";
const ID_TOKEN_KEY = "id_token";
export const useJwtService = defineStore("jwtservice", () => {
  function saveToken(token) {
    window.localStorage.removeItem(ID_TOKEN_KEY);
    window.localStorage.setItem(ID_TOKEN_KEY, token);
  }
  function getToken(){
    return window.localStorage.getItem(ID_TOKEN_KEY);
  }
  function destroyToken(){
    window.localStorage.removeItem(ID_TOKEN_KEY);
  }
  return{getToken,saveToken,destroyToken}
});
