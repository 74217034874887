<template>
  <nav
    class="navbar navbar-expand-lg bg-body-tertiary"
    style="padding: 10px 35px"
  >


    <div class="container-fluid p-0"  :style="router.currentRoute?.value?.name==='page.manage.selectaccount'?'z-index: -2':''">
      <router-link class="navbar-brand" :to="{name: 'page.landing'}"
        ><img class="logo-img" src="@/assets/images/logo.png" alt="logo"
      /></router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        id="hamburger_btn"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent" style="position: relative">
        
        <ul  v-if="getIsAuthorized && !multipleUser" id="myList" class="navbar-nav mb-2 mb-lg-0 header_nav">
  

          <li  @click="clickList" class="nav-item">
            <!-- <a class="nav-link" href="#"> My Services </a> -->
            <router-link :to="{name: 'page.self.myorders'}" class="nav-link hover-highlight" href="#"> My Services </router-link>
          </li>
          <li @click="clickList" class="nav-item">
            <!-- <a class="nav-link" href="#"> My Services </a> -->
            <router-link :to="{name: 'page.self.mypurchase'}" class="nav-link" href="#"> Purchase History </router-link>
          </li>
          <li @click="clickList" class="nav-item">
            <!-- <a class="nav-link" href="#"> My Services </a> -->
            <router-link :to="{name: 'page.self.myforms'}" class="nav-link" href="#"> Upload Files </router-link>
          </li>
       
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              My Reports
            </a>
            <ul class="dropdown-menu">
              <li @click="clickList"><router-link :to="{name: 'page.reports.investigation'}" class="dropdown-item">Investigation Reports</router-link></li>
              <li @click="clickList"><router-link :to="{name: 'page.reports.opdPrescription'}" class="dropdown-item">OPD Prescription</router-link></li>
              <li @click="clickList"><router-link :to="{name: 'page.reports.prescription'}" class="dropdown-item">Prescriptions</router-link></li>
              <li @click="clickList"><router-link :to="{name: 'page.reports.vitals'}" class="dropdown-item">Vitals</router-link></li>
              <li @click="clickList"><router-link :to="{name: 'page.reports.advices'}" class="dropdown-item">Advices</router-link></li>
            </ul>
          </li>
          <!-- <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              About Us
            </a>
            <ul class="dropdown-menu">
              <li @click="clickList"><router-link :to="{name: 'page.aboutus.contactus'}" class="dropdown-item">Contact Us</router-link></li>
           <li @click="clickList"><router-link :to="{name: 'page.reports.prescription'}" class="dropdown-item">Disclaimer & Privacy</router-link></li>
              <li @click="clickList"><router-link :to="{name: 'page.reports.vitals'}" class="dropdown-item">Terms and Conditions</router-link></li>  
            </ul>
          </li> -->
          <!-- <li class="nav-item main_search_li">
            <a href="#" class="nav-link">
              <input type="text" v-model="searchQuery" @input="handleInput" class="main_search" placeholder="Search">
            </a>
          </li> -->
        </ul>
        <ul class="navbar-nav mb-2 mb-lg-0 ms-auto header_nav right_nav">
          <!-- <li  class="nav-item">
             <router-link :to="{name: 'page.aboutus.contactus'}" class="nav-link login-li" >
           <span style=""> Contact Us</span>
             </router-link>  
          </li> -->
     
          <li v-if="!getIsAuthorized" class="nav-item">
             <router-link :to="{name: 'page.auth.login'}" class="nav-link login-li" >
           <span style="color:var(--foundation----primary---p200); font-weight: 600;"> Login</span>
             </router-link>  
          </li>
          <li  class="nav-item" style="cursor: pointer;">
          <div v-if="!multipleUser" >
          <font-awesome-icon v-if="getIsAuthorized" :icon="['fas', 'wallet']" data-bs-target="#paymentInfoModal" data-bs-toggle="modal" :style="{ fontSize: '30px', marginRight: '20px', marginTop:'6px' }" />
          <!-- <span v-if="getPaidUnpaidAmount?.due_amount && showNotification" style="position:absolute; margin-left:-27px background-color: red; width: 15px; height: 15px; border-radius: 50%; display: inline-block;" class="badge1"> </span> -->
<span  v-if="getPaidUnpaidAmount?.due_amount>0 && getIsAuthorized" class="red-dot"></span>
          </div>
          </li>
          <li v-if="getIsAuthorized && getPaidUnpaidAmount" class="nav-item c-pointer " style="font-size: small;" data-bs-target="#paymentInfoModal" data-bs-toggle="modal">
            <!-- <div v-if="!multipleUser" class="text-success ">Deposit: Rs. {{ getPaidUnpaidAmount?.deposit_amount }}</div> 
            <div v-if="!multipleUser" class=" text-danger ">Due: Rs. {{ getPaidUnpaidAmount?.due_amount }}</div>  -->
          </li>
          <li v-if="!multipleUser"  @click="clickList" class="nav-item nav-cart" style="position:relative;">
         
         <router-link  :to="{name:'page.self.mycart'}" class="nav-link" v-if="getIsAuthorized ">
           <font-awesome-icon class="nav-icon" style="font-size : 23px;" icon="fa-solid fa-cart-shopping" />
           My Cart 
           <div v-if="getMyCartLength && getIsAuthorized" class="cart-badge" style="position:absolute; margin-left:14px">{{getMyCartLength }}</div>
           <div v-if=" !getIsAuthorized" class="cart-badge" style="position:absolute; margin-left:14px">{{getTempCartLength }}</div>
         </router-link>
         <!-- <router-link  :to="{name: 'page.aboutus.contactus'}" class="nav-link" v-if="!getIsAuthorized || !getopdPatientRegId">
           <font-awesome-icon class="nav-icon" style="font-size : 23px;" icon="fa-solid fa-cart-shopping" />
           My Cart 
           <div v-if=" !getIsAuthorized && getTempCartLength" class="cart-badge" style="position:absolute; margin-left:14px">{{getTempCartLength }}</div>
           <div v-if=" !getopdPatientRegId && getMyCartLength" class="cart-badge" style="position:absolute; margin-left:14px">{{getMyCartLength }}</div>
         </router-link> -->
       </li>
          <li v-if="getIsAuthorized" class="nav-item dropdown me-2">
            <a v-if="!multipleUser"
              class="nav-link dropdown-toggle nav_header_dropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              href="#"
            >
            <font-awesome-icon class="nav-icon" icon="fa-solid fa-circle-user" />{{ getUser.patient_name?.split(" ")[0] }}
            </a>
            <ul v-if="!multipleUser" class="dropdown-menu">
              <li @click="clickList"><router-link :to="{name:'page.manage.myaccount'}" class="dropdown-item" >My account</router-link></li>
              <li @click="clickList"><a  href="/login" class="dropdown-item " @click="logout()">Logout</a></li>
            </ul>
          </li>
          
          
    
        </ul>
      </div>
    </div>
  </nav>
  <PaymentInfo :depositAmount=getPaidUnpaidAmount?.deposit_amount??0
    :dueAmount=getPaidUnpaidAmount?.due_amount??0></PaymentInfo>
</template>

<script setup>
import { computed, onMounted, watch, ref } from "vue";
import { storeToRefs } from "pinia";
import router from "@/router/index";
import { useRoute } from 'vue-router';
import { useManageUser } from "@/core/modules/auth/user";
import { useItemsStore } from '@/core/modules/header'
import { useSelfCart } from "@/core/modules/self/mycart"
import { useSelectAccount } from '../core/modules/manage/selectaccount'
import PaymentInfo from "@/components/modal/paymentInfo.vue";

const { getIsAuthorized, getUser,getopdPatientRegId,getPaidUnpaidAmount } = storeToRefs(useManageUser());
const { getMyCartLength,getTempCartLength } = storeToRefs(useSelfCart());
const { getAccounts } = storeToRefs(useSelectAccount());
const manageUserStore = useManageUser();
const searchQuery = ref('')
const multipleUser = ref(false)
const cartStore=useSelfCart()
const itemsStore = useItemsStore();

const route = useRoute()

onMounted(()=>{
  if(!getIsAuthorized.value){
    cartStore.setCartDetailDataTemp()
  }
})

    const checkRoute = computed(() => {
      return route.path; 
    });

    watch(checkRoute, (newRoute) => {
  if (newRoute === '/manage/select-account') {
    multipleUser.value = true
  } else {
     multipleUser.value = false
  }
  });

watch(searchQuery, (newValue, oldValue) => {
  if(newValue != oldValue){
    itemsStore.setSearchQuery(newValue);
  }
})

watch(() => getMyCartLength.value, () => {
    return getMyCartLength.value
});

// const cart_length = computed(() => {
//     return getMyCartLength.value;
// })

function logout() {
  cartStore.setCartDetailDataEmpty()
    manageUserStore.pageAuth();
    router.push({ name: "page.auth.login" });
}
function clickList(){
  // let x = document.getElementById("hamburger_btn");
  const targetElement = document.querySelector("#navbarSupportedContent");
  const isCollapsed = targetElement.classList.contains("show");
  if (isCollapsed) {
    targetElement.classList.remove("show");
  } else {
    targetElement.classList.add("show");
  }
}


// function clickList() {
//   const list = document.getElementById('myList');
//   const listItems = list.getElementsByTagName('li');
//     Array.from(listItems).forEach(item => {
//     item.addEventListener('click', handleItemClick);
//   });
// }

// function handleItemClick(event) {
//   const clickedItem = event.target;

//   if (clickedItem.classList.contains('active')) {
//     clickedItem.classList.remove('active');
//   } else {
//     clickedItem.classList.add('active');
//   }


// }
</script>

<style scoped>
.login-li {
    position: relative;
    top: 3px;
}

.active {
    font-weight: 700;
}

.badge {
  position: absolute;
  top: -3px;
  right: -5px;
  font-size: 0.75rem; 
}

.red-dot{
background-color: red; 
width: 15px; 
height: 15px;
border-radius: 50%; 
display: inline-block;
position: relative;
left: -32px;
top: -14px;
}

@media(max-width: 1031px){
  .red-dot{
    position: relative;
    top: -37px;
    left: 21px;
  }
}



</style>