<template>
    <modalComp :idmodal="'addtocart'" :show-header="true" :show-footer="true" :size="'modal-xl'">
        <template v-slot:header>
            <h5 class="modal-title">{{ service_name }} </h5>
            <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
        </template>
        <template v-slot:body >
            <div style="min-height: 50vh; max-height: 80vh;">
            
                <div class="d-flex align-items-center justify-content-end" >
        <input  v-if="getpopularServices && getpopularServices.length > 0" type="text" class="form-control rounded-pill" style="max-width:250px;" placeholder="Search for services..." v-model="searchText" @input="searchItem">
    </div>
                <div class="mb-1 mx-2">
                    <!-- <p>
                        Service Details for the selected service. Just a simple briefing of the service
                    </p> -->
                    <!-- <div>
                        <img class="cart-service-image" :src="service_icon??require('@/assets/images/illustrations/meds.svg')" alt="" style="object-fit: contain;">
                    </div> -->
                   <div class="mt-1 d-flex align-items-center justify-content-between">
    <label v-if="getpopularServices && getpopularServices.length > 0" class="form-label" style="font-weight: 600;">Popular services</label>

</div>

                   <div  v-if="getpopularServices" style="max-height: 35vh; overflow-y: auto; position: relative;">
                <div v-for="(option, index) in getpopularServices" :key="index" >
                    <template v-if="option.name.toLowerCase().includes(searchText.toLowerCase().trim())">
                    <input  v-if="getIsAuthorized" type="checkbox" class="mx-2" :id="`checkbox_${option.id}`" :value="option" @change="handleChange(option)" :checked="option.isChecked" :true-value="1" :false-value="0" v-model="option.isChecked"  >
                    <input  v-if="!getIsAuthorized" type="checkbox" class="mx-2" :id="`checkbox_${option.id}`" :value="option" @change="handleChange(option)" :checked="option.isChecked" :true-value="1" :false-value="0"  :disabled="true" v-model="option.isChecked">
                    <label :for="`checkbox_${option.id}`">{{option.name}}</label>
                    <span v-if="option.price" style="font-weight: 500">&nbsp;&nbsp;[NPR. {{ option.price[0].amount?.toLocaleString() }}]</span>
                    <div v-if="option.isChecked">
                    <ul style="font-size: 0.75rem; font-weight: 400; margin-bottom: 0 !important " >
                        <li v-for = '(pack, ind) in option.package_items' :key="ind">{{ pack.name }}</li>
                        <div class="  d-flex justify-content-start" v-if="option.is_multiple">
                                <div class="px-2">     <label
              style="font-weight: 500; font-size: 0.75rem"
              class="form-label"
              v-if="option.is_nursing"
              >From Date Time</label
            >
            <label
              style="font-weight: 500; font-size: 0.75rem"
              class="form-label"
              v-else
              > Date Time</label
            >
            <br />      <input
              type="datetime-local"
              :min="new Date().toISOString()
    .slice(0, 16)"
                                    
              class="py-15"
              required
              v-model="option.package_date_from"
              placeholder="Select Date and Time"
              id="from_datetime"
            /></div>
                                <div class="px-2">         <label
              style="font-weight: 500; font-size: 0.75rem"
              class="form-label"
                v-if="option.is_nursing"
              >To Date Time</label
            >
            <br />     <input
              type="datetime-local"
              :min="new Date().toISOString()
    .slice(0, 16)"
       v-if="option.is_nursing"
            
              class="py-15"
              required
              v-model="option.package_date_to"
              placeholder="Select Date and Time"
              id="to_datetime"
            /></div>
                            </div>
                            <div class="px-2 py-1" v-if="option.isChecked && option.is_tele_cons">Modes of Teleconsultation</div>
                        <div class="p-2 d-flex" v-if="option.isChecked && option.is_tele_cons" >
                           
                        <span   v-for="telecon,index in getTeleCons" :key="index"  :class="telecon.id==option.tele_cons_type_id?'btn btn-sm btn-success btn-sm  mx-1 ':'btn btn-sm btn-light btn-sm  mx-1 '" @click="(event)=>option.tele_cons_type_id=telecon.id">
                            {{ telecon.name }}
                        </span>
                        
                        </div>
                    </ul>
                         <div>
       
            </div>
                    </div>
                     </template>
                    
                </div>
                
            </div>
                
       <div class="mt-2 d-flex justify-content-between align-items-center">
    <label class="form-label" style="font-weight: 600">Select services</label>
    
   <input v-if="getpopularServices && !getpopularServices.length > 0" type="text" class="form-control rounded-pill" style="width:250px;" placeholder="Search for services..." v-model="searchText" @input="searchItem">
</div>
<!-- note: make this comp and reuse pls -->
<SpinnerDataLoader
            :classname="'text-primary'"
            v-if="!getServicesDepartmentWiseData ||getServicesDepartmentWiseData?.length<=1"
          ></SpinnerDataLoader>
                </div>
                <div  v-if="getServicesDepartmentWiseData" style="max-height: 50vh; overflow-y: auto; position: relative;">
                <div v-for="(option, index) in paginatedData" :key="index" >
                    <template v-if="1">
                    <input  v-if="getIsAuthorized" type="checkbox" class="mx-2" :id="`checkbox_${option.id}`" :value="option" @change="handleChange(option)" :checked="option.isChecked" :true-value="1" :false-value="0" v-model="option.isChecked"  >
                    <input  v-if="!getIsAuthorized" type="checkbox" class="mx-2" :id="`checkbox_${option.id}`" :value="option" @change="handleChange(option)" :checked="option.isChecked" :true-value="1" :false-value="0" :disabled="true" v-model="option.isChecked">
                    <label :for="`checkbox_${option.id}`">{{option.name}}</label>
                    <span v-if="option.price" style="font-weight: 500">&nbsp;&nbsp;[NPR. {{ option.price.amount?.toLocaleString() }}]</span>
                    <div v-if="option.isChecked">
                    <ul style="font-size: 0.75rem; font-weight: 400; margin-bottom: 0 !important " >
                        <li v-for = '(pack, ind) in option.package_items' :key="ind">{{ pack.name }}</li>
                        <div class="  d-flex justify-content-start" v-if="option.is_multiple">
                                <div class="px-2" >     <label
              style="font-weight: 500; font-size: 0.75rem"
              class="form-label"
                v-if="option.is_nursing"
              >From Date Time</label
            >
            <label
              style="font-weight: 500; font-size: 0.75rem"
              class="form-label"
                v-else
              >Date Time</label
            >
            <br />      <input
              type="datetime-local"
              :min="new Date().toISOString()
    .slice(0, 16)"
                                    
              class="py-15"
              required
              v-model="option.package_date_from"
              placeholder="Select Date and Time"
              id="from_datetime"
            /></div>
                                <div class="px-2">         <label
              style="font-weight: 500; font-size: 0.75rem"
              class="form-label"
                v-if="option.is_nursing"
              >To Date Time</label
            >
            <br />     <input
             v-if="option.is_nursing"
             
              type="datetime-local"
              :min="new Date().toISOString()
    .slice(0, 16)"
            
              class="py-15"
              required
              v-model="option.package_date_to"
              placeholder="Select Date and Time"
              id="to_datetime"
            /></div>
                            </div>

<div v-if="option.is_escorting
 && option.isChecked">
 <div class="mb-2 mx-2 row">
                    <div class="col-md-6 col-12 mt-3" style="font-size: 0.75rem">
                        <div >Pickup Location</div>


                        <!-- <vue3-google-autocomplete
      id="mapSearch"
      apiKey="AIzaSyDzuT9vxxZvX6hvRO1gJzKEC5pWgj0Sk7Y"
      v-model="searchQuery"
      @placeChanged="handlePlaceChanged"
      placeholder="Enter location..."
    /> -->
    <vue-google-autocomplete
      :ref="searchQuery"
      id="map"
      classname="form-control"
      placeholder="Please type your address"
      v-on:placechanged="handlePlaceChanged($event,option,true)"
      country="np"
      :enableGeolocation='true'
      autocomplete="on"
      class="my-1"
      
    >
    </vue-google-autocomplete>
                        <GoogleMap api-key="AIzaSyDzuT9vxxZvX6hvRO1gJzKEC5pWgj0Sk7Y" style="width: 100%; height: 30vh" :center="markerPosition" @click="handleMarkerPositionChange($event,option,1)" :zoom="15">
                            <Marker :options="{ position : option.pick_up_marker }"  />
                          </GoogleMap>
                          </div>
                    <div class="col-md-6 col-12 mt-3" style="font-size: 0.75rem">  <div>Drop Location</div>
                    <vue-google-autocomplete
      :ref="searchQuery1"
      id="map1"
      classname="form-control"
      placeholder="Please type your address"
      v-on:placechanged="handlePlaceChanged($event,option,false)"
      country="np"
      :enableGeolocation='true'
      autocomplete="on"
       class="my-1"
      
    >
    </vue-google-autocomplete>  
                        <GoogleMap api-key="AIzaSyDzuT9vxxZvX6hvRO1gJzKEC5pWgj0Sk7Y" style="width: 100%; height: 30vh" :center="markerPosition1" @click="handleMarkerPositionChange($event,option,0)" :zoom="15">
                            <Marker :options="{ position : option.drop_location_marker }" draggable />
                          </GoogleMap>
               
                </div></div>
                </div>

                            <div class="px-2 py-1" v-if="option.isChecked && option.is_tele_cons">Modes of Teleconsultation</div>
                        <div class="p-2 d-flex" v-if="option.isChecked && option.is_tele_cons" >
                           
                        <span   v-for="telecon,index in getTeleCons" :key="index"  :class="telecon.id==option.tele_cons_type_id?'btn btn-sm btn-success btn-sm  mx-1 ':'btn btn-sm btn-light btn-sm  mx-1 '" @click="(event)=>option.tele_cons_type_id=telecon.id">
                            {{ telecon.name }}
                        </span>
                        
                        </div>
                    </ul>
                    </div>
                     </template>
                </div>
<div v-if="getServicesDepartmentWiseData.length > 0" class="mt-1 d-flex justify-content-end">
  <nav aria-label="Page navigation">
    <ul class="pagination">
      <li class="page-item" :class="{ disabled: currentPage === 1 }">
        <a class="page-link" href="#" @click="previousPage">{{ '<' }} </a>
      </li>
      <li v-for="pageNumber in visiblePages" :key="pageNumber" :class="{ active: currentPage === pageNumber }">
        <a class="page-link" href="#" @click="changePage(pageNumber)">{{ pageNumber }}</a>
      </li>
      <li class="page-item" :class="{ disabled: currentPage === totalPages }">
        <a class="page-link" href="#" @click="nextPage">{{">"}}</a>
      </li>
    </ul>
  </nav>
</div>

            </div>
                <!-- <label id="item_error" for="" v-if="getErrors ? (getErrors['errors']['items'] ? true : false) : false
                " class="text-danger">{{
                    getErrors
                    ? getErrors['errors']["items"]
                    ? getErrors['errors']["items"][0]
                    : ""
                    : ""
                }}</label> -->
                <!-- <span class="text-danger" style="font-size: 12px" v-if="getErrors ? (getErrors['message'] ? true : false) : false">&nbsp; &nbsp; {{
                    getErrors
                    ? getErrors['message']
                    ? getErrors['message']
                    : ""
                    : ""
                }}</span> -->
            </div>
        </template>
        <template v-slot:footer>
            <div class="p-2" v-if="getIsAuthorized">
                <button class="btn-meraki-primary" @click="bookServices" >Add to Cart</button>
            </div>
            <div class="p-2" v-if="!getIsAuthorized">
                <button class="btn-meraki-primary" @click="bookServices" :disabled="true" >Add to Cart</button>
            </div>
        </template>
    </modalComp>
</template>

<script setup>
import SpinnerDataLoader from "@/components/loaders/spinnerloader";
import modalComp from '@/components/common/modal'
import { useServices } from '@/core/modules/self/services'
import {computed, watch, ref, onMounted} from 'vue'
import { storeToRefs } from 'pinia'
import { useSelfCart } from '@/core/modules/self/mycart'
import { useManageUser } from '@/core/modules/auth/user'
import moment from 'moment';
import { Modal } from 'bootstrap'
import Swal from 'sweetalert2/dist/sweetalert2.js'
moment().format();
import { GoogleMap, Marker } from "vue3-google-map"
// import GoogleAutocomplete from '../GoogleAutocomplete.vue';
import VueGoogleAutocomplete from "vue-google-autocomplete";


const addressValue = ref(null);
const placeholder=ref("");
const searchQuery=ref("")
const searchQuery1=ref("")

const checkObjectBool = ref(null)
const mapUrl = ref('https://www.google.com/maps/search/?api=1&query=27.6931196034988,85.31704387320124')
const { getErrors } = storeToRefs(useSelfCart());
const { getIsAuthorized } = storeToRefs(useManageUser());
const { getServicesDepartmentWiseData, getpopularServices,getTeleCons } = storeToRefs(useServices());
const store = useSelfCart();
// };
const props = defineProps({
    serviceName: String,
    serviceIcon: String,
    isMultiple: Number,
    serviceId: Number,
    getopdPatientRegId:Number,
})
const checkedItems = ref([])
const searchText = ref('')
const ServicesDepartmentWiseData = ref([])


onMounted(() => {
    let addCartModal = document.getElementById('addtocart')
    addCartModal.addEventListener('hidden.bs.modal', function () {
        $('input[type=checkbox]').prop('checked', false);
        checkedItems.value = [];
        store.emptyErrors();
    })
    if(ServicesDepartmentWiseData.value.length > 0){

        ServicesDepartmentWiseData.value = [...getServicesDepartmentWiseData.value];
    }


})
watch(
    () => props.serviceName, () => {
        return props.serviceName;
    },
    () => props.serviceIcon, () => {
        return props.serviceIcon;
    },
    () => props.getopdPatientRegId, () => {
        return props.getopdPatientRegId;
    },
    () => props.isMultiple, () => {
        return props.isMultiple;
    },
    () => props.serviceId, () => {
        return props.serviceId;
    },
    () => getServicesDepartmentWiseData.value, () => {
        return getServicesDepartmentWiseData.value;
    },
      () => getServicesDepartmentWiseData.value, () => {
        ServicesDepartmentWiseData.value = [...getServicesDepartmentWiseData.value];
    },
    )
const service_name = computed(() => {
    return props.serviceName;
})
const service_icon = computed(() => {
    return props.serviceIcon;
})
const getopdPatientReg_Id = computed(() => {
    return props.getopdPatientRegId;
})
const isMultipleService = computed(() => {
    return props.isMultiple;
})
const service_id = computed(() => {
    return props.serviceId;
})

const markerPosition = ref({ lat: 27.6931196034988, lng: 85.31704387320124 });
const markerPosition1 = ref({ lat: 27.6931196034988, lng: 85.31704387320124 });
function handleChange(option, event){
    if(isMultipleService.value == 0){
        if(option.isChecked == 1){
            getServicesDepartmentWiseData.value.filter((element) => {
                return element.isChecked = 0;
            });
            option.isChecked = 1;
        }
    }
        // now handle tele cons ko kura and then date
        if(!option.tele_cons_type_id && option.is_tele_cons){
            option.tele_cons_type_id=getTeleCons?.value[0]?.id
        }
        else{
            option.tele_cons_type_id=0
        }
        if(!option.package_date_from){
            if(option.is_multiple) option.package_date_from=new Date().toISOString().slice(0, 16)              
            else option.package_date_from=null
        }
        if(!option.package_date_to){
            if(option.is_multiple) option.package_date_to =option.package_interval ?new Date(Date.now() + option.package_interval * (60 * 60 * 1000) )?.toISOString().slice(0, 16):new Date().toISOString().slice(0, 16) ;
            else option.package_date_to=null
        }
        // handle state of map for escorting 1
        if(option.is_escorting){
            if(!option.pick_up_location){
                option.pick_up_location='Meraki Hospital'
                option.pick_up_marker=markerPosition.value

            }
            if(!option.drop_location){
                option.drop_location='Meraki Hospital'
                option.drop_location_marker=markerPosition.value

            }
        }
    
}
const handlePlaceChanged = (event,option,isPickup) => {
        if(isPickup){
        markerPosition.value = {
          lat: event.latitude,
          lng: event.longitude
        };
        option.pick_up_location=event.route+", "+event.locality+", "+event.country
        option.pick_up_marker=markerPosition.value
    }
    else{
        markerPosition1.value = {
          lat: event.latitude,
          lng: event.longitude
        };
        option.drop_location=event.route+", "+event.locality+", "+event.country
        option.drop_location_marker=markerPosition1.value
    }
      
    }


const currentPage = ref(1);
    const itemsPerPage = 5;


    const totalItems = computed(() => getServicesDepartmentWiseData.value.length);
    const totalPages = computed(() => Math.ceil(totalItems.value / itemsPerPage));
    const paginatedData = computed(() => {
  const startIndex = (currentPage.value - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Filter the data based on the search text
  const filteredData = getServicesDepartmentWiseData.value.filter((option) => 
    option?.name?.toLowerCase().includes(searchText.value?.toLowerCase().trim())
  );

  // Slice the filtered data for pagination
  return filteredData.slice(startIndex, endIndex);
});
    function changePage(page) {
      currentPage.value = page;
    }

    function previousPage() {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    }

    function nextPage() {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
      }
    }


function searchItem (){
//    getServicesDepartmentWiseData.value.filter(option => option.name.toLowerCase().includes(searchText.value.trim().toLowerCase())); 
}


function handleMarkerPositionChange(event,option,isPickup){
    
  const newPosition = {
    lat: event.latLng.lat(),
    lng: event.latLng.lng()
  };
  if(isPickup){

      option.pick_up_marker = newPosition;
      option.pick_up_location=getMapUrl(newPosition,option);
  }
  else{
    option.drop_location_marker = newPosition;
      option.drop_location=getMapUrl(newPosition,option);
  }

//   getAddressName();
}
const getAddressData=(place)=> {
      if (place.geometry) {
        this.markerPosition = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
      }
    }



function getMapUrl(latlng){
    return  `https://www.google.com/maps/search/?api=1&query=${latlng.lat},${latlng.lng}`
}


function bookServices(){
    let item1 = getServicesDepartmentWiseData.value.filter((element) => {
        return element.isChecked == 1
    });
    let item2= getpopularServices.value.filter((element) => {
        return element.isChecked == 1
    });
    let item =[...item1,...item2]
    // console.log(item)
    

    if(item.length == 0){
        Swal.fire({
            title: 'Error',
            text: 'Please select atleast one item',
            icon: 'error',
            confirmButtonText: 'OK'
        })  
    }
    else {
        var body = {
            'items' : item,
            'department_id' : props.serviceId
        }
        store.storeServicesToCart(body);
}
}


</script>

<style >
/* .autocomplete {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
} */
.pac-container {  
    z-index: 10000000 !important;
    /* display: block !important; */
}

</style>