import { defineStore } from 'pinia'
export const useItemsStore = defineStore('itemsStore', {
    state: () => ({
        searchQuery: ''
      }),
    getters:{
        getSearchQuery:(state)=>{
            return state.searchQuery
        }
      },
    actions: {
        setSearchQuery(query) {
            this.searchQuery = query;
          }
    }
})
