import { defineStore } from 'pinia'
import axios from "axios";
export const useAllServices = defineStore('allServices', {
    state: () => ({
        allservices: null,
        // servicesDepartmentWise: null,
        // bannerData: null
        // bookedServiceCategory : null
    }),
    getters: {
        getAllServices : (state) => {
            return state.allservices
        },
        // getBanner : (state) => {
        //     return state.bannerData
        // },
        // getServicesDepartmentWiseData : (state) => {
        //     return state.servicesDepartmentWise
        // }
    },
    actions: {
        async getAllServicesList (){
            await axios 
                .get('/getItemsByDepartments')
                .then((response) => {
                   if(response.status === 200){
                    this.allservices = response.data;
                   }
                })
        },
        // async getBannerData (){
        //     this.bannerData=null
        //     await axios 
        //         .get('/getBanner')
        //         .then((response) => {
        //            if(response.status === 200){
        //             this.bannerData = [...response.data.data];
        //            }
        //         })
        // },
        // async getServicesDepartmentWise(id) {
        //     await axios
        //         .get('/getServicesDepartmentWise?department_id='+id)
        //         .then((response) => {
        //             // this.servicesDepartmentWise = response.data.services;
        //             let temp_arr;
        //                 temp_arr = response.data.services;
        //                 let x = temp_arr.map((item) => {
        //                     return {...item, isChecked: 0};
        //                 })
        //                 this.servicesDepartmentWise = x;
        //         })
        // }
    }
})