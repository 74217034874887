<!-- eslint-disable vue/require-v-for-key -->
<template>
    <div class="container-fluid">
        <div class="row ctm-padding">
            <div class="col-1">
              </div>
            <div class="col-11">
                <h6>My OPD Prescription</h6>
            </div>

            <div v-if="!isShowInvestigationReport">
            <noRecordComp v-if="getOpdPrescriptionData.length < 1 && !getLoaderStatus" :title="'NO RECORD FOUND'">
            </noRecordComp> <div class="row">
            <div class="col-1"></div>
            <div class="col-2">
                <div v-for="(inv, index) in getOpdPrescriptionData" :key="index">
                  
                    <sideItemComp v-on:onactive="openPdf(inv.patient_id,inv.visit_id)" :isActive="!inv.is_active"   :title1="moment(new Date(inv.appointment_date)).format('MMMM D, YYYY')"  :title2="inv.visit_id" ></sideItemComp>
                </div>
            </div>
            <div class="col-8">
              <div v-if="getPdfLoaderStatueInvestigationReport" class="mt-5">
                <div class="d-flex justify-content-around align-items-center mt-5">
                <span  style="width:50px;height:50px; color: var(--foundation----primary---p200);" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
              </div>

              </div>
              <div  v-if="getReportIframe"> 
                <iframe :src="getReportIframe" width="100%" style="height: calc(100vh - 150px);"
              frameborder="0"></iframe>
            </div>
            </div>
          </div>
          </div>
        </div>
        </div>

    <commment  :comment="docComment"/>
</template>

<script setup>
import { onMounted,ref } from "vue";
import moment from "moment";

import { storeToRefs } from "pinia";
import { useSelfReports } from '@/core/modules/reports/reports'
import { useManageUser } from "@/core/modules/auth/user";
import commment from "@/components/modal/commment.vue";
import sideItemComp from '@/components/reportscomponent'
import noRecordComp from "@/components/norecord";
const { getOpdPrescriptionData, getReportIframe ,getPdfLoaderStatueInvestigationReport} = storeToRefs(useSelfReports());
const {getUser} = storeToRefs(useManageUser())
const store = useSelfReports();
const docComment=ref("")
const isShowInvestigationReport=ref(false)
onMounted(async() => {
//    await  store.getInvestigationReportsData()
    //  await store.getExternalGraphDataApi()
    const manageUserStore = useManageUser();
    await manageUserStore.getUserDetails();

    await store.getOpdPrescriptionDataFetch(getUser?.value?.id)
})
function openPdf(patId,visitId){
  store.setActivePrescription(patId,visitId);
}

</script>

<style scoped>
.report-card {
    text-align: center;
    padding: 10px;
    background-color: var(--foundation----primary---p50);
    border-radius: 5px;
}

</style>