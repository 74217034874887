<template>
  <div class="container-fluid">
    <div class="row mt-3 mt-md-0">
      <div class="col-12 p-0">
        <div class="account-container">
          <h7 class="mb-3">Change Password</h7>
          <div class="row mt-2">
            <div class="col-12">
              <label class="acc_label pb-1" for="current_pw"
                >Current Password</label
              >
              <br />
              <div class="col-3" style="position: relative">
                <input
                  v-model="oldPassword"
                  :type="!showPassword ? 'password' : 'text'"
                  class="pw_input w-100"
                  id="current_pw"
                />
                <font-awesome-icon
                  class="fo-text-color-primary-p300 eye-icon"
                  @click="showPasswordFun()"
                  :icon="
                    !showPassword ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'
                  "
                />
              </div>
            </div>
            <div class="col-12 mt-3">
              <label class="acc_label pb-1" for="new_pw">New Password</label>
              <br />
              <div class="col-3" style="position: relative">
                <input
                  v-model="newPassword"
                  :type="!showNewPassword ? 'password' : 'text'"
                  class="pw_input w-100"
                  id="new_pw"
                />
                <font-awesome-icon
                  class="fo-text-color-primary-p300 eye-icon"
                  @click="showNewPasswordFun()"
                  :icon="
                    !showNewPassword
                      ? 'fa-solid fa-eye-slash'
                      : 'fa-solid fa-eye'
                  "
                />
              </div>
            </div>
            <div class="col-12 mt-3">
              <label class="acc_label pb-1" for="confirm_pw"
                >Confirm Password</label
              >
              <br />
              <div class="col-3" style="position: relative">
                <input
                  v-model="confirmPassword"
                  :type="!showConfirmPassword ? 'password' : 'text'"
                  class="pw_input w-100"
                  id="confirm_pw"
                />
                <font-awesome-icon
                  class="fo-text-color-primary-p300 eye-icon"
                  @click="showConfirmPasswordFun()"
                  :icon="
                    !showConfirmPassword
                      ? 'fa-solid fa-eye-slash'
                      : 'fa-solid fa-eye'
                  "
                />
              </div>
            </div>
            <div class="col-4 mt-4">
              <button
                class="confirm-btn"
                @click="changePassword"
                :disabled="getLoaderStatus"
              >
                Change Password
                <span
                  v-if="getLoaderStatus"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// import accountSidenav from '@/components/accountSidenav.vue'
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useAuthChangePassword } from "@/core/modules/manage/changepassword";
const { getErrors, getLoaderStatus } = storeToRefs(useAuthChangePassword());
const store = useAuthChangePassword();
const showPassword = ref("");
const showNewPassword = ref("");
const showConfirmPassword = ref("");
const oldPassword = ref("");
const newPassword = ref("");
const confirmPassword = ref("");
function showPasswordFun() {
  showPassword.value = showPassword.value ? false : true;
}
function showNewPasswordFun() {
  showNewPassword.value = showNewPassword.value ? false : true;
}
function showConfirmPasswordFun() {
  showConfirmPassword.value = showConfirmPassword.value ? false : true;
}

function changePassword() {
  if (!oldPassword.value || !newPassword.value || !confirmPassword.value) {
    if (!confirmPassword.value) document.getElementById("confirm_pw").focus();
    if (!newPassword.value) document.getElementById("new_pw").focus();
    if (!oldPassword.value) document.getElementById("current_pw").focus();
  } else {
    var change_password_credentials = {
      old_password: oldPassword.value,
      new_password: newPassword.value,
      confirm_password: confirmPassword.value,
    };
    store.submitChangePassword(change_password_credentials);
  }
}
</script>
