import axios from "axios";
import { defineStore } from "pinia";
import { toast } from "vue3-toastify";

import router from "@/router/index";
export const usePayment = defineStore("payment", {
  state: () => ({
    errors: [],
    loaderStatus: false,
    paymentSuccess: "",
    paymentError: "",
    isPaymentSussess: false,
    isPaymentError: false,
    paymentResponsePdf: "",
  }),
  getters: {
    getErrors: (state) => {
      return state.errors;
    },
    getLoaderStatus: (state) => {
      return state.loaderStatus;
    },
    getIsPaymentSuccess: (state) => {
      return state.isPaymentSussess;
    },
    getIsPaymentError: (state) => {
      return state.isPaymentError;
    },
  },
  actions: {
    async savePayment(body) {
      try {
        await axios
          .post("/khalti-payment", body)
          .then((response) => {
            localStorage.setItem("sMasterBillId", body.sMasterBillId);
            window.open(response.data.paymentURL);
          })
          .catch((error) => {
            this.loaderStatus = false;
          });
      } catch (error) {
        this.loaderStatus = false;
      }
    },
    async paymentResponse(body) {
      this.loaderStatus = true;
      try {
        await axios
          .post("/khalti-v2/khaltiResponse", body)
          .then((response) => {
            this.paymentResponsePdf = response.data?.paymentReceiptUrl;
            this.isPaymentSussess = true;
            this.loaderStatus = false;
            router.push({ name: "page.paymentresponse-success" });
          })
          .catch((error) => {
            toast.error(error.response.data.message, {
              autoClose: 500,
            });
            this.isPaymentError = true;
            this.loaderStatus = false;
          });
      } catch (error) {
        this.isPaymentError = true;
        this.loaderStatus = false;
      }
    },
  },
});
