<template>
    <modalComp :idmodal="'pdfView'" :show-header="true" :show-footer="true" :size="'modal-lg'">
        <template v-slot:header>
            <h5 class="modal-title">Deposit Receipt </h5>
            <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
        </template>
        <template v-slot:body>
         
         <iframe  :src="getRefundHistory" style="width: 100%; height: 500px;"></iframe>
            
        </template>
        <template v-slot:footer>
        </template>

    </modalComp>
</template>

<script setup>
import { onMounted, ref, computed,watchEffect } from "vue";
import modalComp from '@/components/common/modal'
import moment from 'moment';
import { storeToRefs } from 'pinia'
import { usePurchaseHistory} from '../../core/modules/purchase/purchaseHistory'
const refundStore = usePurchaseHistory();
const { getRefundHistory} = storeToRefs(usePurchaseHistory());
const showTableData = ref(false);
moment().format()
// const emit = defineEmits(['totalChange']);
onMounted(() => {
    // console.log(getOrderDetails);
})

const refundHistory = ref('');


watchEffect(() => {
  refundHistory.value = getRefundHistory.value;
});




function showTable() {
    showTableData.value = true
}
</script>
<style scoped>

</style>