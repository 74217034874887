import { defineStore } from 'pinia'
import axios from "axios";
import { toast } from "vue3-toastify";
export const useAuthForgotPassword = defineStore('authForgotPassword', {
    state: () => ({
        errors: null,
        loaderStatus:false
    }),
    getters:{
        getErrors:(state)=>{
            return state.errors
        },
        getLoaderStatus: (state) => {
            return state.loaderStatus
        }
      },
    actions: {
        async forgetPassword (body){
            this.errors = null;
            try {
            this.loaderStatus = true;
            await axios
                .post('/forgetPassword', body)
                .then((response) => {
                    this.loaderStatus =  false;
                    if(response.data.status == 200){
                        setTimeout(function(){   toast.success(response.data.message, {
                            autoClose: 500,
                          }); 
},500)
                     
                       this.$router.push({ name: "page.auth.login" });   
                    }
                })
                .catch((error) => {
                    this.loaderStatus = false;
                });
            }
            catch(error) {
                this.loaderStatus =  false;
            }
        }
    }
})