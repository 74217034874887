<template>
    <div class="container pt-3 pb-3">
      <!-- <h5 class="text-center" ></h5> -->
      <div class="row" style="min-height: 80vh; max-height: 90vh; overflow-y: auto;">
        <div v-html="getPrivacyData">

            
        </div>
        
        </div>  
       
    </div>
  </template>
  
  <script setup>
  import { storeToRefs } from "pinia";
  

  import { useContactUs } from "@/core/modules/self/contactUs";

  const {getPrivacyData}=storeToRefs(useContactUs())
  const store = useContactUs();

  store.fetchPrivacyData()
  </script>