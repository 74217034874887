<template>
    <footer id="footer" style="background-color: var(--foundation----primary---p200)">
        <div class="container py-3">
            <div class="row copyright">
                <div class="col-md-3 col-sm-3 col-xs-12 pl-0 text-center">
                    <a href="https://www.mavorion.com/" target="_blank" title="Visit Mavorion"><img src="../assets/images/dolphinlogo.png" alt="" height="32px"></a>
                </div>
                <div style="color: white;" class="col-md-6 col-sm-6 col-xs-12 copyright-left text-center">
                    <a style=" color: white;text-decoration: none; line-height: 2;" href="/policy">Privacy Policy</a>
                     <!-- <span>&nbsp; | &nbsp;</span> -->
                    <!-- <a style="color: white;text-decoration: none; line-height: 2;" href="/terms">Terms Of Use</a> -->
                </div>
                <div class="col-md-3 col-sm-12 col-xs-12 pr-0 text-center">
                    <a href="https://www.mavorion.com/" target="_blank"><img src="../assets/images/mavorion.png" height="22px"></a>

                </div>
            </div>
        </div>
    </footer>
</template>